import React, {useState} from 'react';
import TextFields from "../components/TextFields";
import Card from "../components/Card";
import Button from "../components/Button";
import IcoMoreBlack from "../assets/images/ico_more_black.svg";
import SelectBox from "../components/SelectBox";
import Switch from "../components/Switch";
import CheckBox from "../components/CheckBox";
import Bar from "../components/Chart/Bar";
import Line from "../components/Chart/Line";
import Pie from "../components/Chart/Pie";
import Scatter from "../components/Chart/Scatter";
import Radar from "../components/Chart/Radar";
import Relation from "../components/Chart/Relation";

const Ui = () => {

    let [selectBoxValue, setSelectBoxValue] = useState("");
    let [chk1, setChk1] = useState(false);
    let [chk2, setChk2] = useState(true);
    let [chk3, setChk3] = useState(false);
    let [chk4, setChk4] = useState(true);
    let [chk5, setChk5] = useState(true);

    let option = [
        {
            display: "제목",
            name: "title"
        },
        {
            display: "이름",
            name: "name"
        },
        {
            display: "닉네임",
            name: "nicName"
        }
    ];

    return (
        <>
            <div style={{width: '1000px', height: "1000px"}}>
                <Relation height={"1000px"}/>
            </div>
            <div style={{width: '600px'}}>
                <Bar/>
            </div>
            <div style={{width: '600px'}}>
                <Line/>
            </div>
            <div style={{width: '600px'}}>
                <Pie/>
            </div>
            <div style={{width: '600px'}}>
                <Scatter/>
            </div>
            <div style={{width: '600px'}}>
                <Radar/>
            </div>

            <div>
                <CheckBox id={"asdf"}
                           label={"메롱메롱"}
                           onChange={checked => setChk5(checked)} checked={chk5}/>
                <br/>
                <br/>
                checkbox
                <br/>
                <br/>
                <div className={"check-box"}>
                    <input type={"checkbox"} id="check1"/>
                    <label className={""} for="check1"></label>
                    <span>기본형태</span>
                </div>
                <br/>
                <div className={"check-box"}>
                    <input type={"checkbox"} id="check2" checked/>
                    <label className={""} for="check2"></label>
                    <span>기본형태(체크상태) + 마우스 오버 시 색상 변경 </span>
                </div>
                <br/>
                <div className={"check-box"}>
                    <input type={"checkbox"} id="check3" checked disabled/>
                    <label className={""} for="check3"></label>
                    <span>체크 + disabled</span>
                </div>
                <br/>
                <br/>
                <Switch checked={chk1} onChange={b => setChk1(b)}/>
                <Switch checked={chk2} onChange={b => setChk2(b)}/>
                <span>각 버튼 태그에 disabled 속성 추가 후 disabled 상태 확인 가능( = button disabled)</span>
                <Switch checked={chk3} onChange={b => setChk3(b)} disabled={true}/>
                <Switch checked={chk4} onChange={b => setChk4(b)} disabled={true}/>
                <br/>
                시안과 동일한 형태로 커스텀을 하기 위해서는 checkbox 를 이용 해야 해서 아래와 같이 작업을 했습니다.
                <br/>
                <br/>
                선택시
                <br/>
                <label className={"container-check"}>
                    <input type={"checkbox"} className={"check"} checked/>
                    <div className={"check-outer-div"}>
                        <div className={"check-inner-div"}></div>
                    </div>
                </label>
                <br/>
                기본형태
                <br/>
                <label className={"container-check"}>
                    <input type={"checkbox"} className={"check"}/>
                    <div className={"check-outer-div"}>
                        <div className={"check-inner-div"}></div>
                    </div>
                </label>
                <br/>
                선택시 + disabled
                <br/>
                <label className={"container-check"}>
                    <input type={"checkbox"} className={"check"} checked disabled/>
                    <div className={"check-outer-div"}>
                        <div className={"check-inner-div"}></div>
                    </div>
                </label>
                <br/>
                기본형태 + disabled
                <br/>
                <label className={"container-check"}>
                    <input type={"checkbox"} className={"check"} disabled/>
                    <div className={"check-outer-div"}>
                        <div className={"check-inner-div"}></div>
                    </div>
                </label>
                <br/>
                <br/>
                <br/>
                disabled클래스명 추가로 비 활성화 상태 확인가능(class="iui-select-box disabled")
                <br/>
                <SelectBox options={option} onChange={option => setSelectBoxValue(option.name)} value={selectBoxValue}/>
                <br/>
                <br/>
                <br/>
                <h1>Text fields</h1>

                <TextFields placeholder={"Default"}
                            status={"default"}
                            assistiveText={"default text field"}
                />


                <TextFields placeholder={"Default"}
                            status={"default"}
                            type={"password"}
                            assistiveText={"default text field"}
                />

                <TextFields placeholder={"error"}
                            status={"error"}
                            assistiveText={"error text field"}
                />

                <TextFields placeholder={"disable"}
                            status={"disable"}
                            assistiveText={"disable text field"}
                />

            </div>

            <div>
                <Card title={"Title"}>
                    <p>
                        카드는 Title과 Op버튼만 수정 해주시면 됩니다.<br/>
                        width는 100%로 두시면 되고, padding, shadow, border 위주로 봐주시면 됩니다.<br/>
                        Contents 부분은 사용 용도에 따라 바뀌기 때문입니다.
                    </p>
                </Card>
            </div>

            <div>
                <h1>Buttons</h1>
                <span>각 버튼 태그에 disabled 속성 추가 후 disabled 상태 확인 가능( = button disabled)</span>
                <Button type={"full"}>full</Button><br/>
                <Button type={"line"}>line</Button><br/>
                <Button type={"round"}>round</Button><br/>
                {/* <Button type={"disabled"}>disabled</Button> */}
            </div>
            <br/>
            <br/>
            <br/>
            <img src={IcoMoreBlack}/>
            이미지의 경우, 위와 같은 방식으로 import 후 사용하셔야 하며,
            <div className={"test"}>
                asdf
            </div>
            <br/>
            <br/>
            <br/>
        </>
    );
};

export default Ui;



