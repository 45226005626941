import React, {useState, useEffect} from 'react';
import Button from "./Button";
import {yyyymmdd, yyyymmddhhiiss} from "../util/Date";
import ResourcePreview from "./ResourcePreview";
import {byte} from "../util/Number";

const DatasetResource = ({resource, fileDownload, sampleFileDownload, downloadLoading, sampleDownloadLoading}) => {

    return (
        <>
            <ul className={"info-table"}>
                <li>
                    <strong>{resource.format.toUpperCase()}</strong>
                    <span>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <Button onClick={() => fileDownload(resource.id)}>{downloadLoading ? <div className="lds-facebook">
                            <div />
                            <div />
                            <div />
                        </div> : "DOWNLOAD"}</Button>
                        <Button onClick={() => sampleFileDownload(resource.id)}>{sampleDownloadLoading ? <div className="lds-facebook">
                            <div />
                            <div />
                            <div />
                        </div> : "SAMPLE DOWNLOAD"}</Button>
                    </div>
                </span>
                </li>
                <li>
                    <strong>이름</strong>
                    <span>{resource.name}</span>
                </li>
                <li>
                    <strong>생성시간</strong>
                    <span>{yyyymmddhhiiss(new Date(resource.created), "-")}</span>
                </li>
                <li>
                    <strong>포 맷</strong>
                    <span>{resource.format}(UTF-8)</span>
                </li>
                <li>
                    <strong>사이즈</strong>
                    <span>{byte(resource.size, 0)}</span>
                </li>
                <li>
                    <strong>설 명</strong>
                    <span>UTF-8을 지원하는 에디터로 열어야합니다.(예: Notepad++)</span>
                </li>
            </ul>
                    <ResourcePreview resourceId={resource.id}/>
        </>
    )
};

export default DatasetResource;
