import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import FroalaEditor from 'react-froala-wysiwyg';
import React from 'react';

// Render Froala Editor component.
class Froala extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content ? props.content : "",
            froalaEditorConfig: {
                key: 'FSC2H-9G1A16A2B5B1D1rhwusguC2C-13edC3E3G3A7B6C5B4E4E3F2==',
                placeholderText: '입력해주세요',
                charCounterCount: true,
                // Set the image upload parameter.
                imageUploadParam: 'upload_image',

                // Set the image upload URL.
                imageUploadURL: '/upload_image',

                // Additional upload params.
                imageUploadParams: {id: 'my_editor'},

                // Set request type.
                imageUploadMethod: 'POST',

                // Set max image size to 5MB.
                imageMaxSize: 5 * 1024 * 1024,

                // Allow to upload PNG and JPG.
                imageAllowedTypes: ['jpeg', 'jpg', 'png'],

                // Set the video upload parameter.
                videoUploadParam: 'video_param',

                // Set the video upload URL.
                videoUploadURL: '/upload_video',

                // Additional upload params.
                videoUploadParams: {id: 'my_editor'},

                // Set request type.
                videoUploadMethod: 'POST',

                // Set max video size to 50MB.
                videoMaxSize: 50 * 1024 * 1024,

                // Allow to upload MP4, WEBM and OGG
                videoAllowedTypes: ['webm', 'jpg', 'ogg'],

                // Set the file upload parameter.
                fileUploadParam: 'file_param',

                // Set the file upload URL.
                fileUploadURL: '/upload_file',

                // Additional upload params.
                fileUploadParams: {id: 'my_editor'},

                // Set request type.
                fileUploadMethod: 'POST',

                // Set max file size to 20MB.
                fileMaxSize: 20 * 1024 * 1024,

                // Allow to upload any file.
                fileAllowedTypes: ['*'],
            }
        };

        this.handleModelChange = this.handleModelChange.bind(this);
    }

    handleModelChange (model) {
        console.log(model);
        this.setState({
            content: model
        }, () => this.props.onChange(this.state.content));
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.content !== this.state.content)
            this.setState({
                content: nextProps.content
            })
    }

    render () {
        return(
            <div >
                <FroalaEditor
                    model={this.state.content}
                    onModelChange={model => this.handleModelChange(model)}
                    config={this.state.froalaEditorConfig}
                />
                {/*<h4>Rendered Content:</h4>*/}
                {/*<FroalaEditorView*/}
                {/*    model={this.state.content}*/}
                {/*/>*/}
            </div>
        );
    }

}

export default Froala;
