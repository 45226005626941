import React from 'react';
import ReactEcharts from "echarts-for-react";

const Pie = (props) => {

    let option = null;
    if(props.type === "A"){
        option = {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            series: [
                {
                    name: '비율(%)',
                    type: 'pie',
                    radius: '55%',
                    center: ['40%', '50%'],
                    data: props.seriesData,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        if(props.showLegend)
            option.legend = {
                type: 'scroll',
                orient: 'vertical',
                right: 10,
                top: 20,
                bottom: 20,
                data: props.legendData,

                selected: props.selected
            };
    }else if(props.type === "B"){
        option = {
            tooltip: {
                trigger: 'item',
                formatter: "<p style='text-align: left'>{a} <br/>{b}: {c} ({d}%)</p>"
            },
            series: [
                {
                    name: '비율(%)',
                    type:'pie',
                    radius: ['55%', '85%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '15',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: props.seriesData,
                }
            ]
        };

        if(props.showLegend)
            option.legend = {
                orient: 'vertical',type: 'scroll',

                x: 'left',
                data: props.legendData,

                selected: props.selected
            };
    }

    if(props.title.length > 0)
        option.title = {
            text: props.title,
                x:'center'
        };

    return (
        <div>
            <ReactEcharts
                option={option}
                notMerge={true}
                theme={props.theme}
                opts={{
                    height: props.height
                }}
                style={{
                    height: props.height
                }}
            />
        </div>
    )
};


/**
 *
 * @props {
 *      seriesData: *[],
 *      legendData: string[],
 *      theme: string,
 *      selected: {}
 * }
 *
 */
Pie.defaultProps = {
    height: '400px',
    theme: 'default', // default, light, dark
    legendData: ["소방자동차", "구급자동차", "혈액공급차량", "범죄자이송차량", "군용차량"],
    selected: {
        "소방자동차": true,
        "구급자동차": true,
        "혈액공급차량": true,
        "범죄자이송차량": true,
        "군용차량": true
    },
    seriesData: [
        {
            "name": "소방자동차",
            "value": 32154
        },
        {
            "name": "구급자동차",
            "value": 87975
        },
        {
            "name": "혈액공급차량",
            "value": 17573
        },
        {
            "name": "범죄자이송차량",
            "value": 35884
        },
        {
            "name": "군용차량",
            "value": 95700
        }
    ],
    showLegend: true,
    type: "A",
    title: ""
};

export default Pie;
