import React from 'react';

const Indicator = () => {

    return (
        <>
            <div>
                {/*<div className="lds-facebook">*/}
                {/*    <div />*/}
                {/*    <div />*/}
                {/*    <div />*/}
                {/*</div>*/}
            </div>
        </>
    )
};

export default Indicator;
