import React, {useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Button from "../components/Button";
import {logout, setUserInfo} from "../store/actions/UserAction";
import {terms} from "../util/Terms";
import logo_nia_grey from "../assets/images/logo_nia_grey@2x.png";
import logo_gg_grey from "../assets/images/logo_gg_grey@2x.png";
import logo_ict_grey from "../assets/images/logo_ict_grey@2x.png";
import Modal from "react-modal";

const Layout = ({children, ...props}) => {
    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    const dispatch = useDispatch();

    const refContainer = useRef(null);
    const menuChkRef = useRef(null);

    let isMainClass = "";

    if(props.location.pathname === "/")
        isMainClass = " ds-is-main";
    let menuActive = {
        dataset: props.location.pathname === "/dataset" || props.location.pathname.indexOf("/dataset/") > -1,
        mixDataset: props.location.pathname === "/datasetMix",
        use: props.location.pathname.indexOf("/use") > -1 || props.location.pathname.indexOf("/visualize") > -1,
        board: props.location.pathname.indexOf("/board") > -1,
        introduce: props.location.pathname === "/whatwedo"
    };



    if(menuChkRef !== null && menuChkRef.current !== null){
        // console.log(menuChkRef.current);
        menuChkRef.current.checked = false;
    }


    //모ㅓ달
    const [modalIsOpen,setIsOpen] = useState(false);

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1060
        },
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width:  '50%'
        }
    };
    let subtitle = undefined;

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            {setIsOpen &&
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    aria={{
                        labelledby: "heading",
                        describedby: "full_description"
                    }}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Inline Styles Modal Example"
                >
                    <div style={{"width": "100%", "border-bottom": "1px solid #cad0dd", "padding-bottom": "20px"}}>
                        <span id="heading" style={{"font-size": "24px", "font-weight": "500"}}>경기지역경제포털</span>
                        <span style={{"float":"right", "margin-top": "5px"}} onClick={() => closeModal()}> <p className={"sitemap-close"}></p> </span>
                    </div>
                    <div id="full_description">
                        <div>
                            <ul className={"sitemap-wrap"}>
                                <li className={"sitemap-item"} style={{"margin-left": "0"}}>
                                    <p>데이터셋</p>
                                    <ul>
                                        <li>
                                            <Link to={"/dataset"} onClick={() => closeModal()}>데이터셋</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"}>
                                    <p>융합데이터</p>
                                    <ul>
                                        <li>
                                            <Link to={"/datasetMix"} onClick={() => closeModal()}>분야별 데이터</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"}>
                                    <p>활용</p>
                                    <ul>
                                        <li>
                                            <Link to={"/use"} onClick={() => closeModal()}>활용 갤러리</Link>
                                        </li>
                                        <li>
                                            <Link to={"/visualize"} onClick={() => closeModal()}>데이터 시각화</Link>
                                        </li>
                                        <li>
                                            <Link to={"/analysisService"} onClick={() => closeModal()}>분석 서비스 신청</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"} style={{"margin-left": "0", "margin-top": "10px"}}>
                                    <p>참여소통</p>
                                    <ul>
                                        <li>
                                            <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"} onClick={() => closeModal()}>공지사항</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510"} onClick={() => closeModal()}>묻고답하기</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"} onClick={() => closeModal()}>자주하는 질문</Link>
                                        </li>
                                        <li>
                                            <Link to={"/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f"} onClick={() => closeModal()}>데이터 문의</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"sitemap-item"} style={{"margin-top": "10px"}}>
                                    <p>소개</p>
                                    <ul>
                                        <li>
                                            <Link to={"/whatwedo"} onClick={() => closeModal()}>소개</Link>
                                        </li>
                                        <li>
                                            <Link to={"/allocation"} onClick={() => closeModal()}>데이터 배당</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal>
            }
            <div className={"ds-header-wrap" + isMainClass}>
                <div className={"top-bar"}>
                    <div className={"top-bar-inner"}>
                        <span className={"top-bar-message"}>방대한 데이터를 쉽고 빠르게 찾아보세요!</span>
                        <div className={"ds-header-user-wrap"}>
                            {!isLogin ? <div className={"top-btn-group"}>
                                    <Link to={"/join"}>
                                        <Button type={"empty"}>회원가입</Button>
                                    </Link>
                                    <Link to={"/login"}>
                                        <Button type={"round"}>로그인</Button>
                                    </Link>
                                    <Link onClick={() => openModal()}>
                                        <Button type={"round"}>사이트맵</Button>
                                    </Link>
                                </div>
                                :
                                <div className={"top-bar-inner"}>
                                    <ul className={"top-btn-group2"}>
                                        <li>
                                            <Link to={"/mypage"}>
                                                나의 정보
                                            </Link>
                                        </li>
                                        <li>
                                            <span onClick={() => dispatch(logout())}>
                                                로그아웃
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => openModal()}>
                                                사이트맵
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={"ds-header"}>
                    <div className={"ds-header-menu-wrap "}>
                        <div className={"ds-header-menu"}>
                            <Link to={"/"}>
                                <div className={"ds-header-title"}>
                                    <strong>경기지역</strong>
                                    <strong>경제포털</strong>
                                </div>
                            </Link>
                            {/* 모바일 사이드 메뉴 start */}
                            <nav role="navigation">
                                <div id="menuToggle">
                                <input type="checkbox" ref={menuChkRef} title={"menu checkbox"}/>
                                    <span></span>
                                    <span></span>
                                    <span></span>

                                    <div id="menu">
                                        <div className={"menu-inner"}>
                                            <div className={"menu-inner-top"}></div>
                                            {!isLogin && <ul className={"menu-left"}>
                                                <li className={"icon1"}><Link to={"/login"}>로그인</Link></li>
                                                <li className={"icon2"}><Link to={"/join"}>회원가입</Link></li>
                                            </ul>}
                                            <ul className={"menu-left"}>
                                                <li className={"icon3"}><Link to={"/"}>홈</Link></li>
                                                <li className={"icon4"}><Link to={"/dataset"}>데이터셋</Link></li>
                                                <li className={"icon5"}>
                                                    <Link to={"/datasetMix"}>융합데이터</Link>
                                                    <ol>
                                                        <li><Link to={"/datasetMix"}>맞춤형 데이터</Link></li>
                                                    </ol>
                                                </li>
                                                <li className={"icon6"}>
                                                    <Link to={"/use"}>활용</Link>
                                                    <ol>
                                                        <li><Link to={"/use"}>활용갤러리</Link></li>
                                                        <li><Link to={"/visualize"}>데이터 시각화</Link></li>
                                                        <li><Link to={"/analysisService"}>분석 서비스 신청</Link></li>
                                                    </ol>
                                                </li>
                                                <li className={"icon7"}>
                                                    <Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}>참여소통</Link>
                                                    <ol>
                                                        <li><Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}>공지사항</Link></li>
                                                        <li><Link to={"/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510"}>묻고 답하기</Link></li>
                                                        <li><Link to={"/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f"}>자주하는 질문</Link></li>
                                                        <li><Link to={"/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f"}>데이터 문의</Link></li>
                                                    </ol>
                                                </li>
                                                <li className={"icon8"}><Link to={"/whatwedo"}>소개</Link>
                                                    <ol>
                                                        <li><Link to={"/whatwedo"}>소개</Link></li>
                                                        <li><Link to={"/allocation"}>데이터 배당</Link></li>
                                                    </ol>
                                                </li>
                                            </ul>
                                            <ul className={"menu-left"}>
                                                <li className={"icon9"}>
                                                    {!isLogin && <Link to={"/login"}>로그인</Link>}
                                                    {isLogin && <ol>
                                                        <li><Link to={"/mypage"}>나의 정보</Link></li>
                                                        <li><Link to={"/mypage/orderlist"}>나의 구매내역</Link></li>
                                                        <li><Link to={"/mypage/favoritedata"}>나의 관심데이터</Link></li>
                                                        <li><Link to={"/mypage/myfaq"}>나의 문의</Link></li>
                                                        <li><Link to={"/mypage/download_history"}>나의 다운로드내역</Link></li>
                                                        <li><Link to={"/mypage/proposal_analysis"}>오프라인 분석신청</Link></li>
                                                        <li><a onClick={() => dispatch(logout())}>로그아웃</a></li>
                                                    </ol>}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            {/* 모바일 사이드 메뉴 end */}
                            <div className={"accordion"}>
                                <ul className={"menu-1depth"}>
                                    <li className={(menuActive.dataset && "active")}><Link to={"/dataset"}><span>데이터셋</span></Link></li>
                                    <li className={(menuActive.mixDataset && "active")}><Link to={"/datasetMix"}><span>융합데이터</span></Link></li>
                                    <li className={(menuActive.use && "active")}><Link to={"/use"}><span>활용</span></Link></li>
                                    <li className={(menuActive.board && "active")}><Link to={"/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794"}><span>참여소통</span></Link></li>
                                    <li className={(menuActive.introduce && "active")}><Link to={"/whatwedo"}><span>소개</span></Link></li>
                                </ul>
                                <div className={"ds-header-menu-with-sub accordion-content"}>
                                    <div className={"menu-sub-position"}>
                                        <SubMenu where={"header"} key={"header"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className={"ds-main-wrap" + isMainClass}>
                <div className={"ds-main"} ref={refContainer}>
                    {children}
                </div>
            </div>
            <div className={"ds-footer-wrap" + isMainClass}>
                <div className={"ds-footer"}>
                    <div className={"ds-footer-inner-wrap category"}>
                        <div className={"footer-logo"}>
                            <strong>경기지역</strong>
                            <strong>경제포털</strong>
                        </div>
                        <SubMenu where={"footer"}/>
                    </div>
                </div>
                <div className={"ds-footer-inner-wrap copyright"}>
                    <div className={"copyright-inner"}>
                        <div className={"company-image"}>
                            <img src={logo_ict_grey} alt={"과학기술정보통신부"}/>
                            <img src={logo_nia_grey} alt={"한국정보화진흥원"}/>
                            <img src={logo_gg_grey} alt={"세계속의 경기도"}/>
                        </div>
                        <ul className={"copyright-txt"}>
                            <li>경기도 수원시 팔달구 효원로 1 (매산로 3가) | 관리부서 : 데이터정책과 | 대표전화 : 031-8008-3952</li>
                            <li>본 서비스는 한국정보화진흥원, 과학기술정보통신부 지원으로 제공되고 있습니다.</li>
                            <li className={"txt-copy"}>© Gyeonggi Province. All Rights Reserved.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;


const SubMenu = ({where}) => {
    const dispatch = useDispatch();

    let m = [];
    let [boardMenuList, setBoardMenuList] = useState([]);

    useSelector(state => {
        if(state.board.boardMenuList !== boardMenuList)
            setBoardMenuList(state.board.boardMenuList);
    });

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);
    const SurveyLink = useSelector(state => state.user.surveyLink);

    // const popupX = (window.screen.width / 2) - ((500 / 2) + 10);
    // const popupY= (window.screen.height / 2) - ((500 / 2) + 50);
    let popupX = (document.body.offsetWidth / 2) - (500 / 2);
    popupX += window.screenLeft;
    const popupY= (window.screen.height / 2) - (500 / 2);
    if(where === "header")
        m = [
            {
                name: "데이터셋",
                child: [
                    {
                        name: "데이터셋",
                        link: "/dataset"
                    }
                ]
            }, {
                name: "융합 데이터",
                child: [
                    {
                        name: "분야별 데이터",
                        link: "/datasetMix"
                    }
                ]
            }, {
                name: "활용",
                child: [
                    {
                        name: "활용 갤러리",
                        link: "/use"
                    }, {
                        name: "데이터 시각화",
                        link: "/visualize"
                    }, {
                        name: "분석 서비스 신청",
                        link: "/analysisService"
                    }
                ]
            }, {
                name: "참여 소통",
                child: [
                    ...boardMenuList,
                    {
                        name: "설문조사",
                        onClick: () => window.open(SurveyLink,'window_name','scrollbars=1, width=500,height=500,left='+popupX+',top='+popupY)
                    }
                ]
            }, {
                name: "소개",
                child: [
                    {
                        name: "소개",
                        link: "/whatwedo"
                    },
                    {
                        name: "데이터 배당",
                        link: "/allocation"
                    }
                ]
            }
        ];

    if(where === "footer")
        m = [
            {
                name: "데이터셋",
                child: [
                    {
                        name: "데이터셋",
                        link: "/dataset"
                    },
                    {
                        name: "분야별 데이터",
                        link: "/datasetMix"
                    }
                ]
            }, {
                name: "활용",
                child: [
                    {
                        name: "활용 갤러리",
                        link: "/use"
                    }, {
                        name: "데이터 시각화",
                        link: "/visualize"
                    }, {
                        name: "분석 서비스 신청",
                        link: "/analysisService"
                    }
                ]
            }, {
                name: "참여 소통",
                child: [
                    ...boardMenuList
                ]
            }
        ];

    if(where === "footer" && isLogin)
        m.push({
            name: "기타",
            child: [
                {
                    name: "이용 약관",
                    onClick: () => {terms("termsAndConditions")}
                }, {
                    name: "개인정보처리방침",
                    onClick: () => {terms("privacyPolicy")}
                }
            ]
        }, {
            name: "마이페이지",
            child: [
                {
                    name: "나의 정보",
                    link: "/mypage"
                }, {
                    name: "나의 구매 내역",
                    link: "/mypage/orderlist"
                }, {
                    name: "나의 문의",
                    link: "/mypage/myfaq"
                }, {
                    name: "다운로드내역",
                    link: "/mypage/download_history"
                }, {
                    name: "오프라인 분석신청 내역",
                    link: "/mypage/proposal_analysis"
                }, {
                    name: "로그아웃",
                    onClick: () => dispatch(logout())
                }
            ]
        });
    else if(where === "footer" && !isLogin)
        m.push({
            name: "기타",
            child: [
                {
                    name: "이용 약관",
                    onClick: () => {terms("termsAndConditions")}
                }, {
                    name: "개인정보처리방침",
                    onClick: () => {terms("privacyPolicy")}
                }, {
                    name: "회원가입",
                    link: "/join"
                }, {
                    name: "로그인",
                    link: "/login"
                }
            ]
        });



    return (
        <>
            <ul className={"footer-category-wrap"}>
                {m.map(l => <li key={JSON.stringify(l)}>
                    <dl>
                        {/*<dt className={"footer-category-tit"}>{l.name}</dt>*/}
                        <dd className={"footer-category-list"}>
                            <ul>
                                {l.child.map(i => {
                                    let to = {};
                                    if(i.link)
                                        to.to = i.link;

                                    return (
                                        <li key={JSON.stringify(i)} style={{width: '100%'}}>
                                            {i.link ?
                                                <Link to={i.link} onClick={i.onClick && i.onClick}>{i.name}</Link>
                                                :
                                                <span onClick={i.onClick && i.onClick}>{i.name}</span>
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        </dd>
                    </dl>
                </li>)}
            </ul>
        </>
    )
};
