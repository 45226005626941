import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import SelectBox from "../SelectBox";

const SubNav = ({children, nav, ...props}) => {

    let [option] = useState(nav);

    useEffect(() => {

    }, []);

    return (
        <>
            <ul className={"sub-nav-bar"}>
                <li className={"icon-home"}><a href="#"></a></li>
                {option.map(i =>
                    <li className={(i.name === props.history.location.pathname && "active")}>
                        <Link to={i.name}>
                            {i.display}
                        </Link>
                    </li>)}
            </ul>
            <div className={"wrap-dataset"}>
                {children}
            </div>

        </>
    )
};

export default SubNav;
