import React from 'react';

const Switch = ({onChange, checked, disabled}) => {

    let disabledClass = disabled ? " iui-switch-disabled" : "";
    let checkedClass = checked ? " iui-switch-checked" : "";

    return (
        <div className={"iui-switch" + disabledClass + checkedClass}>
            <div>
                <button onClick={() => (!disabled) && onChange(!checked)}>
                    {checked? "ON" : "OFF"}
                </button>
            </div>
        </div>
    )
};

Switch.defaultProps = {
    onChange: () => {},
    checked: false,
    disabled: false
};

export default Switch;
