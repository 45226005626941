import React, {useState} from 'react';


const Login = (props) => {

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div>
            <input type={"text"} placeholder={"ID"} title={"ID"}
                   value={id} onChange={e => setId(e.target.value)} onKeyDown={e => e.keyCode === 13 && props.loginProcess(id, password)}/>
            <input type={"password"} placeholder={"PASSWORD"} title={"PASSWORD"}
                   value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => e.keyCode === 13 && props.loginProcess(id, password)}/>
            <button className={"button"} onClick={() => props.loginProcess(id, password)}>로그인</button>
            <button className={"line-button"} onClick={() => props.history.push("/join")}>회원가입</button>
        </div>
    );
};

Login.propTypes = {
    loginProcess: () => {
    }
};

export default Login;
