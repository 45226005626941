import React from 'react';
import Item from "./Item";
import {addComma} from "../../util/Number";


/**
 * list = [
 *     {
 *         subject: String
 *     }
 * ]
 */
const DsList = ({list, descriptionRender, onClick, totCnt, limit, page}) => {
    return (
        <div>
            <div className={"search-result"}>
                <span>전체({addComma(totCnt)})</span>
            </div>
            <ul className={"dataset-list"}>
                {list.map((item, i) => <Item data={item}
                                        key={JSON.stringify(item)}
                                        descriptionRender={descriptionRender}
                                        onClick={onClick}
                                        limit={limit}
                                        page={page}
                                             i={i}
                />)}
            </ul>
        </div>
    )
};

DsList.defaultProps = {
    list: [],
    descriptionRender: () => null,
    onClick: () => {},
    totCnt: 0
};

export default DsList;
