import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Row from './Row';

const Body = ({showLoading, items, loading, head, onRowClick}) => {

    const skeletonRender = () => {
        let res = [];
        for(let i=1; i<=loading; i++){
            res.push(<tr key={i}>
                {renderLoading()}
            </tr>);
        }

        return res;
    };

    const renderLoading = () => {
        return head.map((item, i) =>
            <th key={i}><Skeleton height={19}/></th>
        );
    };

    if(showLoading)
        return (
            <tbody>
            {skeletonRender()}
            </tbody>
        );
    else
        return (
            <tbody>
                {items.map((item, no) =>
                    <Row key={JSON.stringify(item)}
                         item={item}
                         head={head}
                         onRowClick={onRowClick}
                         no={no}
                    />)
                }
            </tbody>
        );
};

Body.defaultProps = {
    items: [],
    head: [],
    limitFilter: [
        {
            name: 10,
            display: 10
        },
        {
            name: 50,
            display: 50
        },
        {
            name: 100,
            display: 100
        }
    ],
};

export default Body;
