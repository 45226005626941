import React, {useEffect, useState} from 'react';
import {HashRouter, Route, Switch, Link} from "react-router-dom";
import ErrorPage404 from './pages/common/ErrorPage404';
import PageCommon from "./pages/common";
import PageAuth from "./pages/user";
import PageDataset from "./pages/dataset";
import PageBoard from "./pages/board";
import PageOrder from "./pages/order"
import {useDispatch, useSelector} from "react-redux";
import * as IboardApi from "./api/Board"
import {setBoardInfo, setBoardMenuList} from "./store/actions/BoardAction";
import Ui from "./pages/Ui";
import Layout from "./pages/Layout";
import WhatWeDo from "./pages/WhatWeDo";
import Allocation from "./pages/Allocation";
import ScrollToTop from "./util/scrollToTop"

function AppRouter(props){

    let [boardList, setBoardList] = useState([]);

    useSelector(state => {
        if(state.board.boardMenuList !== boardList)
            setBoardList(state.board.boardMenuList);
    });


    const userInfo = useSelector(state => state.user.info);

    const dispatch = useDispatch();

    useEffect(() => {
        IboardApi.readBoardList().then(res => {
            let boardList = res.result.boardlist;
            let boardInfo = {};
            let boardLink = [];
            boardList.map(board => {
                boardInfo[board.id] = {
                    name: board.subject
                };
                boardLink.push({
                    name: board.subject,
                    link: "/board/" + board.id
                })
            });

            // dispatch(setBoardInfo(boardInfo));
            // dispatch(setBoardMenuList(boardLink));
        })
    }, []);

    return (
        <>
            <HashRouter>
                <ScrollToTop />
                <Switch>
                    <Route path="/login"
                           component={PageAuth.Login} exact/>
                    <Route path="/join"
                           component={PageAuth.Join} exact/>
                    <Route path="/forgot_password"
                           component={PageAuth.ForgotPassword} exact/>
                    <Route path="/reset_password/:user_id_key/:reset_key"
                           component={PageAuth.ResetPassword} exact/>
                    <Route path="/email_confirm/:user_id_key/:temporary_key"
                           component={PageAuth.EmailConfirm} exact/>
                    <Route path="/ui"
                           component={Ui} exact/>

                    <Layout>
                        <Switch>
                        <Route path="/"
                               component={PageCommon.Main} exact/>
                        <Route path="/mypage"
                               component={PageAuth.Mypage}/>
                        <Route path="/analysisService"
                               component={PageDataset.AnalysisService} exact/>
                        <Route path="/visualize"
                               component={PageDataset.Visualize} exact/>
                        <Route path="/visualize/:id"
                               component={PageDataset.VisualizeDetail} exact/>
                        <Route path="/dataset"
                               component={PageDataset.ListDataset} exact/>
                        <Route path="/datasetMix"
                               component={PageDataset.MixListDataset} exact/>
                        <Route path="/dataset/:id"
                               component={PageDataset.Item} exact/>
                        <Route path="/board/:board"
                               component={PageBoard.List} exact/>
                        <Route path="/board/:board/write"
                               component={PageBoard.Write} exact/>
                        <Route path="/board/:board/write/:articleId"
                               component={PageBoard.Write} exact/>
                        <Route path="/board/:board/article/:articleId"
                               component={PageBoard.Article} exact/>
                        <Route path="/order/:dataId"
                               component={PageOrder.Order} exact/>
                        <Route path="/whatwedo"
                               component={WhatWeDo} exact/>
                        <Route path="/allocation"
                               component={Allocation} exact/>
                        <Route path="/use"
                               component={PageDataset.Use} exact/>
                        <Route path="/use/:id"
                               component={PageDataset.UseDetail} exact/>

                        <Route component={ErrorPage404}/>
                        </Switch>
                    </Layout>

                    <Route component={ErrorPage404}/>
                </Switch>
            </HashRouter>
        </>
    );
}

export default AppRouter;
