import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Button from "./Button";
import CheckBox from "./CheckBox";
import swal from "sweetalert";
import * as UserApi from "../api/User";
import {DaumPostcode} from "react-daum-postcode";
import SelectBox from "./SelectBox";



const UserUpdate = ({userInfo, deleteBtnName, modifyBtnName, ...props}) => {
    const [id, setId] = useState(userInfo.id);
    const [email, setEmail] = useState(userInfo.email);
    const [fullname, setFullName] = useState(userInfo.fullname);
    const [phone, setPhone] = useState(userInfo.phone)
    const [address, setAddress] = useState(userInfo.address)
    const [apikey, setApiKey] = useState(userInfo.apikey);
    const [isSamePassword, setIsSamePassword] = useState(true);
    let emailAgreeByUserInfo = userInfo.emailAgree;
    if(emailAgreeByUserInfo === undefined) emailAgreeByUserInfo = false;
    const [emailAgree, setEmailAgree] = useState(emailAgreeByUserInfo);
    const [detailAddress, setDetailAddress] = useState(userInfo.detail_address);
    const [postCode, setPostCode] = useState(userInfo.postcode);
    const [userType, setUserType] = useState(userInfo.user_type);

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordChangePopup, setPasswordChangePopup] = useState(false);
    const [nowPassword, setNowPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [isShowPostCode, setIsShowPostCode] = useState(false);

    const CategoryUserType = useSelector(state => {
        return state.user.categorySortation
    });
    const CheckUserCategory = (value) => {
        let find_key_by_value = Object.keys(CategoryUserType).find(key => CategoryUserType[key] === value);

        if(find_key_by_value === undefined) {
            return value
        }
        else {
            return find_key_by_value
        }
    };

    const userTypeOption = () => {
        let rst = [];

        for (let [name, display] of Object.entries(CategoryUserType)) {
            rst.push({
                display: display,
                name: name
            })
        }
        return rst;
    };

    const changePassword = (target, value) => {
        if(target === "password"){
            setPassword(value);

            if(passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        }else if(target === "passwordConfirm"){
            setPasswordConfirm(value);

            if(password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };

    const isSetPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if(str.length < 4){
            setPhone(str)
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            setPhone(tmp)
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            setPhone(tmp)
        }else{
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            setPhone(tmp)
        }
    };


    const updateValue = {
        id: id,
        apikey: apikey,
        email: email,
        emailAgree: emailAgree,
        fullname: fullname,
        address: address,
        phone: phone,
        detail_address: detailAddress,
        postcode: postCode,
        user_id_key: userInfo.id_key,
        center_code: userInfo.center_code,
        user_type: CheckUserCategory(userType)
    };


    const handleChangePassword = () => {
        setPasswordChangePopup(false);
        const onlyPasswordParams = {
            id: userInfo.id,
            email: userInfo.email,
            password: newPassword,
            old_password: nowPassword,
            name: userInfo.fullname
        };
        UserApi.updatePassword(onlyPasswordParams)
            .then(res => {
                console.log(res);
                swal("비밀번호 변경이 완료되었습니다.")
            })
            .catch(res => {
                console.log(res);
                let json_res = JSON.parse(res.response);
                if (json_res.result === 101) {
                    swal('비밀번호는 8자 이상이어야 합니다.')
                }
                else if (json_res.result === "incorrect old pw") {
                    swal('현재 비밀번호가 정확하지 않습니다.\n 다시 한번 확인해주세요.')
                }
            });
        setNowPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
    };

    const handleFindPostCode = () => {
        setIsShowPostCode(true);
    };

    const handleAddress = (data) => {
        console.log(data);
        setAddress(data.address);
        setPostCode(data.postcode === "" ? data.zonecode : data.postcode);
        setIsShowPostCode(false);
    };

    return (
        <>
            {isShowPostCode && <div className={"ds-2-daum-post-code-wrap usr-update"}>
                    <DaumPostcode
                        onComplete={data => handleAddress(data)}
                    />
                    <button className={"ds-2-daum-post-code-close-btn usr-update"}
                            onClick={() => setIsShowPostCode(false)}
                    >
                        닫기
                    </button>
            </div>}
            {passwordChangePopup && <div className={"ds-change-password-wrap"}>
                <div className={"ds-change-password-inner"}>
                    <h3 className={"ds-change-password-title"}>비밀번호 변경</h3>
                    <div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={nowPassword}
                                   onChange={e => setNowPassword(e.target.value)} placeholder={"현재 비밀번호"} title={"현재 비밀번호"}/>
                        </div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={newPassword}
                                   onChange={e => setNewPassword(e.target.value)} placeholder={"새로운 비밀번호"} title={"새로운 비밀번호"}/>
                        </div>
                        <div className={"ds-change-password-input"}>
                            <input type="password" value={newPasswordConfirm}
                                   onChange={e => setNewPasswordConfirm(e.target.value)} placeholder={"새로운 비밀번호 확인"} title={"새로운 비밀번호 확인"}/>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setPasswordChangePopup(false)}
                                className={"ds-change-password-btn-cancel"}>
                            취소
                        </button>
                        <button onClick={() => handleChangePassword()} disabled={!(newPasswordConfirm && newPassword && nowPassword)}
                                className={"ds-change-password-btn-ok"}>
                            확인
                        </button>
                    </div>
                </div>
            </div>}
            <div className={"user-form"}>
                <ul>
                    <li>
                        <strong>아이디</strong>
                        <span>{userInfo.id}</span>
                    </li>
                    <li>
                        <strong>이름</strong>
                        <span>{fullname}</span>
                    </li>
                    <li>
                        <strong>회원 구분</strong>
                        <span>
                            <div className={"AnalysisService-select-wrap"} style={{width: '300px'}}>
                                <SelectBox options={userTypeOption()}
                                           onChange={option => {
                                               setUserType(option.name);
                                           }}
                                           value={userType}
                                           placeholder={userType}
                                />
                            </div>

                            {/*<select className={"userType-select-wrap"} onChange={e => setUserType(e.target.value)} value={CheckUserCategory(userType)}>*/}
                            {/*    {userTypeOption().map((item, i) =>*/}
                            {/*        <option value={item.name} key={i}>{item.display}</option>*/}
                            {/*    )}*/}
                            {/*</select>*/}
                        </span>
                        {/*<span>{userInfo.user_type}</span>*/}
                    </li>
                    <li>
                        <strong>비밀번호</strong>
                        <span>
                        {/*<input type={"password"} placeholder={"PASSWORD"}*/}
                            {/*       value={password} onChange={e => changePassword("password", e.target.value)}/>*/}
                            <Button onClick={() => setPasswordChangePopup(true)}>비밀번호 변경</Button>
                    </span>

                    </li>
                    <li>
                        <strong>이메일</strong>
                        <span>
                        <span>{userInfo.email}</span>
                            {/*<Button>이메일 인증</Button>*/}
                            <div className={"checkbox-block"}>
                            <CheckBox label={"이메일 수신 동의 (수신 거부 시 일부 서비스에 제한이 있을 수 있습니다)"}
                                      id={"email"} checked={emailAgree} onChange={(bool) => setEmailAgree(bool)}/>
                        </div>
                    </span>

                    </li>
                    <li>
                        <strong>전화번호</strong>
                        <span>
                        <input type={"text"} value={phone} onChange={e => isSetPhoneNumber(e.target.value)} title={"전화번호"}/>
                            {/*<div className={"checkbox-block"}>*/}
                            {/*    <CheckBox label={"SMS 수신 동의 (수신 거부 시 일부 서비스에 제한이 있을 수 있습니다)"} id={"phone"}/>*/}
                            {/*</div>*/}
                    </span>

                    </li>

                    <li>
                        <strong>우편번호</strong>
                        <span>
                            <button className={"ds-2-find-address usr-update"} onClick={() => handleFindPostCode()}>
                                주소찾기
                            </button>
                            <input type={"text"} value={postCode}
                                   className={"ds-2-find-address-input usr-update"}
                                   style={{marginTop: '-1px'}}
                                   disabled={true}
                                   title={"우편번호"}
                            />
                        </span>
                    </li>

                    <li>
                        <strong> 주소 </strong>
                        <span>
                            <input type={"text"} value={address}
                                   style={{marginTop: '-1px'}}
                                   disabled={true}
                                   title={"주소"}
                            />
                        </span>
                    </li>

                    <li>
                        <strong>상세주소</strong>
                        <span>
                            <input type={"text"} placeholder={"상세주소"} title={"상세주소"}
                                   onChange={e => setDetailAddress(e.target.value)}
                                   value={detailAddress}
                            />
                        </span>
                    </li>
                </ul>
                <div className={"btn-group"}>
                    <Button onClick={() => props.isUpdateUser(updateValue)}>{modifyBtnName}</Button>
                </div>
                <div className={"btn-group btn-secession"}>
                    <Button onClick={() => props.isDeleteUser(userInfo.id_key)}>{deleteBtnName}</Button>
                </div>
            </div>
        </>
    );
};
UserUpdate.propTypes = {
    isUpdateUser: () => {

    },
    isDeleteUser: () => {

    },
    isChangePassword: () => {

    }
};
export default UserUpdate;
