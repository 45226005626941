import React, {useState, useEffect} from 'react';
import ThumImg1 from "../../assets/images/thum_img1.png";
import ThumImg2 from "../../assets/images/thum_img2.png";
import ThumImg3 from "../../assets/images/thum_img3.png";
import ThumImg4 from "../../assets/images/thum_img4.png";
import ThumImg5 from "../../assets/images/thum_img5.png";
import ThumImg6 from "../../assets/images/thum_img6.png";
import SubNav from "../../components/SubNav";
import * as DataApi from "../../api/Data";

const Visualize = (props) => {

    let subNav = [
        {
            display: "활용 갤러리",
            name: "/use"
        }, {
            display: "데이터 시각화",
            name: "/visualize"
        }, {
            display: "분석 서비스 신청",
            name: "/analysisService"
        }
    ];

    let [list, setList] = useState([]);

    useEffect(() => {
        let params = {
            type: "시각화"
        };
        DataApi.readUseList(params).then(res => {
            setList(res.result.use_case_list);
        })
    }, []);

    const handleNewLabel = (cdate) => {
        let bool = false;
        let today = new Date();
        let create_date = new Date(cdate);
        let dateDis = today.getTime() - create_date.getTime();
        let cal_date = dateDis / (1000 * 60* 60* 24);
        if (Math.round(cal_date) <= 30){
            bool = true
        }
        return bool
    };

    return (
        <SubNav nav={subNav} history={props.history}>
            <div className={"list-title-mo"}>데이터 시각화</div>
            <div>
                <p style={{"font-size": "18px"}}>
                데이터 시각화는 경기지역포털 내에 있는 데이터를 기반으로 그래프를 통해 나타낸 곳입니다.
                </p>
                <p style={{"margin-top": "10px"}}>
                    시각화 그래프를 통해 데이터를 한눈에 볼 수 있어 보다 데이터를 정확하게 이해할 수 있습니다.
                </p>
                <p>
                    아래 시각화 게시물은 데이터를 활용한 다양한 예시를 정기적인 업데이트를 통해 제공되고 있습니다.
                </p>
            </div>
            <div className={"use-wrap"}>
                {/*<div className={"search-wrap"}>*/}
                {/*    <select>*/}
                {/*        <option selected>전체</option>*/}
                {/*        <option></option>*/}
                {/*    </select>*/}
                {/*    <input type="text" placeholder="검색"></input>*/}
                {/*    <button></button>*/}
                {/*</div>*/}
                <div className={"gallery-wrap"}>
                    <ul className="gallery-block">
                        {list.map(res =>
                            <li onClick={() => props.history.push('/visualize/'+res.id)}>
                                <dl>
                                    <dt>
                                        <img src={res.thumbnail} alt={res.thumbnail_title}/>
                                    </dt>
                                    <dd>
                                        {handleNewLabel(res.cdate)&& <span style={{color:"red"}}>NEW</span>}
                                        <strong>{res.thumbnail_title}</strong>
                                        <p>{res.thumbnail_desc}</p>
                                    </dd>
                                </dl>
                            </li>)}
                    </ul>
                </div>
                {/*<div className={"paging-wrap"}>*/}
                {/*    <div className={"paging-button"}>*/}
                {/*        <button className="btn-rew"></button>*/}
                {/*        <button className="btn-back"></button>*/}
                {/*        <button>1</button>*/}
                {/*        <button>2</button>*/}
                {/*        <button>3</button>*/}
                {/*        <button>4</button>*/}
                {/*        <button>5</button>*/}
                {/*        <button>6</button>*/}
                {/*        <button>7</button>*/}
                {/*        <button>8</button>*/}
                {/*        <button>9</button>*/}
                {/*        <button>10</button>*/}
                {/*        <button className="btn-next"></button>*/}
                {/*        <button className="btn-ff"></button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </SubNav>
    )
};

export default Visualize;
