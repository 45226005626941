import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import swal from "sweetalert";
import Swal from 'sweetalert2'


import UserUpdateComponent from '../../components/UserUpdateForm';
import * as UserApi from "../../api/User";
import * as OrderApi from "../../api/Order";
import * as FavoriteDataApi from "../../api/FavoriteData"
import {yyyymmdd, yyyymmddhhiiss} from "../../util/Date";
import {delUserInfo, logout, setUserInfo} from "../../store/actions/UserAction";
import * as DataApi from "../../api/Data";
import SubNav from "../../components/SubNav";
import PageAuth from "./index";
import {Route, Switch} from "react-router-dom";
import ErrorPage404 from "../common/ErrorPage404";
import DsList from "../../components/DsList";
import Pagination from "../../components/Pagination";
import * as IboardApi from "../../api/Board";
import {getCookie} from "../../util/Cookie";
import Indicator from "../../components/Indicator";
import {addComma} from "../../util/Number";
import Button from "../../components/Button";
import Datatable from "../../components/Datatable";

const Mypage = ({location, history, ...props}) => {
    const HOME_URL = '/';
    let [orderInfo, setOrderInfo] = useState(null);
    let [orderCnt, setOrderCnt] = useState(0);
    let [favoriteInfo, setFavoriteInfo] = useState(null);
    let [downloadHistoryInfo, setDownloadHistoryInfo] = useState(null);

    let [loading, setLoading] = useState(true);
    let [textList, setTextList] = useState([]);
    let [page, setPage] = useState(1);
    let [maxPage, setMaxPage] = useState(1);
    let [limit, setLimit] = useState(10);
    let [totCnt, setTotCnt] = useState(0);

    let [pageOrder, setPageOrder] = useState(1);
    let [pageDownloadHistory, setPageDownloadHistory] = useState(1);
    let [pageFavorite, setPageFavorite] = useState(1);

    let [pageAnalysis, setPageAnalysis] = useState(1);
    let [analysisInfo, setAnalysisInfo] = useState([]);
    let [analysisCnt, setAnalysisCnt] = useState(0);


    const dispatch = useDispatch();

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    useEffect(() => {
        setLoading(true);
        let params = {
            board_id: "board_942f2cfa-3294-42c5-ac68-b4f3abbed510",
            page_size: limit,
            page: page - 1,
            user_id: getCookie("token")
        };
        IboardApi.readTextList(params).then(res => {
            let textList = res.result.textlist;
            setTextList(textList);
            setTotCnt(res.result.count)
            setLoading(false);
        })
    }, []);

    if(!isLogin){
        history.push("/login?redirectUri=" + location.pathname);
    }

    const descriptionRender = data => {
        return null;
    };

    const handleClickList = data => {
        history.push("/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510/article/" + data.id);
    };

    const isSetUserRedux = () => {
        const params = {
            token: userInfo.token
        };

        UserApi.readUserInfoByToken(params)
            .then(res => {
                const user_info = res.result;
                dispatch(setUserInfo({
                    id_key: user_info.id,
                    id: user_info.name,
                    email: user_info.email,
                    emailAgree: user_info.emailAgree === "true",
                    address: user_info.address,
                    phone: user_info.phone,
                    fullname: user_info.fullname,
                    apikey: user_info.apikey,
                    number_created_packages: user_info.number_created_packages,
                    number_of_edits: user_info.number_of_edits,
                    created: user_info.created,
                    token: user_info.id,
                    role: user_info.role,
                    center_code: user_info.center_code,
                    postcode: user_info.postcode,
                    detail_address: user_info.detail_address,
                    user_type: user_info.user_type
                }));
            })
            .catch(res => {
                console.log(res)
            });
    };

    // 유저 수정
    const isUpdateUser = (userInfo) => {
        console.log(userInfo)
        UserApi.updateUser(userInfo)
            .then(res => {
                swal('수정이 완료되었습니다.');
                isSetUserRedux();
            })
            .catch(res => {
                let json_res = JSON.parse(res.response);
                if(json_res.result === 101) swal('비밀번호는 8자 이상이어야 합니다.');
                else if(json_res.result === 102) swal('이메일 형식이 안맞습니다.');
            })
    };

    // 유저 탈퇴
    const isDeleteUser = (userId) => {
        swal({
            text: "정말 탈퇴하시겠습니까?",
            buttons: [
                "취소",
                "확인",
            ],
        }).then(function (isConfirm) {
            if(isConfirm) {
                let userDeleteParam = {
                    id: userId
                };

                UserApi.deleteUser(userDeleteParam)
                    .then(res => {
                        dispatch(delUserInfo());
                        swal('정상적으로 탈퇴하였습니다.').then(() => {
                            dispatch(logout());
                            window.location.assign(HOME_URL);
                        });
                    });
                }
            else {
                swal.close();
            }
        })
        // Swal.fire({
        //     title: '안내',
        //     text: '정말 탈퇴하시겠습니까?',
        //     // icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: '확인',
        //     cancelButtonText: '취소'
        // }).then((result) => {
        //     if(result.value){
        //         let userDeleteParam = {
        //             id: userId
        //         };
        //
        //         UserApi.deleteUser(userDeleteParam)
        //             .then(res => {
        //                 dispatch(delUserInfo());
        //                 swal('정상적으로 탈퇴하였습니다.').then(() => {
        //                     dispatch(logout());
        //                     window.location.assign(HOME_URL);
        //                 });
        //             });
        //     }
        // });
    };

    const isChangePassword = (onlyPasswordParams) => {
        UserApi.updateUser(onlyPasswordParams)
            .then(res => {
                swal("비밀번호 변경이 완료되었습니다.");
            })
            .catch(res => {
                swal('비밀번호는 8자 이상이어야 합니다.');
            })
    };

    const categoryPurpose = useSelector(state => {
        let rst = {};

        for(let [name, display] of Object.entries(state.user.categoryPurpose)){
            rst[name] = display
        }

        return rst;
    });

    // 오프라인 데이터 분석신청 히스토리
    useEffect(() => {
        // http://192.168.0.2:10000/api/ikan/read/list_proposal_analysis?limit=10&page=0&filter=user_id&value=limber93
        let params = {
            value: userInfo.id,
            filter: "user_id",
            limit: limit,
            page: pageAnalysis - 1
        };
        UserApi.readProposalAnalysis(params).then(res => {
            let newList = res.result.proposal_list.map(li => {
                li.subject = <div>{categoryPurpose[li.analysis_purpose]}</div>;
                // li.subject = li.use_date.start_date + " ~ " +li.use_date.end_date + " 오프라인 분석신청";
                li.date = li.apply_date;
                li.startDate = li.use_date.start_date;
                return li;
            });
            setAnalysisInfo(newList);
            setAnalysisCnt(res.result.count);
        });
    }, [pageAnalysis]);

    // 주문정보
    useEffect(() => {
        let params = {
            user_id_key: userInfo.id_key,
            limit: limit,
            page: pageOrder - 1
        };
        OrderApi.readListOrder(params).then(res => {
            let newList = res.result.order_list.map(li => {
                li.subject = li.display_package;
                return li;
            });
            console.log(newList);
            setOrderInfo(newList);
            setOrderCnt(res.result.count);
        });
    }, [pageOrder]);

    const isDeleteOrder = (package_id) => {
        let params = {
            id: package_id
        };
        OrderApi.deleteOrder(params).then(res => {
            swal('주문목록 삭제가 완료되었습니다.')
            // props.history.goBack();
        });
    };

    // 관심데이터 정보
    useEffect(() => {
        let params = {
            user_id_key: userInfo.id_key,
            limit: limit,
            page: pageFavorite - 1
        };
        FavoriteDataApi.readListFavoriteData(params).then(res => {
            setFavoriteInfo(res.result);
        });
    }, [pageFavorite]);

    const isDeleteFavoriteData = (favorite_id, package_id) => {

        swal({
            text: "정말 삭제하시겠습니까?",
            buttons: [
                "취소",
                "확인",
            ],
        }).then(function (isConfirm) {
            if(isConfirm) {
                let params = {
                    id: favorite_id,
                    package_id: package_id
                };
                FavoriteDataApi.deleteFavoriteData(params).then(res => {
                    swal('관심데이터 삭제가 완료되었습니다.');
                    // props.history.goBack();
                    let params = {
                        user_id_key: userInfo.id_key,
                        limit: limit,
                        page: 1 - 1
                    };
                    FavoriteDataApi.readListFavoriteData(params).then(res => {
                        setPageFavorite(1);
                        setFavoriteInfo(res.result);
                    });

                });
            }
            else {
                swal.close();
            }
        })

        // Swal.fire({
        //     title: '안내',
        //     text: '정말 삭제하시겠습니까?',
        //     // icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: '확인',
        //     cancelButtonText: '취소'
        // }).then((result) => {
        //     if(result.value === true){
        //         let params = {
        //             id: favorite_id,
        //             package_id: package_id
        //         };
        //         FavoriteDataApi.deleteFavoriteData(params).then(res => {
        //             swal('관심데이터 삭제가 완료되었습니다.');
        //             // props.history.goBack();
        //             let params = {
        //                 user_id_key: userInfo.id_key,
        //                 limit: limit,
        //                 page: 1 - 1
        //             };
        //             FavoriteDataApi.readListFavoriteData(params).then(res => {
        //                 setPageFavorite(1);
        //                 setFavoriteInfo(res.result);
        //             });
        //
        //         });
        //     }
        // });
    };

    // 다운로드 내역
    useEffect(() => {
        let params = {
            user_id_key: userInfo.id_key,
            limit: limit,
            page: pageDownloadHistory - 1
        };
        UserApi.readDownloadHistory(params).then(res => {
            setDownloadHistoryInfo(res.result)
        });
    }, [pageDownloadHistory]);

    const isDeleteDownloadData = (download_id) => {
        let params = {
            id: download_id
        };
        UserApi.deleteDownloadHistory(params).then(res => {
            swal('다운로드 이력 삭제가 완료되었습니다.')
        })
    };
    const dateRender = (dateString) => {
        return yyyymmdd(new Date(dateString), ".")
    };

    let subNav = [
        {
            display: "나의 정보",
            name: "/mypage"
        }, {
            display: "나의 구매내역",
            name: "/mypage/orderlist"
        }, {
            display: "관심데이터",
            name: "/mypage/favoritedata"
        }, {
            display: "나의문의",
            name: "/mypage/myfaq"
        }, {
            display: "다운로드내역",
            name: "/mypage/download_history"
        }, {
            display: "오프라인 분석신청 내역",
            name: "/mypage/proposal_analysis"
        }
    ];


    const getFavoriteInfo = () => {
        if(favoriteInfo !== null){
            let rst = [];
            favoriteInfo.favorite_list.map(row => {
                rst.push({
                    ...row,
                    subject: row.title
                });
            });

            return rst;
        }else return null;
    };

    const getDownloadHistoryInfo = () => {
        if(downloadHistoryInfo !== null){
            let rst = [];
            downloadHistoryInfo.download_history.map(row => {
                rst.push({
                    ...row,
                    subject: row.display_package
                });
            });

            return rst;
        }else return null;
    };

    return (
        <SubNav nav={subNav} history={history}>

            <Switch>
                <Route path="/mypage"
                       render={() => <UserUpdateComponent userInfo={userInfo}
                                                          deleteBtnName={"탈퇴"} modifyBtnName={"수정"}
                                                          isDeleteUser={(userId) => isDeleteUser(userId)}
                                                          isUpdateUser={(userInfo) => isUpdateUser(userInfo)}
                                                          isChangePassword={(onlyPasswordParams) => isChangePassword(onlyPasswordParams)}/>}
                       exact/>

                <Route path="/mypage/orderlist"
                       render={() => <>
                           {orderInfo && <>
                               <Datatable
                                   search={false}           //검색 사용여부
                                   head={[{
                                       name: "no",
                                       display: "구분",
                                       render: (value, item, no) => <div
                                           style={{textAlign: "center"}}>{(pageOrder - 1) * limit + no + 1}</div>,
                                       style: {
                                           width: '80px'
                                       }
                                   }, {
                                       name: "subject",
                                       display: "제목",
                                       render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => history.push("/dataset/" + item.package_id)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                       </div>
                                   }, {
                                       name: "price",
                                       display: "가격",
                                       render: value => <div style={{textAlign: "center"}}>{value === 0 ? "무료" : addComma(value)}</div>
                                   }, {
                                       name: "purpose",
                                       display: "구매목적",
                                       render: value => <div style={{textAlign: "center"}}>{value}</div>
                                   }, {
                                       name: "plan",
                                       display: "이용계획",
                                       render: value => <div style={{textAlign: "center"}}>{value}</div>
                                   }, {
                                       name: "date",
                                       display: "구매시간",
                                       style: {
                                           width: '220px'
                                       },
                                       render: value => <div style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value))}</div>
                                   }]}              //head 데이터 (필수)
                                   list={orderInfo}              //body 데이터 (필수)
                                   paging={true}            //페이지 사용여부
                                   page={pageOrder}              //현재 페이지 (필수)
                                   maxPage={Math.ceil(orderCnt / limit)}        //전체 페이지 (필수)
                                   padding={2}              //페이지번호개수 (선택)
                                   onPageMove={page => setPageOrder(page)}
                                   tableState={true}        //테이블상태 사용여부 (page필수)
                                   rowTotal={orderCnt}        //전체데이터 값 (선택)
                                   limit={false}            //출력개수 사용여부
                               />

                               {/*<DsList list={orderInfo}*/}
                               {/*        totCnt={orderCnt}*/}
                               {/*        descriptionRender={data => orderDescriptionRender(data)}*/}
                               {/*        onClick={data => history.push("/dataset/" + data.package_id)}*/}
                               {/*        page={pageOrder}*/}
                               {/*        limit={limit}*/}
                               {/*/>*/}
                               {/*<Pagination maxPage={Math.ceil(orderCnt / limit)}*/}
                               {/*            page={pageOrder}*/}
                               {/*            padding={2}*/}
                               {/*            onPageMove={page => setPageOrder(page)}*/}
                               {/*/>*/}
                           </>}
                       </>} exact/>

                <Route path="/mypage/favoritedata"
                       render={() => <>
                           {favoriteInfo !== null ? <>
                               <Datatable
                                   search={false}           //검색 사용여부
                                   head={[{
                                       name: "no",
                                       display: "구분",
                                       render: (value, item, no) => <div
                                           style={{textAlign: "center"}}>{(pageFavorite - 1) * limit + no + 1}</div>,
                                       style: {
                                           width: '80px'
                                       }
                                   }, {
                                       name: "subject",
                                       display: "제목",
                                       render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => history.push("/dataset/" + item.package_id)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                       </div>
                                   }, {
                                       name: "date",
                                       display: "추가시간",
                                       style: {
                                           width: '320px'
                                       },
                                       render: value => <div style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value))}</div>
                                   }, {
                                       name: "btn",
                                       display: "삭제",
                                       style: {
                                           width: '160px'
                                       },
                                       render: (value, data) => <div style={{textAlign: "center"}}>
                                           <button
                                               className={"analysisCancelBtn"}
                                               onClick={() => isDeleteFavoriteData(data.id, data.package_id)}>삭제</button>
                                       </div>
                                   }]}              //head 데이터 (필수)
                                   list={getFavoriteInfo()}              //body 데이터 (필수)
                                   paging={true}            //페이지 사용여부
                                   page={pageFavorite}              //현재 페이지 (필수)
                                   maxPage={Math.ceil(favoriteInfo.count / limit)}        //전체 페이지 (필수)
                                   padding={2}              //페이지번호개수 (선택)
                                   onPageMove={page => setPageFavorite(page)}
                                   tableState={true}        //테이블상태 사용여부 (page필수)
                                   rowTotal={favoriteInfo.count}        //전체데이터 값 (선택)
                                   limit={false}            //출력개수 사용여부
                               />
                           </> : <div><Indicator/></div>}
                       </>} exact/>

                <Route path="/mypage/download_history"
                       render={() => <>
                           {downloadHistoryInfo !== null ? <>
                               <Datatable
                                   search={false}           //검색 사용여부
                                   head={[{
                                       name: "no",
                                       display: "구분",
                                       render: (value, item, no) => <div
                                           style={{textAlign: "center"}}>{(pageDownloadHistory - 1) * limit + no + 1}</div>,
                                       style: {
                                           width: '80px'
                                       }
                                   }, {
                                       name: "subject",
                                       display: "제목",
                                       render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => history.push("/dataset/" + item.package_id)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                       </div>
                                   }, {
                                       name: "date",
                                       display: "다운로드시간",
                                       style: {
                                           width: '320px'
                                       },
                                       render: value => <div style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value))}</div>
                                   }]}              //head 데이터 (필수)
                                   list={getDownloadHistoryInfo()}              //body 데이터 (필수)
                                   paging={true}            //페이지 사용여부
                                   page={pageDownloadHistory}              //현재 페이지 (필수)
                                   maxPage={Math.ceil(downloadHistoryInfo.count / limit)}        //전체 페이지 (필수)
                                   padding={2}              //페이지번호개수 (선택)
                                   onPageMove={page => setPageDownloadHistory(page)}
                                   tableState={true}        //테이블상태 사용여부 (page필수)
                                   rowTotal={downloadHistoryInfo.count}        //전체데이터 값 (선택)
                                   limit={false}            //출력개수 사용여부
                               />
                           </> : <div><Indicator/></div>}
                       </>} exact/>

                <Route path="/mypage/myfaq"
                       render={() => <div>
                               <Datatable
                                   search={false}           //검색 사용여부
                                   head={[{
                                       name: "no",
                                       display: "구분",
                                       render: (value, item, no) => <div
                                           style={{textAlign: "center"}}>{(page - 1) * limit + no + 1}</div>,
                                       style: {
                                           width: '80px'
                                       }
                                   }, {
                                       name: "subject",
                                       display: "제목",
                                       render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                                       </div>
                                   }, {
                                       name: "date",
                                       display: "문의시간",
                                       style: {
                                           width: '320px'
                                       },
                                       render: value => <div style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value))}</div>
                                   }]}              //head 데이터 (필수)
                                   list={textList}              //body 데이터 (필수)
                                   paging={true}            //페이지 사용여부
                                   page={page}              //현재 페이지 (필수)
                                   maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                                   padding={2}              //페이지번호개수 (선택)
                                   onPageMove={page => setPage(page)}
                                   tableState={true}        //테이블상태 사용여부 (page필수)
                                   rowTotal={totCnt}        //전체데이터 값 (선택)
                                   limit={false}            //출력개수 사용여부
                               />
                       </div>} exact/>

                <Route path="/mypage/proposal_analysis"
                       render={() => <div>
                           <Datatable
                               search={false}           //검색 사용여부
                               head={[{
                                   name: "no",
                                   display: "구분",
                                   style: {
                                       width: '80px'
                                   },
                                   render: (value, item, no) => <div
                                       style={{textAlign: "center"}}>{(pageAnalysis - 1) * limit + no + 1}</div>
                               }, {
                                   name: "startDate",
                                   display: "신청날짜",
                                   render: value => <div style={{textAlign: "center"}}>{value}</div>
                               }, {
                                   name: "analysis_purpose",
                                   display: "목적",
                                   render: value => <div style={{textAlign: "center"}}>{categoryPurpose[value]}</div>
                               }, {
                                   name: "user_memo",
                                   display: "신청자 메모",
                               }, {
                                   name: "state_display",
                                   display: "상태",
                                   render: (value, data) => <div style={{textAlign: "center"}}>
                                       {value}
                                   </div>
                               }, {
                                   name: "admin_memo",
                                   display: "관리자 메모",
                               }, {
                                   name: "btns",
                                   display: "취소",
                                   render: (value, data) => <div style={{textAlign: "center"}}>
                                       {value}
                                       {data.state !== 'cancel' && <><button
                                           className={"analysisCancelBtn"}
                                           onClick={() => {
                                               let params = {
                                                   id: data.id
                                               };
                                               UserApi.updateProposalAnalysis(params).then(()=>{
                                                   let params = {
                                                       value: userInfo.id,
                                                       filter: "user_id",
                                                       limit: limit,
                                                       page: pageAnalysis - 1
                                                   };
                                                   UserApi.readProposalAnalysis(params).then(res => {
                                                       let newList = res.result.proposal_list.map(li => {
                                                           li.subject = <div>{categoryPurpose[li.analysis_purpose]}</div>;
                                                           // li.subject = li.use_date.start_date + " ~ " +li.use_date.end_date + " 오프라인 분석신청";
                                                           li.date = li.apply_date;
                                                           li.startDate = li.use_date.start_date;
                                                           return li;
                                                       });
                                                       setAnalysisInfo(newList);
                                                       setAnalysisCnt(res.result.count);
                                                   });
                                                   swal("취소가 완료되었습니다.");
                                               }).catch(() => {
                                                   swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.")
                                               })
                                           }}>신청취소</button></>}
                                   </div>
                               }]}              //head 데이터 (필수)
                               list={analysisInfo}              //body 데이터 (필수)
                               showLoading={false}    //로딩UI 사용여부
                               loading={limit}          //로딩테이블 데이터 개수 (필수)
                               paging={true}            //페이지 사용여부
                               page={pageAnalysis}              //현재 페이지 (필수)
                               maxPage={Math.ceil(analysisCnt / limit)}        //전체 페이지 (필수)
                               padding={2}              //페이지번호개수 (선택)
                               onPageMove={page => setPageAnalysis(page)}
                               tableState={true}        //테이블상태 사용여부 (page필수)
                               rowTotal={analysisCnt}        //전체데이터 값 (선택)
                               limit={false}            //출력개수 사용여부
                           />
                       </div>} exact/>

                <Route component={ErrorPage404}/>
            </Switch>
        </SubNav>
    );

};

export default Mypage;
