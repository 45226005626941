import {SET_META_INFO, SET_DATA_TITLE} from "../actions/DataAction";

const initialState = {
    metaInfo: {},
    dataTitle: {}
};

const DataReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_META_INFO:
            return Object.assign({}, state, {
                metaInfo: {
                    ...state.metaInfo,
                    ...action.metaInfo
                }
            });

        case SET_DATA_TITLE:
            return Object.assign({}, state, {
                dataTitle: {
                    ...state.dataTitle,
                    ...action.dataTitle
                }
            });

        default:
            return state;
    }
};

export default DataReducer;
