import React from 'react';
import ReactEcharts from "echarts-for-react";

const Scatter = (props) => {

    let option = {
        // tooltip: {},
        legend: [{
            // selectedMode: 'single',
            data: props.categories.map(function(a){
                return a.name;
            })
        }],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
            {
                type: 'graph',
                layout: 'force',
                data: props.data.nodes.map(res => {
                    let rst = {};
                    rst.id = res.id;
                    rst.name = res.name;
                    rst.symbolSize = res.symbolSize;
                    rst.category = res.category;
                    rst.label = {
                        normal: {
                            show: true
                        }
                    };
                    return rst;
                }),
                links: props.data.links,
                categories: props.categories,
                roam: true,
                focusNodeAdjacency: true,
                label: {
                    normal: {
                        position: "inside"
                    },
                },
                force: {
                    repulsion: 100
                }
            }
        ]
    };


    return (
        <div>
            <ReactEcharts
                option={option}
                notMerge={true}
                theme={props.theme}
                opts={{
                    height: props.height
                }}
                style={{
                    height: props.height
                }}
            />
        </div>
    )
};


/**
 *
 * @props {
 *      data: *[]
 *      heme: string
 * }
 *
 */
Scatter.defaultProps = {
    height: '400px',
    theme: 'default', // default, light, dark
    categories: require('./Categories.json'),
    data: require('./Data.json'),
};

export default Scatter
