import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import Comment from "./Comment";
import Button from "../../components/Button";

import 'froala-editor/css/froala_style.min.css';
import Skeleton from "react-loading-skeleton";
import {yyyymmdd} from "../../util/Date";


const Article = ({match, ...props}) => {

    const dispatch = useDispatch();

    let [boardInfo, setBoardInfo] = useState({});
    let [isInit, setIsInit] = useState(true);
    let [content, setContent] = useState("");
    let [subject, setSubject] = useState("");
    let [userId, setUserId] = useState("");
    let [date, setDate] = useState(new Date());
    let [username, setUsername] = useState("");
    let [hits, setHits] = useState(0);
    let [showLoading, setShowLoading] = useState(true);

    const userInfo = useSelector(state => state.user.info);

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);
        }
    });

    let board = match.params.board;
    let info = boardInfo[board];
    let articleId = match.params.articleId;

    useEffect(() => {
        setShowLoading(true);
        setContent("");
        let params = {
            text_id: articleId
        };
        IboardApi.readText(params).then(res => {
            let rst = res.result;
            console.log(rst);
            setUserId(res.result.user_id);
            setContent(rst.text);
            setSubject(rst.subject);
            setUsername(rst.user_name);
            setHits(rst.read_count);
            setDate(new Date(rst.cdate.replace(" ", "T")));
            setShowLoading(false);
        })
    }, [articleId]);


    if(info === undefined) return <ErrorPage404/>;

    if(isInit){
        setIsInit(false);
    }

    const removeArticle = () => {
        let params = {
            text_id: articleId
        };
        IboardApi.deleteText(params).then(res => {
            props.history.push("/board/" + board);
        });
    };

    return (
        <>
            <div className={"list-view"}>
                {showLoading ? <>
                        <h1><Skeleton/></h1>
                        <Skeleton count={10}/>
                    </>
                    :
                    <>
                        <div className={"list-view-title"}>
                            {/*{board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510" && <span className={"notice-question"}>질문</span>}*/}
                            {subject}
                        </div>
                        <div className={"article-date"}>
                            <span>작성자: {username}</span>
                            <span className={"dash"}>|</span>
                            <span>조회수: {hits}</span>
                            <span className={"dash"}>|</span>
                            <span>작성일: {yyyymmdd(date)}</span>
                        </div>
                        <div className={"fr-view"} dangerouslySetInnerHTML={{__html: content}}/>
                    </>
                }
            </div>
            <Comment textId={articleId} info={info} isA={(board === "board_942f2cfa-3294-42c5-ac68-b4f3abbed510")}/>
            <div className={"view-btn-wrap"}>

                <Button type={"line"} onClick={() => props.history.go(-1)}>목록</Button>

                {info.write && userId === userInfo.id_key &&
                <Button type={"line"}
                        onClick={() => props.history.push("/board/" + board + "/write/" + articleId)}>수정</Button>
                }

                {info.remove && userId === userInfo.id_key &&
                <Button type={"line"} onClick={e => removeArticle()}>삭제</Button>
                }
            </div>
        </>
    )
};

export default Article;
