/**
 * 숫자에 3자리마다 콤마 찍어줌!!
 * @param num
 * @returns {string}
 */
export const addComma = (num) => {
    if(num === 0 || num === "") return "0";

    let reg = /(^[+-]?\d+)(\d{3})/;
    let n = (num + '');

    while(reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

    return n;
};

/**
 * byte 용량을 환산하여 반환
 * 용량의 크기에 따라 MB, KB, byte 단위로 환산함
 * @param fileSize  byte 값
 * @param fixed     환산된 용량의 소수점 자릿수
 * @returns {String}
 */
export const byte = (fileSize, fixed) => {
    let str;

    fileSize = Number(fileSize);

    //MB 단위 이상일때 MB 단위로 환산
    if(fileSize >= 1024 * 1024){
        fileSize = fileSize / (1024 * 1024);
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = addComma(fileSize) + ' MB';
    }
    //KB 단위 이상일때 KB 단위로 환산
    else if(fileSize >= 1024){
        fileSize = fileSize / 1024;
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = addComma(fileSize) + ' KB';
    }
    //KB 단위보다 작을때 byte 단위로 환산
    else{
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = addComma(fileSize) + ' byte';
    }
    return str;
};
