import React, {useState,useEffect, useRef} from 'react';
import CheckBox from "./CheckBox";
import swal from "sweetalert";
import {terms} from "../util/Terms";
import {DaumPostcode} from "react-daum-postcode";
import SelectBox from "./SelectBox";
import {useSelector} from "react-redux";

import {index as privacyPolicy} from "../util/Terms/privacyPolicy";
import {index as termsAndConditions} from "../util/Terms/termsAndConditions";
import {index as privacyCollectionAndAgreePolicy} from "../util/Terms/privacyCollectionAndAgreePolicy"

// console.log(privacyPolicy);

const Login = (props) => {

    const [joinStep, setJoinStep] = useState(false);
    const [agree, setAgree] = useState(false);
    const [agreeB, setAgreeB] = useState(false);
    const [agreeC, setAgreeC] = useState(false);
    const [agreeALL, setAgreeALL] = useState(false);
    const [id, setId] = useState("");
    const [isDupId, setIsDupId] = useState(false); // 개발 시 기본값 true
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSamePassword, setIsSamePassword] = useState(true);
    const [email, setEmail] = useState("");
    const [isDupEmail, setIsDupEmail] = useState(false); // 개발 시 기본값 true
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [processLoading, setProcessLoading] = useState(false);
    const [emailAgree, setEmailAgree] = useState(false);
    const [detailAddress, setDetailAddress] = useState("");
    const [postCode, setPostCode] = useState("")
    const [isCheckId, setCheckId] = useState(false);
    const [isCheckEmail, setCheckEmail] = useState(false);
    const [isCheckName, setCheckName] = useState(false);
    const [isCheckAddress, setCheckAddress] = useState(false);
    const [isCheckPhone, setCheckPhone] = useState(false);
    const [isCheckDetailAddress, setCheckDetailAddress] = useState(false);
    const [isCheckPostCode, setCheckPostCode] = useState(false);
    const [isShowPostCode, setIsShowPostCode] = useState(false);
    const [selectBoxValue, setSelectBoxValue] = useState(false);

    const categoryDemand = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categorySortation)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    const changePassword = (target, value) => {
        if(target === "password"){
            setPassword(value);

            if(passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        }else if(target === "passwordConfirm"){
            setPasswordConfirm(value);

            if(password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };
    const isFirstStepValidate = () => {
        let validateArr = [agree, agreeB, agreeC];

        return validateArr.indexOf(false) < 0;
    };

    const isPassValidate = () => {

        let validateArr = [isSamePassword, isCheckId, isCheckEmail, isCheckName, isCheckAddress, isCheckPhone, agree, agreeB, agreeC,
            isCheckDetailAddress, isCheckPostCode, emailAgree, selectBoxValue];

        return validateArr.indexOf(false) < 0;
    };

    const checkEmptyValue = (target, setValue, value) => {
        if(value.length !== 0){
            target(true);
            setValue(value);
        }else{
            setValue("");
            target(false);
        }
    };

    const isSetPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if(str.length < 4){
            setPhone(str)
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            setPhone(tmp)
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            setPhone(tmp)
        }else{
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            setPhone(tmp)
        }
        if(str.length !== 0){
            setCheckPhone(true);
        }else{
            setCheckPhone(false);
        }
    };

    const joinProcess = () => {
        setProcessLoading(true);

        let regExpId = /^[0-9a-z\-_]+$/;
        if(id && !regExpId.test(id)){
            swal("ID는 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.").then(res => {
                refIdInput.current.focus()
            });
            setProcessLoading(false);
            return;
        }


        if(isPassValidate()){
            let joinInfo = {
                id: id,
                password: password,
                name: name,
                email: email,
                emailAgree: emailAgree,
                address: address,
                phone: phone,
                detail_address: detailAddress,
                postcode: postCode,
                user_type: selectBoxValue,
                termsAndConditions: agree,
                privacyPolicy: agreeB,
                privacyAgreePolicy: agreeC
            };
            props.joinProcess(joinInfo, () => {
                setProcessLoading(false)
            });
        }else{
            swal("모두 입력해주세요.");
            setProcessLoading(false);
        }
    };

    const toJoinSecondStep = () => {
        setJoinStep(true);
    };

    const handleFindPostCode = () => {
        setIsShowPostCode(true);
    };

    const handleAddress = (data) => {
        checkEmptyValue(setCheckAddress, setAddress, data.address);
        checkEmptyValue(setCheckPostCode, setPostCode, data.postcode === "" ? data.zonecode : data.postcode);
        setIsShowPostCode(false);
    };

    const handleAgreeChanged = (bool, type) => {
        if (type === "ALL") {
            setAgree(bool);
            setAgreeB(bool);
            setAgreeC(bool);
            setAgreeALL(bool);
        }
        else if (type === "A") {
            setAgree(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        else if (type === "B") {
            setAgreeB(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
        else if (type === "C") {
            setAgreeC(bool);
            if (agreeALL) {
                setAgreeALL(false)
            }
        }
    };

    const onIdBlur = (t) => {
        let regExpId = /^[0-9a-z\-_]+$/;
        if(id && !regExpId.test(id)){
            swal("ID는 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.").then(res => {
                t.focus()
            });
            return false;
        }

        return true;
    };

    const refIdInput = useRef(null);

    let checkText = isPassValidate() ? null : <span className={"all-check-text"}>&#8251; 모든 입력은 필수 입력 사항입니다.</span>;
    if (joinStep === false) {
        return (
            <>
                <div>
                    <div className={"join-form-step"}>
                        <div className={"join-form-step-wrap"}>
                            <p className={"join-form-step-head"}>STEP 01</p>
                            <p className={"join-form-step-body"}>약관 동의</p>
                        </div>
                        <div className={"join-form-step-wrap join-form-step-gray"}>
                            <p className={"join-form-step-head"}>STEP 02</p>
                            <p className={"join-form-step-body"}>정보 입력</p>
                        </div>
                        <div className={"join-form-step-wrap join-form-step-gray"}>
                            <p className={"join-form-step-head"}>STEP 03</p>
                            <p className={"join-form-step-body"}>가입 완료</p>
                        </div>
                    </div>
                    <ul className={"join-form"}>
                        <li style={{"padding-top": "15px", "padding-bottom": "15px"}}>
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'ALL')} checked={agreeALL} id={"agreeALL"}
                                      label={
                                          <>
                                              <div style={{"font-size": "15px", "color": "#000", "font-weight": "500"}}>
                                                  필수 약관에 모두 동의합니다.
                                              </div>
                                          </>
                                      }/>
                        </li>
                        <li>

                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: termsAndConditions.replace(/\n/g, '<br/>')}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'A')} checked={agree} id={"agree"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                                  이용약관에 동의합니다.
                                              </div>
                                              {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                              {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                                          </>
                                      }/>
                        </li>
                        <li>
                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: privacyPolicy.replace(/\n/g, '<br/>')}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'B')} checked={agreeB} id={"agreeB"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                                  개인정보처리방침에 동의합니다.
                                              </div>
                                              {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                              {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                                          </>
                                      }/>
                        </li>
                        <li>
                            <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: privacyCollectionAndAgreePolicy.replace(/\n/g, '<br/>')}} />
                            <CheckBox onChange={bool => handleAgreeChanged(bool, 'C')} checked={agreeC} id={"agreeC"}
                                      label={
                                          <>
                                              <div style={{"display": "inline-block", "color": "red"}}>
                                                  (필수)
                                              </div>
                                              <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                                  개인정보 수집 및 이용에 동의합니다.
                                              </div>
                                              {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
                                              {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
                                          </>
                                      }/>
                        </li>
                    </ul>
                    <button className={"button"} onClick={() => toJoinSecondStep()} style={{"margin-bottom": "20px"}}
                            disabled={!isFirstStepValidate()}>동의</button>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className={"join-form-step"}>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 01</p>
                        <p className={"join-form-step-body"}>약관 동의</p>
                    </div>
                    <div className={"join-form-step-wrap"}>
                        <p className={"join-form-step-head"}>STEP 02</p>
                        <p className={"join-form-step-body"}>정보 입력</p>
                    </div>
                    <div className={"join-form-step-wrap join-form-step-gray"}>
                        <p className={"join-form-step-head"}>STEP 03</p>
                        <p className={"join-form-step-body"}>가입 완료</p>
                    </div>
                </div>
                {isShowPostCode && <div className={"ds-2-daum-post-code-wrap"}>
                    <DaumPostcode
                        onComplete={data => handleAddress(data)}
                    />
                    <button className={"ds-2-daum-post-code-close-btn"}
                            onClick={() => setIsShowPostCode(false)}
                    >
                        닫기
                    </button>
                </div>}
                <div>
                    <ul className={"join-form"}>
                        <li>
                            <input type={"text"} placeholder={"ID"} title={"ID"} value={id}
                                   ref={refIdInput}
                                   onChange={e => {
                                       checkEmptyValue(setCheckId, setId, e.target.value);
                                   }}
                            />
                            <p className={"join-warning-text"}>&#8251; 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.</p><br></br>
                        </li>
                        <li>
                            <input type={"password"} title={"PASSWORD"} placeholder={"PASSWORD"}
                                   onChange={e => changePassword("password", e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <input type={"password"} placeholder={"PASSWORD CONFIRM"} title={"PASSWORD CONFIRM"}
                                   value={passwordConfirm}
                                   onChange={e => changePassword("passwordConfirm", e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                            <span className={"txt-wrong"}>{!isSamePassword && "비밀번호가 일치하지 않습니다."}</span>
                        </li>
                        <li>
                            <input type={"text"} placeholder={"EMAIL"} title={"EMAIL"}
                                   onChange={e => checkEmptyValue(setCheckEmail, setEmail, e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                            <p className={"join-warning-text"} style={{color: "#426d9e", opacity: 1}}>&#8251; 인증메일 및 정보메일 스팸 필터링 방지를 위해 회원가입 시 사내메일이 아닌 상용메일(gmail, naver, daum 등)을 이용해 회원가입을 해주세요.</p>
                        </li>
                        <li>
                            <CheckBox   label={
                                <>
                                    <div style={{"display": "inline-block", "color": "red"}}>
                                        (필수)
                                    </div>
                                    <div style={{"display": "inline-block", "margin-left": "3px"}}>
                                        이메일 수신 동의
                                    </div>
                                </>
                            }
                            id={"emailAgree"} checked={emailAgree} onChange={(bool) => {
                                setEmailAgree(bool);
                            }
                            }/>
                        </li>
                        <li>
                            <div className={"joinFormSelectBoxWrap"}>
                                <SelectBox options={categoryDemand}
                                           onChange={option => {
                                               setSelectBoxValue(option.name);
                                           }}
                                           value={selectBoxValue}
                                           placeholder={"회원 유형 선택"}
                                />
                            </div>
                        </li>
                        <li>
                            <input type={"text"} placeholder={"NAME"} title={"NAME"}
                                   onChange={e => checkEmptyValue(setCheckName, setName, e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <button className={"ds-2-find-address"} onClick={() => handleFindPostCode()}>
                                주소찾기
                            </button>
                            <input type={"text"} placeholder={"우편번호"} title={"우편번호"}
                                   className={"ds-2-find-address-input"}
                                   value={postCode}
                                   disabled={true}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <input type={"text"} placeholder={"주소"} title={"주소"}
                                   value={address}
                                   disabled={true}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <input type={"text"} placeholder={"상세주소"} title={"상세주소"}
                                   onChange={e => checkEmptyValue(setCheckDetailAddress, setDetailAddress, e.target.value)}
                                   value={detailAddress}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                        <li>
                            <input type={"text"} placeholder={"PHONE"} value={phone} title={"PHONE"}
                                   onChange={e => isSetPhoneNumber(e.target.value)}
                                   onClick={e => onIdBlur(refIdInput.current)}
                                   onFocus={e => onIdBlur(refIdInput.current)}
                            />
                        </li>
                    </ul>
                    {checkText}
                    <button className={"button"} onClick={() => joinProcess()} style={{"margin-bottom": "20px"}}
                            disabled={!isPassValidate()}>{(processLoading ?
                        "가입중" : "가입")}</button>
                </div>
            </>
        )
    }

    // return (
    //     <>
    //         {isShowPostCode && <div className={"ds-2-daum-post-code-wrap"}>
    //             <DaumPostcode
    //                 onComplete={data => handleAddress(data)}
    //             />
    //             <button className={"ds-2-daum-post-code-close-btn"}
    //                     onClick={() => setIsShowPostCode(false)}
    //             >
    //                 닫기
    //             </button>
    //         </div>}
    //         <div>
    //             <ul className={"join-form"}>
    //                 <li>
    //                     <input type={"text"} placeholder={"ID"} title={"ID"} value={id}
    //                            ref={refIdInput}
    //                            onChange={e => {
    //                                checkEmptyValue(setCheckId, setId, e.target.value);
    //                            }}
    //                     />
    //                     <p className={"join-warning-text"}>&#8251; 영문(소문자), 숫자, 특수문자(-, _)만 입력 가능합니다.</p><br></br>
    //                 </li>
    //                 <li>
    //                     <input type={"password"} placeholder={"PASSWORD"} title={"PASSWORD"}
    //                            onChange={e => changePassword("password", e.target.value)}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <input type={"password"} placeholder={"PASSWORD CONFIRM"} title={"PASSWORD CONFIRM"}
    //                            value={passwordConfirm}
    //                            onChange={e => changePassword("passwordConfirm", e.target.value)}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                     <span className={"txt-wrong"}>{!isSamePassword && "비밀번호가 일치하지 않습니다."}</span>
    //                 </li>
    //                 <li>
    //                     <input type={"text"} placeholder={"EMAIL"} title={"EMAIL"}
    //                            onChange={e => checkEmptyValue(setCheckEmail, setEmail, e.target.value)}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                     <p className={"join-warning-text"} style={{color: "#426d9e", opacity: 1}}>&#8251; 인증메일 및 정보메일 스팸 필터링 방지를 위해 회원가입 시 사내메일이 아닌 상용메일(gmail, naver, daum 등)을 이용해 회원가입을 해주세요.</p>
    //                 </li>
    //                 <li>
    //                     <CheckBox   label={"이메일 수신 동의(선택)"}
    //                                 id={"emailAgree"} checked={emailAgree} onChange={(bool) => {
    //                         setEmailAgree(bool);
    //                     }
    //                     }/>
    //                 </li>
    //                 <li>
    //                     <div className={"joinFormSelectBoxWrap"}>
    //                         <SelectBox options={categoryDemand}
    //                                    onChange={option => {
    //                                        setSelectBoxValue(option.name);
    //                                    }}
    //                                    value={selectBoxValue}
    //                                    placeholder={"회원 유형 선택"}
    //                         />
    //                     </div>
    //                 </li>
    //                 <li>
    //                     <input type={"text"} placeholder={"NAME"} title={"NAME"}
    //                            onChange={e => checkEmptyValue(setCheckName, setName, e.target.value)}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <button className={"ds-2-find-address"} onClick={() => handleFindPostCode()}>
    //                         주소찾기
    //                     </button>
    //                     <input type={"text"} placeholder={"우편번호"} title={"우편번호"}
    //                            className={"ds-2-find-address-input"}
    //                            value={postCode}
    //                            disabled={true}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <input type={"text"} placeholder={"주소"} title={"주소"}
    //                            value={address}
    //                            disabled={true}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <input type={"text"} placeholder={"상세주소"} title={"상세주소"}
    //                            onChange={e => checkEmptyValue(setCheckDetailAddress, setDetailAddress, e.target.value)}
    //                            value={detailAddress}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <input type={"text"} placeholder={"PHONE"} title={"PHONE"} value={phone}
    //                            onChange={e => isSetPhoneNumber(e.target.value)}
    //                            onClick={e => onIdBlur(refIdInput.current)}
    //                            onFocus={e => onIdBlur(refIdInput.current)}
    //                     />
    //                 </li>
    //                 <li>
    //                     <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: termsAndConditions.replace(/\n/g, '<br/>')}} />
    //                     <CheckBox onChange={bool => handleAgreeChanged(bool, 'A')} checked={agree} id={"agree"}
    //                               label={
    //                                   <>
    //                                       이용약관에 동의합니다.
    //                                       {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
    //                                       {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
    //                                   </>
    //                               }/>
    //                 </li>
    //                 <li>
    //                     <div className={"privacyPolicyWrap"} dangerouslySetInnerHTML={{__html: privacyPolicy.replace(/\n/g, '<br/>')}} />
    //                     <CheckBox onChange={bool => handleAgreeChanged(bool, 'B')} checked={agreeB} id={"agreeB"}
    //                               label={
    //                                   <>
    //                                       개인정보처리방침에 동의합니다.
    //                                       {/*<i onClick={() => terms("joinPolicy")} >이용약관</i>&nbsp;및&nbsp;*/}
    //                                       {/*<i onClick={() => terms("joinPolicy")} style={{padding: 0}}>개인정보처리방침</i> 동의*/}
    //                                   </>
    //                               }/>
    //                 </li>
    //                 <li>
    //                 <CheckBox onChange={bool => handleAgreeChanged(bool, 'ALL')} checked={agreeALL} id={"agreeALL"}
    //                           label={
    //                               <>
    //                                   전체 동의
    //                               </>
    //                           }/>
    //                 </li>
    //             </ul>
    //             {checkText}
    //             <button className={"button"} onClick={() => joinProcess()}
    //                     disabled={!isPassValidate()}>{(processLoading ?
    //                 "가입중" : "가입")}</button>
    //         </div>
    //     </>
    // );
};

Login.propTypes = {
    joinProcess: () => {
    }
};

export default Login;
