import React from 'react';
import ReactEcharts from "echarts-for-react";

const Line = (props) => {

    const getData = () => {
        return props.data.map(data => {
            return {
                ...data,
                type: 'line',
                barWidth: props.barWidth,
            }
        });
    };

    getData();

    let option = {
        color: props.barColor,
        tooltip: {
            trigger: 'axis',
            axisPointer: {type: props.axisPointerType}
        },
        grid: {
            left: "20px",
            right: "20px",
            bottom: "10px",
            top: "20px",
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: props.xAxisData,
                axisTick: {alignWithLabel: true}
            }
        ],
        yAxis: [{type: 'value'}],
        series: getData()
    };


    return (
        <div>
            <ReactEcharts
                option={option}
                notMerge={true}
                theme={props.theme}
                opts={{
                    height: props.height
                }}
                style={{
                    height: props.height
                }}
            />
        </div>
    )
};


/**
 *
 * @props {
 *      axisPointerType: string,
 *      data: *[],
 *      color: string[],
 *      theme: string,
 *      xAxisData: string[]
 *  }
 *
 */
Line.defaultProps = {
    height: '400px',
    axisPointerType: 'line', // line, shadow
    theme: 'default', // default, light, dark
    xAxisData: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    data: [
        {
            name: 'Test 1',
            data: [10, 52, 200, 334, 390, 330, 220]
        }, {
            name: 'Test 2',
            data: [220, 330, 390, 234, 200, 52, 10]
        }
    ],
    barColor: ['#EE4B55', '#e9ee68'],
    barWidth: "40%"
};

export default Line;
