import React, {useEffect, useState} from 'react';
import * as DataApi from "../../api/Data";
import DatasetList from '../../components/DatasetList';
import {getParameterByName, getParamsFromQueryString, serialize} from '../../util/Url';
import Pagination from "../../components/Pagination";
import DatasetFilterNew from "../../components/DatasetFilterNew";


let oldParam = {};
const ListDataset = (props) => {

    const getPage = () => {
        let qs = getParamsFromQueryString(props.history.location.search);
        let page = qs.hasOwnProperty("page") ? JSON.parse(JSON.stringify(Number(qs.page[0]))) : 1;
        return page;
    };

    let [dataList, setDataList] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false);
    let [page, setPage] = useState(getPage());
    let [maxPage, setMaxPage] = useState(20);
    let [tags, setTags] = useState([]);
    let [formats, setFormats] = useState([]);
    let [category, setCategory] = useState([]);
    let [filters, setFilters] = useState([]);
    let [totalCount, setTotalCount] = useState(0);
    let [limit] = useState(10);

    const searchData = (params = false, page = 1) => {
        let p = {};
        if(params){
            p = params;
            oldParam = p;
        }else{
            p = oldParam;
        }
        p.page = getPage() -1;
        p.limit = limit;
        p.mixData = true;

        DataApi.readSearchData(p).then(res => {
            setTotalCount(res.result.count);
            setFormats(res.result.search_facets.res_format.items);
            setTags(res.result.search_facets.tags.items);
            setCategory(res.result.search_facets.DataSet_DataSet_theme.items);
            setDataList(res.result.results);
            // setPage(p.page + 1);
        });
    };

    useEffect(() => {
        setMaxPage(Math.ceil(totalCount / limit));
    }, [totalCount]);

    useEffect(() => {
        // searchData(false, page);
    }, [page]);

    const onPageMove = (page) => {

        let qs = getParamsFromQueryString(props.history.location.search);
        qs.page = [String(page)];

        props.history.push({
            pathname: "/datasetMix",
            search: serialize(qs)
        });

        // setPage(page);
    };

    const handleFilterChange = (params) => {

        if((oldParam.hasOwnProperty("tag") ? oldParam.tag.length : 0) +
            (oldParam.hasOwnProperty("category") ? oldParam.category.length : 0) +
            (oldParam.hasOwnProperty("word") ? oldParam.word.length : 0) +
            (oldParam.hasOwnProperty("format") ? oldParam.format.length : 0)
            !=
            (params.hasOwnProperty("tag") ? params.tag.length : 0) +
            (params.hasOwnProperty("category") ? params.category.length : 0) +
            (params.hasOwnProperty("word") ? params.word.length : 0) +
            (params.hasOwnProperty("format") ? params.format.length : 0))
            params.page = String(1);
        else params.page = String(getPage());


        if(isLoaded){
            props.history.push({
                pathname: "/datasetMix",
                search: serialize(params)
            });
        }
        else {
            props.history.replace({
                pathname: "/datasetMix",
                search: serialize(params)
            });
            setIsLoaded(true);
        }

        searchData(params);
    };

    return (
        <>
            <DatasetFilterNew tags={tags} formats={formats} category={category} onChange={(params) => handleFilterChange(params)} history={props.history}/>
            <DatasetList list={dataList} totalCount={totalCount}/>
            <Pagination page={getPage()}
                        maxPage={maxPage}
                        padding={2}
                        onPageMove={num => onPageMove(num)}
            />
        </>
    )

};

export default ListDataset;
