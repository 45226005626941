import React from 'react';
import FaqList from "./board/FaqList";

const WhatWeDo = () => {

    return (
        <>
            <div className={"mobile-bg-allocation"}/>
            <div className={"bg-WhatWeDo bg-allocation"} style={{
                backgroundColor: "#004C9D"
            }}>
                <div className={"allocation-text-area-wrap"}>
                    <div className={"allocation-text-area"}>
                        <h1 className={"allocation-text"}>
                            <strong>데이터 배당 지급 안내</strong>
                        </h1>
                        <p>지&nbsp;&nbsp;급&nbsp;&nbsp;일 : 2020. 2. 20.(목) ∼ 2. 22.(토), 순차 지급<br />
                            <small>※ 해당 시·군 : 수원, 고양, 용인, 부천, 화성, 남양주, 평택, 파주, 광주,<br /><span className={"space"}>&emsp;</span> 광명, 이천, 오산, 하남, 양주, 안성, 여주, 양평, 동두천, 과천, 가평</small><br />
                            지급대상 : 19. 4. 1. ~ 12. 31. 중 사용실적이 있는<br /><span className={"space"}>&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;</span>20개 시·군의 지역화폐 카드<br />
                            배당방법 : 경기지역화폐 카드에 인센티브로 자동 지급<br /><span className={"space"}>&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;</span>(신청 필요 없음)<br />
                            배당금액 : 카드 당 120원<br />
                            관련문의 : 120콜센터(031-120), <br /><span className={"space"}>&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;</span>데이터정책과(031-8008-3952,3958)</p>
                    </div>
                </div>
            </div>
            <div className={"box-gray"} style={{backgroundColor: "#FFF"}}>
                <div className={"img-box bg17 ds-allocation"}>
                    <div className={"inner-text17"}>
                        <dl>
                            <dt>경기 데이터 배당 <span className={"text-point"}>지급 의미</span></dt>
                            <dd>
                                <p>‘경기 데이터 배당’은 아주 적은 금액을 지급하지만 사용자가 생산한 데이터가 배당(수익)으로 되돌아오는 ‘세계최초의 데이터 주권 실천사례’라는 의미가 있으며, 이번 데이터 배당이 도민과 기업에게 데이터 주권에 대한 논의를 확산하고, 더 나아가 누구든 데이터를 통해 얻은 이익은 생산자인 사용자에게 수익을 나누어 주는 선례가 되었으면 합니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg11 ds-allocation"}>
                    <div className={"inner-text11"}>
                        <dl>
                            <dt><span className={"text-point"}>데이터 배당</span>이 뭔가요?</dt>
                            <dd>
                                <p>데이터 배당이란 기업이 소비자들의 데이터를 수집·활용하여 수익을 창출하는 경우 데이터의 생산에 기여한 각 개인에게도 수익을 되돌려 주어야 한다는 개념입니다.<br /><br />경기지역화폐를 사랑해주신 도민 여러분들을 위하여 경기도는 세계 최초로 데이터 배당을 통해 지역화폐 사용통계 데이터의 거래 수익을 나누어 드리고자 합니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg12 ds-allocation"}>
                    <div className={"inner-text12"}>
                        <dl>
                            <dt>데이터 배당을 <span className={"text-point"}>왜</span> 하는 건가요?</dt>
                            <dd>
                                <p>글로벌 선진 IT 기업들은 각종 데이터 등을 수집·활용하여 수익을 얻고 있습니다. <br /><br />경기도는 데이터 생산자인 경기도민의 데이터 주권을 선언하고 데이터 생산 기여자에 대한 보상체계의 첫 시험대를 마련했습니다. 데이터 보상 실현으로 데이터 경제 선순환 체계가 이루어 질 수 있습니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg13 ds-allocation"}>
                    <div className={"inner-text13"}>
                        <dl>
                            <dt>데이터에 제 <span className={"text-point"}>개인정보</span>가 포함되어 있나요?</dt>
                            <dd>
                                <p>거래되는 데이터는 경기지역화폐 이용자의 개인정보가 아닌, 지역화폐를 이용하면서 생산되는 연령대별, 성별, 결제내역 등 통계적 데이터이며, 또한 범주화 비식별화를 통해 개인정보는 철저히 제거 됩니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg14 ds-allocation"}>
                    <div className={"inner-text14"}>
                        <dl>
                            <dt>데이터 <span className={"text-point"}>배당</span>은 어떻게 받을 수 있나요?</dt>
                            <dd>
                                <p>2019년 4월 1일 ~ 12월 31일 까지 한번이라도 결제한 기록이 있으면 경기지역화폐 카드에 자동으로 배당금이 지급됩니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg15 ds-allocation"}>
                    <div className={"inner-text15"}>
                        <dl>
                            <dt>지역화폐 배당<span className={"text-point"}>정책</span>은?</dt>
                            <dd>
                                <p>배당정책(주체) : 배당정책 결정은 경기도이며, 시행주체는 시·군입니다. <br/>배당대상 : 2019년 4월 ~ 12월 중 이용 실적이 있는 경기지역화폐 카드<br/>배당금 계산방식 : (지역화폐 데이터 거래금액 – 처리비용)/데이터 제공 경기지역화폐 카드 수 <br/>데이터 배당 명칭 : 경기 데이터 배당</p>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={"img-box bg16 ds-allocation"}>
                    <div className={"inner-text16"}>
                        <dl>
                            <dt><span className={"text-point"}>카드</span>를 여러 장 가지면 배당을 더 많이 받지 않나요?</dt>
                            <dd>
                                <p>해당 시군에서만 사용가능한 지역화폐 특성상 여러 시군의 지역화폐를 사용하는 경우 지역경제 활성화에 더 기여하게 됩니다.<br />배당금은 결제 기록이 있는 모든 카드에 동일하게 지급됩니다.</p>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={"ds-2-allocation-more-wrap"}>
                <p className={"ds-2-allocation-more"}>이런 부분도 궁금하실 거예요~</p>
                <div className={"ds-2-allocation-text-box"}>
                    <div className={"ds-2-allocation-text-box-wrap"}>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>왜 우리지역은 배당금이 지급되지 않나요?</h2>
                            <p>이번 데이터 배당은 지역화폐 카드 데이터를 활용하여 발생하는 수익을, 다시 데이터 생산에 기여한 사용자에게 되돌려주는 개념이기 때문에 경기지역화폐 카드를 사용하는 지역 중 데이터 배당 동의 시ㆍ군만 지급 대상입니다.</p>
                        </div>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>거래된 데이터가 어떻게 쓰이는가?</h2>
                            <p>거래되는 데이터는 지역별, 연령별 상품 선호도 판매량 분석 등 전통시장 및 골목상권 활성화 방안 연구, 지역화폐의 경제적 효과 분석 등에 활용하기 위해 교육기관, 연구소, 기업과 같은 수요 기관에 제공하게 됩니다.<br />
                                예) 지역화폐 사용처를 분석해보니, 골목상권의 매출 증대에 기여했고, 40~50대는 한식 위주의 가맹점 사용이 많아, 향후 지역경제 활성화 정책방향을 정하는데 활용이 됩니다.</p>
                        </div>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>내 개인정보를 나의 동의없이 유통해도 되는가?</h2>
                            <p>개인정보를 유통하는 것이 아닙니다. 경기도에서 지역화폐를 사용한 분들의 통계성 데이터로써 개인정보 자료는 없습니다.<br />
                                수집 단계부터 개인정보는 모두 삭제된 상태로 수집되며, 이 자료에 추가로 비식별화 및 범주화 과정까지 거친 데이터를 활용합니다.<br />
                                ※ 데이터 범주화 및 총계처리를 통한 거래예정 지역화폐 데이터 현황(거래일자, 성별코드(여자:F/남자:M), 연령(30대/40대/50대), 상품ID(수원페이/부천페이), 주간결재액, 가맹점 번호)
                            </p>
                        </div>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>데이터의 사후처리 방안은 어떻게 되는가?<br />(유효기간, 폐기여부)</h2>
                            <p>데이터의 유효기간은 향후 연구를 통해 확정할 예정이며, 데이터 활용 관련 폐기 절차를 수립하여 안전하게 데이터가 취급될 수 있도록 진행할 예정입니다.</p>
                        </div>
                    </div>
                    <div className={"ds-2-allocation-text-box-wrap"}>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>앞으로도 계속 배당을 받을 수 있나요?</h2>
                            <p>금번에 시범적으로 실시하고 향후 폭 넓은 의견수립과 데이터 제공 및 활용 상황에 따라 데이터 배당정책을 보완해 나갈 계획입니다.</p>
                        </div>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>배당금액은 얼마나 되는가?</h2>
                            <p>데이터 생산에 기여한 지역화폐카드 사용자를 대상으로 지급할 예정이며, 데이터 거래금액에서 처리비용을 제외하고  남은 금액을 지역화폐로 균등하게 배당금을 지급합니다.</p>
                        </div>

                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>무슨 말인지 잘 모르겠다, 좀 더 정확하게 알려줄 수 없는가?</h2>
                            <p>데이터배당과 관련, 궁금한 사항은 경기도청 120콜센터와 아래 문의처로 연락주시면 상세한 내용을 안내 받으실 수 있습니다.<br />
                            ※ 연락처<br />
                            &nbsp;&nbsp;-&nbsp;전화&nbsp;:&nbsp;031-8008-3952, 8008-3958, 8008-3854, 8008-3855<br />
                            &nbsp;&nbsp;-&nbsp;이메일&nbsp;:&nbsp;nearby@gg.go.kr, firstwon77@gg.go.kr, hsyun@gg.go.kr, wslee0522@gg.go.kr
                            </p>
                        </div>
                        <div className={"ds-2-allocation-text-box-item"}>
                            <h2>데이터 3법 통과여부와 데이터 배당 간 관계가 있는 부분인가?</h2>
                            <p>금번 활용하는 데이터는 개인정보를 포함하는 것이 아니기 때문에 개인정보를 활용하기 위한 근거가 되는 데이터 3법과는 직접 관계가 없습니다.</p>
                        </div>
                    </div>
                    <div style={{clear:"both"}}></div>
                </div>
            </div>
        </>
    )
};

export default WhatWeDo;
