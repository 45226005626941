import {index as privacyPolicy} from "./privacyPolicy";
import {index as termsAndConditions} from "./termsAndConditions";
import {index as OrderPolicy} from "./orderPolicy"
import {index as JoinPolicy} from "./JoinPolicy"

export const terms = (term) => {
    let body = document.body;
    let element = document.createElement('div');
    element.classList.add("terms");
    let wrap = document.createElement('div');
    wrap.classList.add("ds-terms-wrap");
    let inner = document.createElement('div');
    inner.classList.add("ds-terms-inner");
    let txtWrap = document.createElement('div');
    txtWrap.classList.add("ds-terms-txt-wrap");
    let btn = document.createElement('button');
    btn.classList.add("ds-terms-button-out");
    btn.onclick = () => {
        body.removeChild(element)
    };
    let txtInner = document.createElement('div');
    txtInner.classList.add("ds-terms-txt-inner");
    let txt = document.createElement('pre');
    txt.classList.add("ds-terms-txt");

    if(term === "privacyPolicy"){
        txt.innerText = privacyPolicy
    }else if(term === "termsAndConditions"){
        txt.innerText = termsAndConditions;
    }
    else if (term === "orderPolicy") {
        txt.innerText = OrderPolicy;
    }

    else if (term === "joinPolicy") {
        txt.innerText = JoinPolicy;
    }

    body.appendChild(element);
    element.appendChild(wrap);
    wrap.appendChild(inner);
    inner.appendChild(txtWrap);
    inner.appendChild(btn);
    txtWrap.appendChild(txtInner);
    txtInner.appendChild(txt);
};
