import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import * as DataApi from "../../api/Data";
import * as OrderApi from "../../api/Order"
import swal from "sweetalert";
import Indicator from "../../components/Indicator";
import {addComma} from "../../util/Number";

const Order = ({match, ...props}) => {

    let [dataInfo, setDataInfo] = useState(null);

    useEffect(() => {
        let params = {
            id: match.params.dataId
        };
        DataApi.readData(params).then(res => {
            setDataInfo(res.result);
        });
    }, []);

    return (
        <>
            {dataInfo !== null ? <OrderInfo key={match.params.id} dataInfo={dataInfo}/> : <div><Indicator /></div>}
        </>
    )

};

export default Order;

const OrderInfo = ({dataInfo}) => {
    let test_price = "100000";
    let [quantity, setQuantity] = useState(1);
    let [purpose, setPurpose] = useState('구입 목적을 입력해주세요.');
    let [plan, setPlan] = useState('이용계획을 입력해주세요.');
    let [isCheckPurpose, setCheckPurpose] = useState(false);
    let [isCheckPlan, setCheckPlan] = useState(false);

    const userInfo = useSelector(state => state.user.info);
    const isPayment = (dataInfo) => {
        // 메타데이터에서 가져와야함

        let params = {
            user_id_key: userInfo.id_key,
            package_id: dataInfo.id,
            quantity: quantity,
            price: test_price,
            payment: 'card',
            user_name: userInfo.id,
            package_name: dataInfo.title,
            purpose: purpose,
            plan: plan
        };
        OrderApi.addOrder(params)
            .then(res => {
                swal('주문신청이 완료되었습니다.')
            })
            .catch(res => {

                swal('주문신청이 실패하였습니다. \n 다시 시도해주세요.')
            })
    };
    const checkEmptyValue = (target, setValue, value) => {
        if (value.length !== 0) {
            target(true);
            setValue(value);
        }
        else {
            target(false);
        }
    };

    const isPassValidate = () => {
        let validateArr = [isCheckPurpose, isCheckPlan];

        return validateArr.indexOf(false) < 0;
    };

    return (
        <>
            <div>
                <h1>주문정보</h1>
                <p>주문자<span>{userInfo.fullname}</span></p>
                <p>이메일<span>{userInfo.email}</span></p>
                <p>전화번호<span>{userInfo.phone}</span></p>
            </div>

            {/*데이터상세정보따라감*/}
            <h1> 데이터 정보 </h1>
            <div>
                <p>제목 <span>{dataInfo.title}</span></p>
                <p>설명 <span>{dataInfo.notes}</span></p>
            </div>
            <div>
                <h3>tags</h3>
                <p>{dataInfo.tags.map(tag => <span key={tag.display_name}>{tag.display_name}</span>)}</p>
            </div>
            <div>
                <h1>구입목적(필수)</h1>
                <textarea placeholder="구입목적을 입력해주세요." onChange={e => checkEmptyValue(setCheckPurpose, setPurpose, e.target.value)}/>

            </div>
            <div>
                <h1>이용계획(필수)</h1>
                <textarea placeholder="이용계획을 입력해주세요." onChange={e => checkEmptyValue(setCheckPlan, setPlan, e.target.value)}/>
            </div>

            <div>
                {/*추가*/}
                <div>
                    <button onClick={()=>isPayment(dataInfo)} disabled={!isPassValidate()}> 이용신청하기 </button>
                </div>
            </div>

            <div>
                가격: {addComma(test_price)}원
            </div>
        </>
    );
};

