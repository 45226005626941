import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import * as DataApi from "../../api/Data";
import DatasetList from "../../components/DatasetList";
import SearchForm from "../../components/SearchForm";
import ThumImg1 from "../../assets/images/thum_img1.png";
import ThumImg2 from "../../assets/images/thum_img2.png";
import ThumImg3 from "../../assets/images/thum_img3.png";
import ThumImg4 from "../../assets/images/thum_img4.png";
import ThumImg5 from "../../assets/images/thum_img5.png";
import ThumImg6 from "../../assets/images/thum_img6.png";
import {addComma} from "../../util/Number";
import DatasetCard from "../../components/DatasetCard";
import Pie from "../../components/Chart/Pie";
import Bar from "../../components/Chart/Bar";

function Main(props){

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    let recommendDataDummy = require('./RecommendData.json');
    let [recommendData, setRcommendData] = useState(recommendDataDummy);

    let [popularKeyword, setPopularKeyword] = useState([]);
    let [popularDataset, setPopularDataset] = useState([]);
    let [popularDownloadDataset, setPopularDownloadDataset] = useState([]);
    let [latestData, setLatestData] = useState([]);
    let [totalCount, setTotalCount] = useState(0);

    let [pieTab, setPieTab] = useState("A");

    let [tab, setTab] = useState("A");


    useEffect(() => {
        let ketwordParam = {
            time_unit: "d",
            howmany: 5
        };
        DataApi.readSearchPopularKeyword(ketwordParam).then(res => {
            console.log(res.result.popular_countlist);
            setPopularKeyword(res.result.popular_list);
        });

        let popularDatasetParam = {
            type: "view",
            time_unit: "d",
            howmany: 10
        };
        DataApi.readSearchPopularData(popularDatasetParam).then(res => {
            console.log(res.result.popular_countlist);
            setPopularDataset(res.result.popular_countlist);
        });

        let popularDownloadDatasetParam = {
            type: "download",
            time_unit: "d",
            howmany: 10
        };
        DataApi.readSearchPopularData(popularDownloadDatasetParam).then(res => {
            console.log(res.result.popular_countlist);
            setPopularDownloadDataset(res.result.popular_countlist);
        });

        let latestDataParam = {
            howmany: 10
        };
        DataApi.readLatestData(latestDataParam).then(res => {
            // console.log(res.result.results);
            setLatestData(res.result.results);
        });

        DataApi.readDataCount().then(res => {
            setTotalCount(res.result.count);
        });
    }, []);


    return (
        <div>
            <div className={"main-img"}>
                <div className={"main-data-search"}>
                    {/*<div className={"title"}>수집 데이터 {addComma(totalCount)} 개</div>*/}
                    <div className={"title"}>지역경제 데이터를 한곳에서 확인해 보세요!</div>
                    <div className={"search-wrap"}>
                        <SearchForm {...props}/>
                    </div>
                    <div className={"main-tags"}>
                        {popularKeyword.length > 0 && <>
                            <span className={"main-tags-keyword"}>인기검색어 : </span>
                            {popularKeyword.map(keyword =>
                                <Link key={JSON.stringify(keyword)} to={"/dataset?word=" + keyword.keyword}>
                                    <span>{keyword.keyword}</span>
                                </Link>
                            )}
                        </>
                        }
                    </div>
                </div>
            </div>
            <div className={"main-icon-group"}>
                <ul>
                    <li onClick={() => props.history.push("/dataset")}>
                        <dl>
                            <dt className={"icon1"}></dt>
                            <dd>전체</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역화폐")}>
                        <dl>
                            <dt className={"icon2"}></dt>
                            {/*LC*/}
                            <dd>지역화폐</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=기업")}>
                        <dl>
                            <dt className={"icon3"}></dt>
                            {/*EN*/}
                            <dd>기업</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역트랜드")}>
                        <dl>
                            <dt className={"icon4"}></dt>
                            {/*SN*/}
                            <dd>지역트랜드</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=취업")}>
                        <dl>
                            <dt className={"icon5"}></dt>
                            {/*JB*/}
                            <dd>취업</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=지역현황")}>
                        <dl>
                            <dt className={"icon6"}></dt>
                            {/*GS*/}
                            <dd>지역현황</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=기업신용보증")}>
                        <dl>
                            <dt className={"icon7"}></dt>
                            {/*AC*/}
                            <dd>기업신용보증</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?category=영상")}>
                        <dl>
                            <dt className={"icon9"}></dt>
                            {/*AC*/}
                            <dd>영상</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?paytag=pay")}>
                        <dl>
                            <dt className={"icon-pay"}></dt>
                            {/*AC*/}
                            <dd>유료 데이터</dd>
                        </dl>
                    </li>
                    <li onClick={() => props.history.push("/dataset?paytag=free")}>
                        <dl>
                            <dt className={"icon-free"}></dt>
                            {/*AC*/}
                            <dd>무료 데이터</dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div className={"add-section"}>
                <div className={"add-section-inner"}>
                    {/*데이터셋 아이디 a55cf730-f2f5-44f5-8b17-7cea13b76ff9*/}
                    <h2 className={"add-section-title"} /*onClick={e => props.history.push('dataset/a55cf730-f2f5-44f5-8b17-7cea13b76ff9')}*/>도내 연령 및 지역 별 관심 일자리 통계<span style={{fontSize: "0.3em"}}>(단위: 명)</span></h2>
                </div>
                <div className={"add-section-inner"}>
                    <div>
                        <div className={"add-section-inner-left-sub"}>
                            <span className={"add-section-inner-left-sub-text " + (pieTab === "A" ? "sub-active" : "" )} onClick={e => setPieTab("A")}>연령별</span>
                        </div>
                        <div className={"add-section-inner-left-sub"}>
                            <span className={"add-section-inner-left-sub-text " + (pieTab === "B" ? "sub-active" : "" )} onClick={e => setPieTab("B")}>지역별</span>
                        </div>
                    </div>
                    {pieTab === "A" &&
                        <div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>20대</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"
                                     ]}
                                     seriesData={[
                                         {'name': '경영·사무', 'value': 804}, {'name': 'IT·인터넷', 'value': 520}, {'name': '전문직', 'value': 467}, {'name': '생산·제조', 'value': 308}, {'name': '영업·고객상담', 'value': 208}, {'name': '건설', 'value': 196}, {'name': '유통·무역', 'value': 188}, {'name': '의료', 'value': 175}, {'name': '디자인', 'value': 165}, {'name': '서비스', 'value': 165}, {'name': '교육', 'value': 139}, {'name': '특수계층·공공', 'value': 81}, {'name': '미디어', 'value': 57}
                                         ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>30대</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"
                                     ]}
                                     seriesData={[
                                         {'name': '경영·사무', 'value': 804}, {'name': 'IT·인터넷', 'value': 520}, {'name': '전문직', 'value': 467}, {'name': '생산·제조', 'value': 308}, {'name': '영업·고객상담', 'value': 208}, {'name': '건설', 'value': 196}, {'name': '유통·무역', 'value': 188}, {'name': '의료', 'value': 175}, {'name': '디자인', 'value': 165}, {'name': '서비스', 'value': 165}, {'name': '교육', 'value': 139}, {'name': '특수계층·공공', 'value': 81}, {'name': '미디어', 'value': 57}
                                     ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>40대</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"
                                     ]}
                                     seriesData={[
                                         {'name': 'IT·인터넷', 'value': 38}, {'name': '경영·사무', 'value': 20}, {'name': '전문직', 'value': 15}, {'name': '교육', 'value': 11}, {'name': '생산·제조', 'value': 10}, {'name': '디자인', 'value': 9}, {'name': '서비스', 'value': 6}, {'name': '의료', 'value': 6}, {'name': '건설', 'value': 5}, {'name': '영업·고객상담', 'value': 4}, {'name': '유통·무역', 'value': 3}, {'name': '특수계층·공공', 'value': 3}
                                     ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>50대</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"]}
                                     seriesData={[
                                         {"name": 'IT·인터넷', "value": 61},
                                         {"name": '건설', "value": 7},
                                         {"name": '경영·사무', "value": 18},
                                         {"name": '교육', "value": 2},
                                         {"name": '디자인', "value": 4},
                                         {"name": '미디어', "value": 2},
                                         {"name": '생산·제조', "value": 8},
                                         {"name": '서비스', "value": 8},
                                         {"name": '영업·고객상담', "value": 11},
                                         {"name": '유통·무역', "value": 9},
                                         {"name": '의료', "value": 6},
                                         {"name": '전문직', "value": 5},
                                         {"name": '특수계층·공공', "value": 4}
                                     ]}
                                />
                            </div>
                        </div>
                    }
                    {pieTab === "B" &&
                        <div className={"add-section-inner-right"}>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>고양</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"]}
                                     seriesData={[
                                         {'name': '경영·사무', 'value': 121}, {'name': 'IT·인터넷', 'value': 83}, {'name': '전문직', 'value': 68}, {'name': '디자인', 'value': 52}, {'name': '생산·제조', 'value': 45}, {'name': '영업·고객상담', 'value': 45}, {'name': '서비스', 'value': 35}, {'name': '의료', 'value': 35}, {'name': '유통·무역', 'value': 27}, {'name': '건설', 'value': 20}, {'name': '교육', 'value': 20}, {'name': '미디어', 'value': 17}, {'name': '특수계층·공공', 'value': 14}
                                     ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>성남</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"]}
                                     seriesData={[
                                         {'name': 'IT·인터넷', 'value': 129}, {'name': '경영·사무', 'value': 123}, {'name': '전문직', 'value': 74}, {'name': '영업·고객상담', 'value': 36}, {'name': '서비스', 'value': 33}, {'name': '디자인', 'value': 32}, {'name': '건설', 'value': 31}, {'name': '생산·제조', 'value': 25}, {'name': '의료', 'value': 19}, {'name': '교육', 'value': 18}, {'name': '유통·무역', 'value': 16}, {'name': '미디어', 'value': 15}, {'name': '특수계층·공공', 'value': 8}
                                     ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>수원</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"]}
                                     seriesData={[
                                         {'name': '경영·사무', 'value': 236}, {'name': '전문직', 'value': 137}, {'name': 'IT·인터넷', 'value': 126}, {'name': '생산·제조', 'value': 93}, {'name': '영업·고객상담', 'value': 69}, {'name': '서비스', 'value': 60}, {'name': '디자인', 'value': 58}, {'name': '의료', 'value': 53}, {'name': '건설', 'value': 51}, {'name': '교육', 'value': 37}, {'name': '미디어', 'value': 35}, {'name': '유통·무역', 'value': 34}, {'name': '특수계층·공공', 'value': 20}
                                     ]}
                                />
                            </div>
                            <div style={{width: "590px", display: "inline-block"}}>
                                <span>안산</span>
                                <Pie height={200} showLegend={true} type={"B"} theme={"light"}
                                     selected={{
                                         "경영·사무": true,
                                         "영업·고객상담": true,
                                         "생산·제조": true,
                                         "IT·인터넷": true,
                                         "전문직": true,
                                         "교육": true,
                                         "미디어": true,
                                         "특수계층·공공": true,
                                         "건설": true,
                                         "유통·무역": true,
                                         "서비스": true,
                                         "디자인": true,
                                         "의료": true
                                     }}
                                     legendData={[
                                         "경영·사무",
                                         "영업·고객상담",
                                         "생산·제조",
                                         "IT·인터넷",
                                         "전문직",
                                         "교육",
                                         "미디어",
                                         "특수계층·공공",
                                         "건설",
                                         "유통·무역",
                                         "서비스",
                                         "디자인",
                                         "의료"]}
                                     seriesData={[
                                         {'name': '경영·사무', 'value': 134}, {'name': '전문직', 'value': 80}, {'name': '생산·제조', 'value': 54}, {'name': 'IT·인터넷', 'value': 40}, {'name': '서비스', 'value': 39}, {'name': '영업·고객상담', 'value': 32}, {'name': '의료', 'value': 28}, {'name': '유통·무역', 'value': 23}, {'name': '디자인', 'value': 22}, {'name': '교육', 'value': 20}, {'name': '건설', 'value': 18}, {'name': '특수계층·공공', 'value': 15}, {'name': '미디어', 'value': 4}
                                     ]}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={"add-section"}>
                <div className={"add-section-inner"}>
                    <div className={"add-section-inner"}>
                        {/* 일반발행 카드별 주간 결제 금액 정보 (무료) : 9b6a1038-b8a9-404e-9008-4cc1aa5a16b2 */}
                        <h2 className={"add-section-title"} /*onClick={e => props.history.push('dataset/9b6a1038-b8a9-404e-9008-4cc1aa5a16b2')}*/>2019.04 ~ 09 지역단위 지역화폐 소비금액 총계<span style={{fontSize: "0.3em"}}>(단위: 만원)</span></h2>
                    </div>
                    <div className={"add-section-inner-right"}>
                        <Bar theme={"light"}
                             type={"B"}
                             data={[
                                 {
                                     name: '사용금액',
                                     data: [29051, 806666, 103615, 178188,  88342,  79135, 661426, 272721,
                                         30921, 981940, 509446, 420549, 122592, 240617, 396829,  88932,
                                         74682, 131021, 305876, 211883,  78650, 141709, 475742, 733830]
                                 }
                             ]}
                             xAxisData={['가평', '고양', '과천', '광명', '광주', '구리', '군포', '남양주', '동두천', '부천', '수원',
                                 '안산', '안성', '양주', '양평', '여주', '연천', '오산', '용인', '의정부', '이천', '파주', '하남',
                                 '화성']}
                             barColor={['#2E8Fd1']}
                             barWidth={"150%"}
                             xAxisRotate={45}
                             xAxisMargin={15}
                        />
                    </div>
                </div>
            </div>
            {/* 모바일 데이터 목록 start */}
            <div className="main-data-list-mo">
                <div className={"tab-group"}>
                    <ul>
                        <li className={tab === "A" && "selected"} onClick={() => setTab("A")}>인기조회</li>
                        <li className={tab === "B" && "selected"} onClick={() => setTab("B")}>인기다운로드</li>
                        <li className={tab === "C" && "selected"} onClick={() => setTab("C")}>최신데이터</li>
                    </ul>
                    <div className="tab-panels">
                        <div id="tab1" className="tab-panel">
                            {tab === "A" && <ul className={"data-list"}>
                                {popularDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                            {tab === "B" && <ul className={"data-list"}>
                                {popularDownloadDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                            {tab === "C" && <ul className={"data-list"}>
                                {latestData.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.id}>{item.title}</Link>
                                </li>)}
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>
            {/* 모바일 데이터 목록 end */}
            <div className="main-data-list">
                <div className={"main-data-list-inner"}>
                    <dl className={"data-list-block"}>
                        <dt>인기 조회 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {popularDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>
                        </dd>
                    </dl>
                    <dl className={"data-list-block"}>
                        <dt>인기 다운로드 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {popularDownloadDataset.map((item, idx) =><li key={JSON.stringify(item)}>
                                    <span>{idx + 1}</span><Link to={"/dataset/"+item.data_id}>{item.title}</Link>
                                </li>)}
                            </ul>
                        </dd>
                    </dl>
                    <dl className={"data-list-block"}>
                        <dt>최신 데이터</dt>
                        <dd>
                            <ul className={"data-list"}>
                                {latestData.map((item, idx) =><li key={JSON.stringify(item)}>
                                        <span>{idx + 1}</span><Link to={"/dataset/"+item.id}>{item.title}</Link>
                                    </li>)}
                            </ul>
                        </dd>
                    </dl>
                </div>
                {/* <ul>
                    <li>
                        <Link to="/dataset">데이터</Link>
                    </li>
                </ul> */}
            </div>
            <div className={"wrap-monthly-data"}>
                <div className={"wrap-monthly-data-inner"}>
                    <div className={"monthly-data-text"}>
                        <h2>이달의 추천 데이터</h2>
                        <p>매달 업데이트 되는 추천 데이터를 확인해보세요!</p>
                    </div>
                    <div className={"gallery-wrap"}>
                        <ul className="gallery-block">
                            {recommendData.map((row, idx) => <li key={JSON.stringify(row)} onClick={() => props.history.push('/dataset/'+row.package_id)}>
                                <DatasetCard key={idx}
                                             title={row.title}
                                             description={row.description}
                                             image={row.image}
                                />
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
