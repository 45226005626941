import {get, post, getNew, postNew} from '../util/HttpRequest';

const ICAN_API_URL = process.env.REACT_APP_ICAN_API_URL;

/**
 * 데이터 생성
 * /create/data
*/
export const createData = (params = {}) => {
    let target = ICAN_API_URL + "/create/package";
    return post(target, params);
};

/**
 * 데이터 조회
 * /read/data
 */
export const readData = (params = {}) => {
    let target = ICAN_API_URL + "/read/package";
    return getNew(target, params);
};

/**
 * 데이터 수정
 * /update/data
 */
export const updateData = (params = {}) => {
    let target = ICAN_API_URL + "/update/package";
    return post(target, params);
};

/**
 * 데이터 삭제
 * /delete/data
 */
export const deleteData = (params = {}) => {
    let target = ICAN_API_URL + "/delete/package";
    return post(target, params);
};

/**
 * 데이터 목록 조회
 * /read/list_data
 */
export const readListData = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_data";
    return get(target, params);
};

/**
 * 데이터 검색
 * /read/search_data
 */
export const readSearchData = (params = {}) => {
    let target = ICAN_API_URL + "/read/search_data";
    return getNew(target, params);
};

/**
 * 인기 검색어
 * /read/search_popular_keyword
 */
export const readSearchPopularKeyword = (params = {}) => {
    let target = ICAN_API_URL + "/read/search_popular_keyword";
    return getNew(target, params);
};

/**
 * 인기 데이터셋
 * /read/search_popular_data
 */
export const readSearchPopularData = (params = {}) => {
    let target = ICAN_API_URL + "/read/search_popular_data";
    return getNew(target, params);
};

/**
 * 최신 데이터셋
 * /read/search_popular_data
 */
export const readLatestData = (params = {}) => {
    let target = ICAN_API_URL + "/read/list_latest_data";
    return getNew(target, params);
};

/**
 * 데이터 시각화
 * /read/graph
 */
export const readGraph = (params = {}) => {
    let target = ICAN_API_URL + "/read/graph";
    return get(target, params);
};

/**
 * 시각화 다운로드
 * /read/download_graph
*/
export const readDownloadGraph = (params = {}) => {
    let target = ICAN_API_URL + "/read/download_graph";
    return get(target, params);
};

/**
 * 메타데이터 관리 > 메타데이터 조회
 * /read/meta
 */
export const readMeta = (params = {}) => {
    let target = ICAN_API_URL + "/read/meta";
    return getNew(target, params);
};

/**
 * 리소스 다운로드
 * /read/download_data
 */
export const downloadData = (params = {}) => {
    let target = ICAN_API_URL + "/read/download_data";
    return postNew(target, params);
};

/**
 * 리소스 미리보기 다운로드
 * /read/download_sample_data
 */
export const downloadPreviewData = (params = {}) => {
    let target = ICAN_API_URL + "/read/download_sample_data";
    return postNew(target, params);
};

/**
 * 리소스 미리보기
 * /read/resource_view
 */
export const previewData = (params = {}) => {
    let target = ICAN_API_URL + "/read/resource_view";
    return getNew(target, params);
};

/**
 * 메타데이터 관리 > 연관데이터 불러오기
 * /read/relationship
 */
export const readRelationship = (params = {}) => {
    let target = ICAN_API_URL + "/read/relationship";
    return getNew(target, params);
};

/**
 * 메인 > 데이터 갯수
 * /read/data_count
 */
export const readDataCount = (params = {}) => {
    let target = ICAN_API_URL + "/read/data_count";
    return getNew(target, params);
};

/**
 * 활용 > 활용 갤러리
 * /read/data_count
 */
export const readUseList = (params = {}) => {
    let target = ICAN_API_URL + "/read/use_case_list";
    return getNew(target, params);
};

/**
 * 활용 > 활용 갤러리 상세
 * /read/data_count
 */
export const readUseDetail = (params = {}) => {
    let target = ICAN_API_URL + "/read/use_case_content";
    return getNew(target, params);
};

/**
 * 활용 > 오프라인 데이터 분석 신청
 * /create/proposal_analysis
 */
export const proposalAnalysis = (params = {}) => {
    let target = ICAN_API_URL + "/create/proposal_analysis";
    return postNew(target, params);
};

export const readDatasetAllTitle = (params = {}) => {
  let target = ICAN_API_URL + "/read/all_dataset_title";
  return getNew(target, params);
};