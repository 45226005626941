import React from 'react';
import './style.css';

const LoadingCheckPage = () => {

    return (
        <>
            <div className="wrap-check-browser">
                <div className="message-box">
                    <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h2 className="title">잠시만 기다려 주세요</h2>
                    <p className="message-text">
                        로그인 정보를 확인하고 있습니다. 이 과정은 자동으로 진행되며, 약 3초 정도 소요됩니다.
                    </p>
                    <div className={"logo"}>
                        <strong>경기지역</strong>
                        <strong>경제포털</strong>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LoadingCheckPage;
