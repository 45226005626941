import {
    SET_USER_INFO,
    DEL_USER_INFO,
    SET_USER_IS_LOGIN,
    USER_INIT,
    LOGOUT,
    SET_CATEGORY_CODE_DEMAND,
    SET_CATEGORY_CODE_SORTATION,
    SET_CATEGORY_CODE_PURPOSE,
    SET_SURVEY_LINK
} from "../actions/UserAction";

const initialState = {
    isLogin: false,
    info: JSON.parse(JSON.stringify(USER_INIT)),
    categoryDemand: {},
    categoryPurpose: {},
    surveyLink: {},
};

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER_INFO:
            return Object.assign({}, state, {
                info: {
                    ...state.info,
                    ...action.userInfo
                }
            });
        case DEL_USER_INFO:
            return Object.assign({}, state, {
                info: JSON.parse(JSON.stringify(USER_INIT))
            });
        case SET_USER_IS_LOGIN:
            return Object.assign({}, state, {
                isLogin: action.isLogin
            });
        case LOGOUT:
            return Object.assign({}, state, {
                isLogin: false,
                info: JSON.parse(JSON.stringify(USER_INIT))
            });
        case SET_CATEGORY_CODE_DEMAND:
            return Object.assign({}, state, {
                categoryDemand: action.category
            });
        case SET_CATEGORY_CODE_PURPOSE:
            return Object.assign({}, state, {
                categoryPurpose: action.category
            });
        case SET_CATEGORY_CODE_SORTATION:
            return Object.assign({}, state, {
                categorySortation: action.category
            });
        case SET_SURVEY_LINK:
            return Object.assign({}, state, {
                surveyLink: action.survey
            });
        default:
            return state;
    }
};

export default UserReducer;
