import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useSelector} from "react-redux";
import * as DataApi from "../../api/Data";
import * as OrderApi from "../../api/Order"
import * as FavoriteDataApi from "../../api/FavoriteData"
import {Link} from "react-router-dom";
import DatasetMetaView from "../../components/DatasetMetaView";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import MetaInfoView from "../../components/MetaInfoView";
import DatasetResource from "../../components/DatasetResource";
import {TabItem} from "../../components/Tab";
import {yyyymmdd} from "../../util/Date";
import Indicator from "../../components/Indicator";
import {addComma} from "../../util/Number";
import {delCookie} from "../../util/Cookie";
import {terms} from "../../util/Terms";
import CheckBox from "../../components/CheckBox";
import ErrorPage404 from "../common/ErrorPage404";
import SelectBox from "../../components/SelectBox";

let paramsId = null;
const Item = ({match, ...props}) => {
    let [dataInfo, setDataInfo] = useState(null);
    let [show404, setShow404] = useState(false);

    useEffect(() => {
        if(paramsId !== match.params.id || paramsId === match.params.id){
            let params = {
                id: match.params.id
            };
            DataApi.readData(params).then(res => {
                // console.log(res);
                setDataInfo(res.result);
            }).catch(res => {
                setShow404(true);
            });

            paramsId = match.params.id;
        }
    }, []);

    return (
        <>
            {dataInfo !== null ? <ItemInfo key={dataInfo.id} dataInfo={dataInfo} {...props}/> : <div><Indicator /></div>}
            {show404 && <ErrorPage404/>}
        </>
    )

};

export default Item;

const ItemInfo = ({dataInfo, ...props}) => {

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    let [relationship, setRelationship] = useState([]);
    let [linkedFrom, setLinkedFrom] = useState([]);
    let [targetId, setTargetId] = useState(dataInfo.id);

    let [purpose, setPurpose] = useState('');
    let [plan, setPlan] = useState('');
    let [isCheckPurpose, setCheckPurpose] = useState(false);
    let [isCheckPlan, setCheckPlan] = useState(false);
    let [isShowOrder, setIsShowOrder] = useState(false);

    let [spinner1, setSpinner1] = useState(false);
    let [spinner2, setSpinner2] = useState(false);

    let [isSubmit, setIsSubmit] = useState(false);

    const downloadFrame = useRef(null);
    const paymentFrame = useRef(null);

    const [policyAgree, setPolicyAgree] = useState(true);
    const [readPolicy, setReadPolicy] = useState(false);

    const [selectCategoryDemand, setSelectCategoryDemand] = useState(false);
    const categoryDemand = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryDemand)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    const [selectCategoryPurpose, setSelectCategoryPurpose] = useState(false);
    const categoryPurpose = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryPurpose)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    useEffect(() => {
        let params = {
            id: targetId
        };
        DataApi.readRelationship(params).then(res => {
            let relation_data = res.result.relation_data.map(item => item.result);
            let linked_from = res.result.linked_from.map(item => item.result);

            setLinkedFrom(linked_from);
            setRelationship(relation_data);
        });
    }, [targetId]);

    const addFavoriteData = (user_id, data_info) => {
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
        //     confirmButtonText:"로그인",
        //     cancelButtonText:"확인",
        //     showCancelButton: true
        // }).then((result) => {
        //     if(result.value) {
        //         delCookie("token");
        //         window.location.href = "/#/login";
        //     }
        // });
        else{
            let params = {
                user_id_key: user_id,
                package_id: data_info.id,
                package_name: data_info.title
            };

            FavoriteDataApi.addFavoriteData(params)
                .then(res => {
                    swal('관심데이터 등록이 성공하였습니다.')
                })
                .catch(res => {
                    let rst = JSON.parse(res.response);
                    if(rst.message === "session_expire"){

                    }else swal('관심데이터가 이미 등록이 되어있습니다.');
                })
        }
    };


    let Catalog_Catalog = [];
    let DataService_DataService = [];
    let DataSet_DataSet = [];
    let DataSet_Distribution = [];

    let dataExtras = dataInfo.extras;

    let metaInfoWithRedux = useSelector(state => state.data.metaInfo);

    let metaInfo = JSON.parse(JSON.stringify(metaInfoWithRedux));


    dataExtras.map(meta => {
        // dataInfo.extras.map(meta => {
        let keyArr = meta.key.split("_");
        let target;
        if(keyArr[0]+"_"+keyArr[1] === "Catalog_Catalog") target = Catalog_Catalog;
        else if(keyArr[0]+"_"+keyArr[1] === "DataService_DataService") target = DataService_DataService;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_DataSet") target = DataSet_DataSet;
        else if(keyArr[0]+"_"+keyArr[1] === "DataSet_Distribution") target = DataSet_Distribution;

        let keyText = meta.key.replace(keyArr[0]+"_"+keyArr[1]+"_", "");

        if(target !== undefined)
            target.push({
                key: keyText,
                display: meta.display,
                value: meta.value
            });
    });


    Object.keys(metaInfo).map((key, index) => {

        let target;
        if(key === "Catalog_Catalog") target = Catalog_Catalog;
        else if(key === "DataService_DataService") target = DataService_DataService;
        else if(key === "DataSet_DataSet") target = DataSet_DataSet;
        else if(key === "DataSet_Distribution") target = DataSet_Distribution;

        if(target !== undefined)
            metaInfo[key].map((meta, idx) => {
                target.some(t => {
                    if(t.key === meta.key)
                        metaInfo[key][idx] = {
                            ...metaInfo[key][idx],
                            value: t.value
                        };

                    return t.key === meta.key;
                });

            });
    });

    metaInfo.view = [];

    // let metas = ["creator_name", "accrualPeriodicity", "issued", "modified", "license", "language", "version", "ds_price", "keyword"];
    let metas = ["creator_name", "accrualPeriodicity", "issued", "modified", "license", "language", "version", "ds_price", "keyword", "contactPoint_phone"];

    metas.map(key => {
        let il = JSON.parse(JSON.stringify(metaInfo.DataSet_DataSet));
        il.some(row => {
            if(key === row.key){
                if(row.value === undefined)
                    row.value = "";

                // 임시 => priceInfo value를 ds_price로
                let check_col = metaInfo.DataService_DataService.filter((v) => (v.key === 'priceInfo'));
                let price_tmp = check_col[0];
                if(row.key === "ds_price"){
                    if (Number.isInteger(Number(price_tmp.value)) && price_tmp.value < 0) {
                        // row.value = addComma(row.value) + "원";
                        price_tmp.value = "가격문의";
                    }
                    else if (!price_tmp.value || Number(price_tmp.value) === 0) {
                        price_tmp.value = "무료";
                    }
                    row.value = price_tmp.value;
                }

                if(row.key === "language")
                    row.value = "한국어"; // 2019-12-17 JSJ - 한국어 고정

                if(row.value === "")
                    row.value = "-";

                metaInfo.view.push(row);
            }
            return key === row.key;
        });
    });
    // console.log(metaInfo)

    const fileDownload = (resourceId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner2(true);
        let params = {
            resource_id: resourceId,
            user_id: userInfo.id_key,
            package_name: dataInfo.title
        };
        DataApi.downloadData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner2(false);
        }).catch(res => {
            setSpinner2(false);
        });
        // DataApi.downloadPreviewData(params).then(res => {
        //     downloadFrame.current.src = res.result;
        //     setSpinner2(false);
        // }).catch(res => {
        //     setSpinner2(false);
        // });
    };
    const sampleFileDownload = (resourceId) => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");

        setSpinner1(true);
        let params = {
            resource_id: resourceId,
            user_id: userInfo.id_key,
            package_name: dataInfo.title
        };
        DataApi.downloadPreviewData(params).then(res => {
            downloadFrame.current.src = res.result;
            setSpinner1(false);
        }).catch(res => {
            setSpinner1(false);
        });
    };

    const handleOrderRequest = () => {
        // swal("경기지역경제포털이 곧 정식 오픈됩니다.\n조금만 기다려 주세요");
        if(!isLogin) {
            swal({
                text: "로그인 후 이용 가능한 서비스입니다.",
                buttons: [
                    "확인",
                    "로그인"
                ]
            }).then(function (isConfirm) {
                if(isConfirm) {
                    delCookie("token");
                    window.location.href = "/#/login";
                } else {
                    swal.close();
                }
            })
        }
        // swal2.fire({text:"로그인 후 이용 가능한 서비스입니다.",
        //     confirmButtonText:"로그인",
        //     cancelButtonText:"확인",
        //     showCancelButton: true
        // }).then((result) => {
        //     if(result.value) {
        //         delCookie("token");
        //         window.location.href = "/#/login";
        //     }
        // });

        else {
            setIsShowOrder(true);
        }
    };

    const checkEmptyValue = (target, setValue, value) => {
        if (value.length !== 0) {
            target(true);
            setValue(value);
        }
        else {
            target(false);
        }
    };

    const isPassValidate = () => {
        let validateArr = [isCheckPurpose, isCheckPlan, policyAgree];

        return validateArr.indexOf(false) < 0;
    };

    const isPayment = (dataInfo) => {

        if(selectCategoryDemand === false){
            swal("수요기업을 선택해주세요.");

            return
        }

        if(selectCategoryPurpose === false){
            swal("활용목적을 선택해주세요.");

            return
        }

        console.log(selectCategoryDemand)
        console.log(selectCategoryPurpose)

        if(true){
            let price = 0;
            dataInfo.extras.map(row => {
                //임시 수정
                if(row.key === "DataService_DataService_priceInfo"){
                    price = Number(row.value);
                }
            });
            if(price < 0){
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "Inquiry",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('안녕하세요 경기지역경제포털 입니다.\n' +
                                     '해당 데이터는 제공 기관 담당자에게 문의해주시기 바랍니다. \n' +
                                     '감사합니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    });
                // if(price > 1000){
                //
                // var params = {
                //     "itemname": dataInfo.title,
                //     "itemid": dataInfo.id,
                //     "username": userInfo.id,
                //     "useremail": userInfo.email,
                //     "userid": userInfo.id_key,
                //     "purpose": purpose,
                //     "plan": plan
                // };
                //
                // var form = document.createElement("form");
                // form.method = "POST";
                // form.target = "paymentFrame";
                // form.action = process.env.REACT_APP_PAYMENT_API_URL;
                //
                // for(let key in params){
                //     let hiddenField = document.createElement("input");
                //     hiddenField.setAttribute("type", "hidden");
                //     hiddenField.setAttribute("name", key);
                //     hiddenField.setAttribute("value", params[key]);
                //     form.appendChild(hiddenField);
                // }
                //
                // document.body.appendChild(form);
                // form.submit();
                // form.parentNode.removeChild(form);
                //
                // setIsSubmit(true);
                //
                // let receiveMessage = (event) => {
                //     let response = event.data;
                //
                //     if(response.type === "cancel"){
                //         swal("취소되었습니다.");
                //         setIsSubmit(false);
                //     }else if(response.type === "error"){
                //         swal(response.returnMsg + "\n\n문제가 계속될 경우 고객센터로 문의 바랍니다.");
                //         setIsSubmit(false);
                //     }else if(response.type === "success"){
                //         swal('이용신청이 완료되었습니다.');
                //         setIsShowOrder(false);
                //         setIsSubmit(false);
                //     }
                // };
                //
                // window.addEventListener("message", receiveMessage, false);
            }else{
                let params = {
                    user_id_key: userInfo.id_key,
                    package_id: dataInfo.id,
                    quantity: 1,
                    price: 0,
                    payment: "FREE",
                    user_name: userInfo.id,
                    package_name: dataInfo.title,
                    purpose: selectCategoryPurpose,
                    plan: selectCategoryDemand
                };
                OrderApi.addOrder(params)
                    .then(res => {
                        swal('이용신청이 완료되었습니다.');
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    })
                    .catch(res => {
                        setIsShowOrder(false);
                        setIsSubmit(false);
                    });
            }
        }
    };

    let paymentFrameStyle = {};
    let paymentFrameClass = "";
    if(isSubmit){
        paymentFrameStyle = {};
        paymentFrameClass = " ds-go";
    }else{
        paymentFrameStyle = {
            position: "fixed",
            left: "-99999px",
            width: "1px",
            height: "1px"
        };
        paymentFrameClass = "";
    }

    //임시
    const isReadPolicy = () => {
        setReadPolicy(true);
        terms("orderPolicy");
    };

    const isCancelPopup = () => {
        setIsShowOrder(false)
        setReadPolicy(false)
    };

    return (
        <>
            <div className={"ds_payment-wrap" + paymentFrameClass}>
                <iframe ref={paymentFrame} name={"paymentFrame"} style={paymentFrameStyle}/>
            </div>
            <iframe ref={downloadFrame} width={1} height={1} style={{
                position: "fixed",
                left: "-99999px"
            }}/>
            <div className={"wrap-dataset-view"}>
                <div className="title-view">
                    <h1>{dataInfo.title}</h1>
                    <p dangerouslySetInnerHTML={{__html:dataInfo.notes.replace(/\r\n|\r|\n/g, "<br />")}} />
                </div>
                <div className={"dataset-info"}>
                    <div className={"dataset-meta"}>
                        <MetaInfoView initialMetaInfo={metaInfo.view} key={JSON.stringify(metaInfo.view)}/>
                    </div>
                    <div className={"dataset-data"}>
                        {dataInfo.resources.map(resource => <DatasetResource key={resource.id}
                                                                             resource={resource}
                                                                             fileDownload={fileDownload}
                                                                             sampleFileDownload={sampleFileDownload}
                                                                             sampleDownloadLoading={spinner1}
                                                                             downloadLoading={spinner2}
                        />)}
                    </div>
                    <div className={"btn-group"}>
                        {/*추가*/}
                        {/*<Link to={"/order/" + dataInfo.id}>*/}
                        {/*    <button>*/}
                        {/*        주문*/}
                        {/*    </button>*/}
                        {/*</Link>*/}
                        <button onClick={() => handleOrderRequest()}>
                            이용신청하기
                        </button>
                        <button onClick={() => addFavoriteData(userInfo.id_key, dataInfo)}> 찜하기</button>
                    </div>
                    <div className="relation-data">
                        <h3>연관 데이터</h3>
                        <ul className={""}>
                            {relationship.map(row => {
                                return (<li key={JSON.stringify(row)}>
                                    <Link to={"/dataset/" + row.id}>
                                        <span>{row.title}</span>
                                    </Link>
                                    <span> 은(는) </span>
                                    <Link to={"/dataset/" + dataInfo.id}>
                                        <span>{dataInfo.title}</span>
                                    </Link>
                                    <span> 와(과) </span>
                                    <span>연관이 있습니다.</span>
                                </li>)
                            })}

                            {linkedFrom.map(row => {
                                return (<li key={JSON.stringify(row)}>
                                    <Link to={"/dataset/" + dataInfo.id}>
                                        <span>{dataInfo.title}</span>
                                    </Link>
                                    <span> 은(는) </span>
                                    <Link to={"/dataset/" + row.id}>
                                        <span>{row.title}</span>
                                    </Link>
                                    <span> 와(과) </span>
                                    <span>연관이 있습니다.</span>
                                </li>)
                            })}
                        </ul>


                    </div>
                </div>
            </div>

            {isShowOrder && <div className="terms">
                <div className={"wrap-order-pop"}>
                    <div className={"wrap-form"}>
                        <h1>수요기업(필수)</h1>
                        <div className={"orderSelectWrap first"}>
                            <SelectBox options={categoryDemand}
                                       onChange={option => {
                                           setSelectCategoryDemand(option.name);
                                       }}
                                       value={selectCategoryDemand}
                                       placeholder={"수요 기업 선택"}
                            />
                        </div>
                        {/*<textarea key={"A"} placeholder="구입목적을 입력해주세요." onChange={e => checkEmptyValue(setCheckPurpose, setPurpose, e.target.value)}/>*/}
                        <h1>활용목적(필수)</h1>
                        {/*<textarea key={"B"} placeholder="이용계획을 입력해주세요." onChange={e => checkEmptyValue(setCheckPlan, setPlan, e.target.value)}/>*/}
                        <div className={"orderSelectWrap second"}>
                            <SelectBox options={categoryPurpose}
                                       onChange={option => {
                                           setSelectCategoryPurpose(option.name);
                                       }}
                                       value={selectCategoryPurpose}
                                       placeholder={"활용 목적 선택"}
                            />
                        </div>
                    </div>
                    <div className="btn-group ac">
                        <button onClick={()=>isPayment(dataInfo)}> 이용신청하기 </button>
                        <button onClick={()=>isCancelPopup()}> 취소 </button>
                    </div>
                </div>
            </div>}

        </>
    )
};
