import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import AutoComplete from "../util/AutoComplete"
import {useSelector} from "react-redux";
import * as DataApi from "../api/Data";

const SearchForm = (props) => {

    const searchRef = useRef(null);
    const [isTitle, isSetTitle] = useState(false)
    const [titleList, setTitleList] =useState("");
    // let titleList = useSelector(state => state.data.dataTitle).title_list;

    useEffect(() => {
        DataApi.readDatasetAllTitle().then(res => {
            // titleList = res.result
            setTitleList(res.result.title_list);
            isSetTitle(true)
        });
    },[])

    if (searchRef !== null) {
        if (isTitle){
            new AutoComplete(document.querySelector('#search_input'), titleList)
        }
    }

    const [word, setWord] = useState("");
    return (
        <div className={"input"}>
            <input type={"text"} id="search_input" placeholder={"방대한 데이터를 쉽고 빠르게 찾아보세요!"}
                   onKeyDown={e => e.keyCode === 13 && props.history.push("/dataset?word=" + word)}
                   value={word} onChange={e => setWord(e.target.value)}
                   title={"데이터셋 검색"} ref={searchRef} autoComplete={"off"}
            />
            <div className={"btn-click"}>
                <Link to={"/dataset?word=" + word}></Link>
            </div>
        </div>
    );
};

export default SearchForm;
