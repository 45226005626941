import React, {useState, useEffect, useRef} from 'react';
import ImgAnalytics from "../../assets/images/img_data_analytics@2x.png";
import ImgCity from "../../assets/images/img_data_city@2x.png";
import SelectBox from "../../components/SelectBox";
import {useSelector} from "react-redux";
import {yyyymmdd} from "../../util/Date";
import swal from "sweetalert";
import { NaverMap, Marker } from 'react-naver-maps';
import * as DataApi from "../../api/Data";
import dataImg1 from "../../assets/images/photo_2020-03-13_09-41-16.jpg";
import dataImg2 from "../../assets/images/photo_2020-03-13_09-41-21.jpg";

function NaverMapAPI() {
    let agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("msie") !== -1) {
        if (document.getElementById("maps") !== null) {
            let maps_obj = window.naver.maps;
            var mapOptions = {
                center: new maps_obj.LatLng(37.402209, 127.110569),
                zoom: 18
            };
            let naver_maps = new maps_obj.Map('maps', mapOptions);
            var marker = new maps_obj.Marker({
                position: new maps_obj.LatLng(37.402209, 127.110569),
                map: naver_maps
            });
        }
        return (
            <div id="maps" style={{"width":"100%", "height":"100%"}}/>
        )
    }
    const navermaps = window.naver.maps;
    return (
        <NaverMap
            mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
            style={{
                width: '100%', // 네이버지도 가로 길이
                height: '100%' // 네이버지도 세로 길이
            }}
            defaultCenter={{ lat: 37.402209, lng: 127.110569 }} // 지도 초기 위치
            defaultZoom={18} // 지도 초기 확대 배율
        >
            <Marker
                key={1}
                position={new navermaps.LatLng(37.402209, 127.110569)}
                animation={0}
            />
        </NaverMap>
    );
}

const AnalysisService = (props) => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateYear, setSelectedDateYear] = useState(selectedDate.getFullYear());
    const [selectedDateMonth, setSelectedDateMonth] = useState(selectedDate.getMonth() + 1);
    const [selectedDateDay, setSelectedDateDay] = useState(selectedDate.getDate());

    const categoryPurpose = useSelector(state => {
        let rst = [];

        for (let [name, display] of Object.entries(state.user.categoryPurpose)) {
            rst.push({
                display: display,
                name: name
            })
        }

        return rst;
    });

    const [selectCategoryPurpose, setSelectCategoryPurpose] = useState(categoryPurpose[0].name);
    const [memo, setMemo] = useState("");



    useEffect(() => {
        let t = new Date(selectedDate);
        t.setFullYear(selectedDateYear);

        setSelectedDate(t);
        setSelectedDateMonth(false);
        setSelectedDateDay(false);
    }, [selectedDateYear]);

    useEffect(() => {
        let t = new Date(selectedDate);
        t.setMonth(selectedDateMonth);

        setSelectedDateDay(false);
        setSelectedDate(t);
    }, [selectedDateMonth]);

    useEffect(() => {
        let t = new Date(selectedDate);
        t.setDate(selectedDateDay);

        setSelectedDate(t);
    }, [selectedDateDay]);

    useEffect(() => {
        console.log(selectedDate);
    }, [selectedDate]);


    const getOptions = (target) => {
        let rst = [];
        let now = new Date();

        if(target === 'date'){
            let startDay = 1;
            if(now.getFullYear() === selectedDateYear && (now.getMonth() + 1) === selectedDateMonth)
                startDay = now.getDate();
            let lastDay = (new Date(selectedDateYear, selectedDateMonth, 0)).getDate();
            for(let a = startDay; a <= lastDay; a++){
                rst.push({
                    display: a + "일",
                    name: a
                })
            }
        }else if(target === 'month'){
            let startMonth = 1;
            if(now.getFullYear() === selectedDateYear)
                startMonth = now.getMonth() + 1;
            for(let a = startMonth; a <= 12; a++){
                rst.push({
                    display: a + "월",
                    name: a
                })
            }
        }else if(target === 'year'){
            let nowYear = now.getFullYear();
            rst.push({
                display: nowYear + "년",
                name: nowYear
            });
            rst.push({
                display: (nowYear + 1) + "년",
                name: (nowYear + 1)
            });
        }

        return rst;
    };

    const userInfo = useSelector(state => state.user.info);
    const isLogin = useSelector(state => state.user.isLogin);

    const apply = () => {

        if(isLogin === false){
            swal("로그인 이후 신청 가능합니다.");
            return
        }

        if(selectedDateMonth === false){
            swal("이용하실 날짜의 월을 선택해주세요.");
            return
        }

        if(selectedDateDay === false){
            swal("이용하실 날짜의 을 선택해주세요.");
            return
        }

        if(selectedDateYear === false){
            swal("이용하실 날짜의 년을 선택해주세요.");
            return
        }

        let params = {
            user_id : userInfo.id,
            user_name: userInfo.fullname,
            email: userInfo.email,
            phone: userInfo.phone,
            start_date: yyyymmdd(new Date(selectedDateYear, selectedDateMonth - 1, selectedDateDay)) + " 00:00:00",
            end_date: yyyymmdd(new Date(selectedDateYear, selectedDateMonth - 1, selectedDateDay)) + " 23:59:59",
            analysis_purpose: selectCategoryPurpose,
            user_memo: memo
        };

        DataApi.proposalAnalysis(params).then(res => {
            swal("신청이 완료되었습니다.\n신청내역은 마이페이지에서 확인 가능합니다.");
        }).catch(() => {
            swal("오류가 발생했습니다. \n잠시 후 다시 시도해주세요.");
        })
    };


    return (
        <>
            <div className={"AnalysisService-wrap"}>
                <div className={"AnalysisService-inner"}>
                    <h1>데이터 분석 센터</h1>
                    <p>데이터 분석 센터는, 방대하고 다양한 데이터를 통해 빅데이터의 성공적인 발전을 도모하기 위해 누구나 손쉽게 데이터를 분석할 수 있는 무료 공간입니다.</p>
                    <img src={ImgCity} alt={"ImgCity"} className={"ImgCity"}/>
                    <img src={ImgAnalytics} alt={"ImgAnalytics"} className={"ImgAnalytics"}/>
                </div>
            </div>
            <div className={"AnalysisService-wrap2"}>
                <div className={"AnalysisService-inner"}>
                    <h2>서비스 이용 신청</h2>

                    <div className={"wrap2-gap-wrap"} key={selectedDate.toDateString()}>
                        <label>이용 날짜</label>
                        <div className={"AnalysisService-select-wrap"}>
                            <SelectBox options={getOptions('year')}
                                       onChange={option => {
                                           setSelectedDateYear(option.name);
                                       }}
                                       value={selectedDateYear}
                                       placeholder={"년"}
                            />
                        </div>
                        <div className={"AnalysisService-select-wrap"}>
                            <SelectBox options={getOptions('month')}
                                       onChange={option => {
                                           setSelectedDateMonth(option.name);
                                       }}
                                       value={selectedDateMonth}
                                       placeholder={"월"}
                            />
                        </div>
                        <div className={"AnalysisService-select-wrap"}>
                            <SelectBox options={getOptions('date')}
                                       onChange={option => {
                                           setSelectedDateDay(option.name);
                                       }}
                                       value={selectedDateDay}
                                       placeholder={"일"}
                            />
                        </div>
                    </div>

                    <div className={"wrap2-gap-wrap"}>
                        <label>활용 목적</label>
                        <div className={"AnalysisService-select-wrap"}>
                            <SelectBox options={categoryPurpose}
                                       onChange={option => {
                                           setSelectCategoryPurpose(option.name);
                                       }}
                                       value={selectCategoryPurpose}
                                       placeholder={"활용 목적 선택"}
                            />
                        </div>
                    </div>

                    <div className={"wrap2-gap-wrap"}>
                        <label>메모</label>
                        <div className={"AnalysisService-note-wrap"}>
                            <textarea
                                placeholder="메모"
                                title="메모"
                                onChange={e => {
                                    if(e.target.value.length <= 500){
                                        setMemo(e.target.value);
                                    }
                                }}/>
                            <p>{memo.length} / 500</p>
                        </div>
                    </div>

                </div>

                <div className="btn-group ac wrap2-btn-wrap">
                    <button onClick={e => apply()}>신청</button>
                </div>
            </div>
            <div className={"AnalysisService-wrap3"}>
                <div className={"AnalysisService-notice-inner"}>
                    <h2>센터 내부</h2>
                    <img src={dataImg1} width={"50%"} alt={"센터 내부"}/>
                    <img src={dataImg2} width={"50%"} alt={"센터 내부"}/>
                </div>
            </div>
            <div className={"AnalysisService-wrap3"}>
                <div className={"AnalysisService-notice-inner"}>
                    <h2>주의 사항</h2>
                    <div>
                        <ul>
                            <li>- 별도 이용료 없이 무료로 이용하실 수 있습니다.</li>
                            <li>- 신청서가 제출되면 평균 2~3일 정도의 검토 과정을 거친 후 승인되며, 사정에 따라 지연될 수 있습니다.</li>
                            <li>- 신청한 이용 시간을 초과하여 이용하실 수 없으므로 이용 시간을 준수하여 주시기 바랍니다.</li>
                            <li>- 사정에 의해 부득이하게 연장이 필요한 경우 '참여소통 > 묻고 답하기'에서 문의하여 주시기 바랍니다.</li>
                            <li>- 센터에서 물과 음료를 제외한 음식물을 반입하거나 섭취하실 수 없습니다.</li>
                            <li>- 데이터 분석과 관련된 이용 목적 외 다른 목적으로 사용하실 수 없으며, 적발 시 법적 제재가 발생할 수 있습니다.</li>
                            <li>- 신청서 작성 시 주민등록번호, 성명, 연락처 및 기타 개인정보가 포함되지 않도록 주의하시기 바랍니다.</li>
                            <li>- 부득이한 사정으로 예고 없이 센터 이용이 불가해질 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"AnalysisService-wrap4"}>
                <div className={"AnalysisService-notice-inner"}>
                    <h2>위치</h2>
                    <div className={"AnalysisService-map"}>
                        <NaverMapAPI />
                    </div>
                    <div>
                        <ul>
                            <li>&nbsp;</li>
                            <li>주소 : 경기도 성남시 분당구 삼평동 678 삼환하이팩스 A동 603호</li>
                            <li>대중교통 : 지하철 이용 시 분당역 신분당선 4번 출구 도보 13분 거리, 버스 이용 시 9007번 H스퀘어역 하차 시 도보 2분 거리</li>
                            <li>&nbsp;</li>
                            <li>※ 주차장은 제공되지 않기 때문에 가까운 주차 서비스를 이용하시기 바랍니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AnalysisService;

