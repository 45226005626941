import React from 'react';
import ReactEcharts from "echarts-for-react";

const Bar = (props) => {

    let option = {
        tooltip: {},
        legend: {
            data: props.legend
        },
        radar: {
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#999',
                    borderRadius: 3,
                    padding: [3, 5]
                }
            },
            indicator: props.indicator
        },
        series: [{
            type: 'radar',
            data : props.data
        }]
    };


    return (
        <div>
            <ReactEcharts
                option={option}
                notMerge={true}
                theme={props.theme}
                opts={{
                    height: props.height
                }}
                style={{
                    height: props.height
                }}
            />
        </div>
    )
};


/**
 *
 * @props {
 *      data: *[]
 *      heme: string
 * }
 *
 */
Bar.defaultProps = {
    height: '400px',
    theme: 'default', // default, light, dark
    legend: ['Allocated Budget', 'Actual Spending'],
    indicator: [
        { name: 'sales', max: 6500},
        { name: 'Administration', max: 16000},
        { name: 'Information Techology', max: 30000},
        { name: 'Customer Support', max: 38000},
        { name: 'Development', max: 52000},
        { name: 'Marketing', max: 25000}
    ],
    data: [
        {
            value : [4300, 10000, 28000, 35000, 50000, 19000],
            name : 'Allocated Budget'
        },
        {
            value : [5000, 14000, 28000, 31000, 42000, 21000],
            name : 'Actual Spending'
        }
    ]
};

export default Bar;
