import React from 'react';

const DatasetCard = ({title, description, image}) => {

    return (
        <>
            <dl>
                <dt>
                    <img src={image} alt={title}/>
                </dt>
                <dd>
                    <strong>{title}</strong>
                    {/*폰트 1px 올림*/}
                    <p style={{"font-size": "15px"}}>{description}</p>
                </dd>
            </dl>
        </>
    )
};

export default DatasetCard;
