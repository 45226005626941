import React from 'react';
import './style.css';

const Card = (props) => {

    return (
        <div className={"ikan-dashboard-card-wrap"}>
            <div className={"ikan-dashboard-card-inner"}>
                {props.title && <div className={"ikan-dashboard-card-header"}>
                    <div className={"ikan-dashboard-card-title-wrap"}>
                        <p className={"ikan-dashboard-card-title"}>
                            {props.title}
                        </p>
                    </div>
                    <div className={"ikan-dashboard-card-option-wrap"}>
                        <button className={"btn-dot"}></button>
                    </div>
                </div>}
                <div className={"ikan-dashboard-card-contents"}>
                    {props.children}
                </div>
            </div>
        </div>
    )
};

Card.defaultProps = {
    title: null
};

export default Card;
