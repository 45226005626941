import React from 'react';
import Num from './Num';

const Page = ({paging, maxPage, page, padding, onPageMove}) => {

    let start = page - padding;
    let r = 0;

    if(start < 1){
        r = Math.abs(page - padding) + 1;
        start = 1;
    }

    let end = page + padding + r;

    if(end > maxPage){
        let el = end - maxPage;
        start = start - el;
        if(start < 1) start = 1;
        end = maxPage;
    }

    let Nums = [];
    for (let i = start; i <= end; i++) {
        Nums.push(<Num key={i} num={i} page={page} onPageMove={onPageMove}/>);
    }

    let beforePage = (page - 1 > 0) ? page - 1 : 1;
    let afterPage = (page + 1 > maxPage) ? maxPage : page + 1;

    if(paging) {
        return (
            <div className={"datatable-page"}>
                <button className={"btn btn-white"}
                        onClick={() => onPageMove(1)}
                        disabled={(page === 1)}
                >{"<<"}</button>
                <button className={"btn btn-white"}
                        onClick={() => onPageMove(beforePage)}
                        disabled={(page === beforePage)}
                >{"<"}</button>
                {Nums}
                <button className={"btn btn-white"}
                        onClick={() => onPageMove(afterPage)}
                        disabled={(page === afterPage)}
                >{">"}</button>
                <button className={"btn btn-white"}
                        onClick={() => onPageMove(maxPage)}
                        disabled={(page === maxPage)}
                >{">>"}</button>
            </div>
        )
    }
    else return null;
};

Page.defaultProps = {
    paging: false,
    page: 1,
    maxPage: 1,
    padding: 2
};

export default Page;
