export const index = `경기지역경제포털 개인정보 수집 및 이용

  경기지역경제 포털시스템 및 연계시스템은 개인정보를 중요하게 생각하며 "정보통신 이용촉진 및 
  정보보호등에 관한 법률", "개인정보보호법 제15조" 등 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 
  의거한 개인정보를 취급(처리)하고 있습니다.
 
 1. 개인정보의 수집방법 및 이용목적
  ○ 수집방법 : 포털시스템 기존사용자 및 회원가입을 통한 수집
  
  ○ 이용목적 : 포털시스템의 서비스 이용 및 회원관리 확인/수정/삭제 등 포털시스템 이용에 필요한 최소한의 정보를 수집합니다.
  
  ○ 시스템목록 : 경기지역경제 포털 시스템, 지역경제빅데이터플랫폼 혁신 서비스
    
 2. 개인정보의 수집항목
  ○ 유통 포털시스템에서는 기본적인 회원 서비스 제공을 위한 필수정보와, 정보주체 각각의 기호와 필요에 
   맞는 서비스를 제공하기 위한 선택정보로 구분하여 다음의 정보를 수집하고 있습니다.
   
  ○ 수집항목 : 아이디, 비밀번호, 이메일, 소속, 이름, 주소, 휴대폰번호
  
  ○ 경기지역경제 포털시스템을 이용할 경우 쿠키, 접속IP, 방문일시, 서비스 이용 기록 등의 정보는 시스템에 의해 자동적으로 수집·저장 됩니다. 

 3. 개인정보의 보유 및 이용기간
  ○ 경기지역경제 포털시스템에서는 정보주체의 회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 경기지역경제 포털시스템 서비스를 이용하기 위한 최소한의 개인정보를 보유 및 이용하게 됩니다.
  
  ○ 회원가입 등을 통해 개인정보의 수집·이용, 제공 등에 대해 동의하신 내용은 언제든지 철회 할 수 있습니다.
  
  ○ 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 보유/이용기간이 종료한 경우 등의 사유발생시 당해 개인정보를 지체없이 파기합니다.

 4. 동의 거부 권리 안내
  ○ 정보주체는 개인정보 수집·이용 동의를 거부 할 수 있습니다. 다만, 이 경우 경기지역경제 포털시스템을 사용하는데 제한이 있을 수 있습니다.
  
 ※ 수집된 개인정보는 경기지역경제 포털시스템을 이용하기 위한 정보 이외의 용도로 이용되지 않으며, 제 3자에게 제공하지 않습니다.
`;
