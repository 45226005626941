import React from 'react';

const TabItem = ({children, ...props}) => {

    return (
        <>
            {children}
        </>
    )
};

TabItem.defaultProps = {
    id: +new Date(),
    name: +new Date(),
};

export default TabItem;
