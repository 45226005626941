import React, {useState, useEffect} from 'react';
import ThumImg from "../../assets/images/use-thum.png";
import * as DataApi from "../../api/Data";
import ErrorPage404 from "../common/ErrorPage404";

const UseDetail = (props) => {

    let [data, setData] = useState(null);
    let [show404, setShow404] = useState(false);

    useEffect(() => {
        let params = {
            content_id: props.match.params.id
        };
        DataApi.readUseDetail(params).then(res => {
            // console.log(res.result);
            setData(res.result.content);
        }).catch(res => {
            setShow404(true);
        })
    }, []);

    if(show404)
        return <ErrorPage404/>;

    else
    return (
        <>
            {data !== null &&
            <div className={"use-wrap"}>
                <div className={"use-service"}>
                    <h3>활용서비스</h3>
                    <div className={"use-service-inner"}>
                        <img src={data.thumbnail}/>
                        <p>
                            <dl>
                                <dt>{data.title}</dt>
                                <dd>
                                    <ul>
                                        <li>{data.desc}</li>
                                        {/*<li className={"link"}>URL : <a href={data.link}*/}
                                        {/*                                target={"_blank"}>{data.link}</a></li>*/}
                                    </ul>
                                </dd>
                            </dl>
                        </p>
                    </div>
                </div>
                <div className={"use-way"}>
                    <h3>활용방법</h3>
                    <div className={"use-way-text"}>{data.body}</div>
                    {Array.isArray(data.images) &&
                    <div className={"use-way-view-box"}>
                        {data.images.map(img => <img src={img}/>)}
                    </div>
                    }
                </div>
                <div className={"btn-group"}>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <button onClick={() => props.history.push("/use")}>목록</button>
                    </div>
                </div>
            </div>
            }
        </>
    )
};

export default UseDetail;
