import React from 'react';
import {Link} from "react-router-dom";
import {yyyymmdd} from "../util/Date";
import {addComma, byte} from "../util/Number";

const DatasetList = ({list, totalCount}) => {
    return (
        <>
            <div className={"search-result"}>
                전체({addComma(totalCount)})
            </div>
            {list.length > 0 ? <ul className={"dataset-list"}>
                {list.map(item => <DatasetItem key={item.id} info={item}/>)}
            </ul> : <div className={"ds-2-no-result"} style={{textAlign: "center", padding: 0}}>검색 결과가 없습니다.</div>}
        </>
    );
};

const DatasetItem = ({info}) => {
    let displayDate = yyyymmdd(new Date(info.metadata_modified), ".");
    let extraDict = info.extras.reduce(function(map, obj) {
        map[obj.key] = obj.value;
        return map;
    }, {});
    return (
        <li key={info.id}>
            <dl>
                <Link to={"/dataset/"+info.id}>
                <dt>
                    {info.title}
                    <div className={"date"}>
                        {displayDate}
                    </div>
                </dt>
                <dd>
                    <div className={"explanation"} dangerouslySetInnerHTML={{__html:info.notes.replace(/\r\n|\r|\n/g, "<br />")}} />
                    <div className={"info"}>

                        <div className={"dataset-list-add-info-wrap"}>
                            <span className={"dataset-list-add-info-color"}>제공기관: {extraDict['DataSet_DataSet_creator_name']} </span>
                            <span className={"dataset-list-add-info-price"}>가격:{
                                extraDict['DataService_DataService_priceInfo'] === '' || Number(extraDict['DataService_DataService_priceInfo']) === 0
                                    ? " 무료" : " 유료"
                            }
                            </span>
                            {info.resources.map((resource) =>
                                <span className={"dataset-list-add-info-size"}>사이즈: {byte(resource.size, 0)}</span>
                            )}
                        </div>

                        <div className={"list-inner-btn-group"}>
                            {info.resources.map((resource, idx) => {
                                return (
                                   <>
                                       {
                                           idx > 0 ? null :
                                           resource.format === "CSV" || resource.format === "ZIP" ?
                                               <Link to={"/dataset/" + info.id + "?preview=true"}>
                                                   <span key={info.package_id} className={"Preview m_right10 m_left10"}>SHEET</span>
                                               </Link> :
                                               null
                                       }
                                       <span key={resource.format + info.id + idx} className={resource.format}>{resource.format}</span>
                                   </>
                                )
                            }
                        )}</div>
                    </div>
                </dd>
                </Link>
            </dl>
        </li>
    );
};

export default DatasetList;
