import React, {useEffect, useState} from 'react';
import * as IboardApi from "../../api/Board";
import SnsStTimeSince from "../../components/SnsStTimeSince";
import {getCookie} from "../../util/Cookie";

const Comment = ({textId, ...props}) => {

    let [comment, setComment] = useState("");
    let [commentList, setCommentList] = useState([]);

    useEffect(() => {
        getCommentList();
    }, [textId]);

    const getCommentList = () => {
        let params = {
            text_id: textId,
            page_size: 10,
            page: 0
        };
        IboardApi.readCommentList(params).then(res => {
            setCommentList(res.result.textlist);
        })
    };

    const addComment = () => {
        let params = {
            text_id: textId,
            text: comment,
            user_id: getCookie("token")
        };
        IboardApi.createComment(params).then(res => {
            getCommentList();
        })
    };

    const removeComment = (commentId) => {
        let params = {
            comment_id: commentId,
        };
        IboardApi.deleteComment(params).then(res => {
            getCommentList();
        })
    };

    return (
        <>
            {props.info.comment && <>
                <textarea value={comment} onChange={e => setComment(e.target.value)}/>
                < button onClick={() => addComment()}>등록</button>
                Comment {textId}
            </>
            }
            <ul className={"comment-list"}>
                {commentList.map(comment =>
                    <li key={JSON.stringify(comment)}>
                        {/*{props.isA && <span className={"comment-answer"}>└ 답변</span>}*/}
                        {comment.text}<br /><br /><SnsStTimeSince date={comment.date}/>
                        {props.info.comment &&
                        <button onClick={() => removeComment(comment.id)}>삭제</button>
                        }
                    </li>
                )}
            </ul>
        </>
    )
};

export default Comment;
