import React from 'react';
import "./style.css";

const CheckBox = ({onChange, label, id, checked}) => {

    return (
        <>
            <div className={"iui-check-box check-box"}>
                <input type={"checkbox"} id={id} onChange={e => onChange(e.target.checked)} checked={checked}/>
                <label htmlFor={id}>
                    <span>{label}</span>
                </label>
            </div>
        </>
    )
};

CheckBox.defaultProps = {
    onChange: () => {
    },
    label: "",
    id: +new Date(),
    checked: false
};

export default CheckBox;
