import React, {Fragment, useEffect, useState} from 'react';
import * as UserApi from "../../api/User";
import swal from "sweetalert"

const ResetPassword = ({match, ...props}) => {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSamePassword, setIsSamePassword] = useState(true);

    let user_id_key = match.params.user_id_key;
    let reset_key = match.params.reset_key;

    const changePassword = (target, value) => {
        if (target === "password") {
            setPassword(value);

            if (passwordConfirm === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);

        } else if (target === "passwordConfirm") {
            setPasswordConfirm(value);

            if (password === value)
                setIsSamePassword(true);
            else setIsSamePassword(false);
        }
    };

    const isChangePassword = () =>{
        let params = {
            user_id_key: user_id_key,
            reset_key: reset_key,
            password: password
        };
        UserApi.readForgotPassword(params).then(res => {
            swal('비밀번호 변경이 완료되었습니다.')
            props.history.push("/login");
        }).catch(res => {
            let json_res = JSON.parse(res.response);
            console.log(json_res)
            if (json_res.result === 101) {
                swal('비밀번호는 8자 이상이어야 합니다.')
            }
            else {
                swal('잘못된 경로로 접근하였습니다.')
            }
        });
    };

    return (
        <>
            <div className={"wrap-cahnge-password"}>
                <div className={"inner-cahnge-password"}>
                    <h1> 비밀번호 변경 </h1>
                    <ul className={"ikan-text-field-default"}>
                        <li>
                            <input type={"password"} placeholder={"PASSWORD"}
                            value={password} onChange={e => changePassword("password", e.target.value)}/>
                        </li>
                        <li>
                            <input type={"password"} placeholder={"PASSWORD CONFIRM"}
                            value={passwordConfirm} onChange={e => changePassword("passwordConfirm", e.target.value)}/>
                            <span className={"error-text"}>{!isSamePassword && "비밀번호가 일치하지 않습니다."}</span>
                        </li>
                    </ul>
                    <div className={"btn-group ac"}>
                        <button onClick={() => isChangePassword()}> 비밀번호수정 </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ResetPassword;
