import {userLogout} from "../../api/User";
import {delCookie} from "../../util/Cookie";

export const SET_USER_INFO = 'SET_USER_INFO';
export const DEL_USER_INFO = 'DEL_USER_INFO';
export const SET_USER_IS_LOGIN = 'SET_USER_IS_LOGIN';
export const SET_CATEGORY_CODE_DEMAND = 'SET_CATEGORY_CODE_DEMAND';
export const SET_CATEGORY_CODE_PURPOSE = 'SET_CATEGORY_CODE_PURPOSE';
export const SET_CATEGORY_CODE_SORTATION = 'SET_CATEGORY_CODE_SORTATION';
export const SET_SURVEY_LINK = 'SET_SURVEY_LINK';
export const LOGOUT = 'LOGOUT';

export const USER_INIT = {
    id_key: null,
    id: null,
    email: null,
    address: null,
    phone: null,
    fullname: null,
    apikey: null,
    user_type: null,
    number_created_packages: null,
    number_of_edits: null,
    created: null,
    token: null,
    isLogin: function(){
        return !(this.id || this.email );
    }
};

export function setUserInfo(userInfo) {
    return {
        type: SET_USER_INFO,
        userInfo: userInfo
    };
}

export function delUserInfo() {
    return {
        type: DEL_USER_INFO,
        userInfo: null
    };
}

export function setUserInfoIsLogin(bool) {
    return {
        type: SET_USER_IS_LOGIN,
        isLogin: bool
    };
}


export function logout() {
    userLogout();
    delCookie("token");
    let status = true;
    let res = {
        status: status,
        type: LOGOUT,
        isLogin: true,
        info: JSON.parse(JSON.stringify(USER_INIT))
    };
    return res;
}

export function setCategoryCodeDemand(category) {
    return {
        type: SET_CATEGORY_CODE_DEMAND,
        category: category
    };
}

export function setCategoryCodePurpose(category) {
    return {
        type: SET_CATEGORY_CODE_PURPOSE,
        category: category
    };
}


export function setCategoryCodeSortation(category) {
    return {
        type: SET_CATEGORY_CODE_SORTATION,
        category: category
    };
}

export function setSurveyLink(survey) {
    return {
        type: SET_SURVEY_LINK,
        survey: survey
    };
}
