import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import DsList from "../../components/DsList";
import Pagination from "../../components/Pagination";
import SubNav from "../../components/SubNav";
import FaqList from "./FaqList";
import {yyyymmddhhiiss} from "../../util/Date";
import Datatable from "../../components/Datatable";

const List = ({match, ...props}) => {
    const dispatch = useDispatch();

    let [boardInfo, setBoardInfo] = useState({});
    let [isInit, setIsInit] = useState(true);

    let [loading, setLoading] = useState(true);
    let [textList, setTextList] = useState([]);
    let [page, setPage] = useState(1);
    let [maxPage, setMaxPage] = useState(1);
    let [limit, setLimit] = useState(10);
    let [totCnt, setTotCnt] = useState(0);

    const isLogin = useSelector(state => state.user.isLogin);

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);
        }
    });

    let board = match.params.board;
    let info = boardInfo[board];

    useEffect(() => {
        setLoading(true);
        let params = {
            board_id: board,
            page_size: limit,
            page: 0,
            user_id: ""
        };
        IboardApi.readTextList(params).then(res => {
            let textList = res.result.textlist;
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setTextList(textList);
            setLoading(false);
            setPage(1);
        })
    }, [board]);

    useEffect(() => {
        setLoading(true);
        let params = {
            board_id: board,
            page_size: limit,
            page: page - 1,
            user_id: ""
        };
        IboardApi.readTextList(params).then(res => {
            let textList = res.result.textlist;
            setTotCnt(res.result.count);
            setMaxPage(Math.ceil(res.result.count / limit));
            setTextList(textList);
            setLoading(false);
        })
    }, [page]);


    if(info === undefined) return <ErrorPage404/>;

    if(isInit){
        setIsInit(false);
    }

    const descriptionRender = data => {
        return null;
    };

    const handleClickList = data => {
        props.history.push("/board/" + board + "/article/" + data.id);
    };

    let subNav = [
        {
            display: '공지사항',
            name: '/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794'
        },
        {
            display: '묻고 답하기',
            name: '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510'
        },
        {
            display: '자주하는 질문',
            name: '/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f'
        },
        {
            display: '데이터 문의',
            name: '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f'
        }
    ];

    if(board === "board_a2eb004d-73ca-4014-a9d2-c402675cf83f")
        return (
            <SubNav nav={subNav} history={props.history} key={board}>
                <FaqList list={textList}
                         totCnt={totCnt}
                         page={page}
                         limit={limit}
                />
                <Pagination maxPage={maxPage}
                            page={page}
                            padding={2}
                            onPageMove={page => setPage(page)}
                />
            </SubNav>
        );
    else
        return (
            <SubNav nav={subNav} history={props.history} key={board}>
                {textList.length > 0 && <>
                    <div className={"list-title-mo"}>{boardInfo[board].name}</div>

                    <Datatable
                        search={false}           //검색 사용여부
                        head={[{
                            name: "no",
                            display: "구분",
                            render: (value, item, no) => <div
                                style={{textAlign: "center"}}>{(page - 1) * limit + no + 1}</div>,
                            style: {
                                width: '80px'
                            }
                        }, {
                            name: "subject",
                            display: "제목",
                            render: (value, item) => <div style={{textAlign: "left"}}>
                                           <span onClick={e => handleClickList(item)}
                                                 style={{cursor: "pointer"}}
                                           >
                                           {value}
                                           </span>
                            </div>
                        }, {
                            name: "user_name",
                            display: "작성자",
                            style: {
                                width: '160px'
                            },
                            render: value => <div style={{textAlign: "center"}}>{value}</div>
                        }, {
                            name: "date",
                            display: "작성일",
                            style: {
                                width: '240px'
                            },
                            render: value => <div style={{textAlign: "center"}}>{yyyymmddhhiiss(new Date(value.replace(" ", "T")))}</div>
                        }, {
                            name: "read_count",
                            display: "조회수",
                            style: {
                                width: '60px'
                            },
                            render: value => <div style={{textAlign: "center"}}>{value}</div>
                        }]}              //head 데이터 (필수)
                        list={textList}              //body 데이터 (필수)
                        paging={true}            //페이지 사용여부
                        page={page}              //현재 페이지 (필수)
                        maxPage={Math.ceil(totCnt / limit)}        //전체 페이지 (필수)
                        padding={2}              //페이지번호개수 (선택)
                        onPageMove={page => setPage(page)}
                        tableState={true}        //테이블상태 사용여부 (page필수)
                        rowTotal={totCnt}        //전체데이터 값 (선택)
                        limit={false}            //출력개수 사용여부
                    />


                    {/*<DsList list={textList}*/}
                    {/*        totCnt={totCnt}*/}
                    {/*        descriptionRender={data => info.description && descriptionRender(data)}*/}
                    {/*        onClick={data => handleClickList(data)}*/}
                    {/*        page={page}*/}
                    {/*        limit={limit}*/}
                    {/*/>*/}
                    {/*<Pagination maxPage={maxPage}*/}
                    {/*            page={page}*/}
                    {/*            padding={2}*/}
                    {/*            onPageMove={page => setPage(page)}*/}
                    {/*/>*/}
                </>}

                {info.write && isLogin &&
                <div className={"btn-group"}>
                    <Link to={"/board/" + board + "/write"} className={"btn"}>{info.writeTxt}</Link>
                </div>
                }
            </SubNav>
        )
};

export default List;
