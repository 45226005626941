export const index = `경기지역경제포털 개인정보처리방침

제1조 [개인정보의 처리 목적]
 ① 경기지역경제포털은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제 18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

제2조 [개인정보의 처리 및 보유기간]
 ① 경기지역경제포털은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
 ② 개인정보 처리 및 보유기간은 다음과 같습니다.
  1) 회원가입 및 관리 : 회원 탈퇴 시까지
  2) 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지

제3조 [정보주체의 권리·의무 및 행사방법]
 ① 정보주체는 경기지역경제포털에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
  1) 개인정보 열람 요구
  2) 오류 등이 있을 경우 정정 요구
  3) 삭제 요구
  4) 처리정지 요구
 ② 제1항에 따른 권리 행사는 경기지역경제포털에 대해 서면, 전화, 전자우편 등을 통하여 하실 수 있으며 경기지역경제포털은 이에 대해 지체 없이 조치하겠습니다.
 ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우 경기지역경제포털은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
 ④ 제1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제 11호 서식에 따른 위임장을 제출하셔야 합니다.
 ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 경기지역경제포털이 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 안됩니다.

제4조 [처리하는 개인정보 항목]
 ① 경기지역경제포털은 경기지역경제포털 회원가입 가입 및 회원관리의 개인정보 항목을 처리하고 있습니다.
 ② 경기지역경제포털 서비스 이용과정에서 IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
  
제5조 [개인정보의 파기]
 ① 경기지역경제포털은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
 ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)을 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
 ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
  1) 파기절차는 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여 파기합니다. 경기지역경제포털은 파기 사유가 발생한 개인정보(또는 개인정보파일)를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보(또는 개인정보파일)를 파기합니다. 
  2) 파기방법은 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없는 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
 
제6조 [개인정보의 안전성 확보조치]
 ① 경기지역경제포털은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
  1) 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등
  2) 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치, 개인정보 암호화
  3) 물리적 조치 : 자료가 저장된 공간(전산실) 등의 접근통제

제7조 [개인정보 처리방침의 변경]
 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
`;