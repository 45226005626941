export const SET_META_INFO = 'SET_META_INFO';
export const SET_DATA_TITLE = 'SET_DATA_TITLE'
export function setMetaInfo(metaInfo) {
    return {
        type: SET_META_INFO,
        metaInfo: metaInfo
    };
}

export function setDataTitle(dataTitle) {
    return {
        type: SET_DATA_TITLE,
        dataTitle: dataTitle
    };
}