import {delCookie, getCookie} from "./Cookie";
import swal from 'sweetalert';

const get = (url, params = []) => {
    return new Promise(function (resolve, reject) {
        let paramArr = [];
        Object.keys(params).map(key => {
            paramArr.push(key + "=" + encodeURI(params[key]));
            return null;
        });

        let target = url + "?" + paramArr.join("&");
        let xhr = new XMLHttpRequest();

        xhr.open("GET", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");

        xhr.onreadystatechange = () => {
            response(xhr, resolve, reject);
        };

        xhr.send(null);
    });
};

const getNew = (url, params = []) => {
    return new Promise(function (resolve, reject) {
        let paramArr = [];
        Object.keys(params).map(key => {
            paramArr.push(key + "=" + encodeURI(params[key]));
            return null;
        });

        let target = url + "?" + paramArr.join("&");
        let xhr = new XMLHttpRequest();

        xhr.open("GET", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("user-ip", getCookie('user_ip'));

        xhr.onreadystatechange = () => {
            responseNew(xhr, resolve, reject);
        };

        xhr.send(null);
    });
};

const post = (url, params = {}, header = {}) => {
    return new Promise(function (resolve, reject) {
        let target = url;
        let xhr = new XMLHttpRequest();

        xhr.open("POST", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");

        Object.keys(header).map(key => {
            xhr.setRequestHeader(key, params[key]);
            return null;
        });

        xhr.onreadystatechange = () => {
            response(xhr, resolve, reject);
        };

        let formData = new FormData();
        Object.keys(params).map(key => {
            formData.append(key, params[key]);
            return null;
        });

        xhr.send(formData);
    });
};

const postNew = (url, params = {}, header = {}) => {
    return new Promise(function (resolve, reject) {
        let target = url;
        let xhr = new XMLHttpRequest();

        xhr.open("POST", target, true);
        xhr.setRequestHeader("token", getCookie("token"));
        xhr.setRequestHeader("client", "portal");
        xhr.setRequestHeader("user-ip", getCookie('user_ip'));

        Object.keys(header).map(key => {
            xhr.setRequestHeader(key, params[key]);
            return null;
        });

        xhr.onreadystatechange = () => {
            responseNew(xhr, resolve, reject);
        };

        let formData = new FormData();
        Object.keys(params).map(key => {
            formData.append(key, params[key]);
            return null;
        });

        xhr.send(formData);
    });
};

const response = (xhr, resolve, reject) => {
    if (xhr.readyState === 4) {
        if(xhr.status === 200){
            let response = JSON.parse(xhr.response);
            if(response.status_code.toLowerCase() === 'fail') reject(xhr);
            else resolve(response);
        }else if(xhr.status === 401){
            swal({
                title: "알림",
                text: "장시간 움직임이 없어 로그아웃 되었습니다. 다시 로그인 해주세요.",
            }).then(() => {
                reject(xhr)
                delCookie("token");
                window.location.href = "/#/login";
            });
        }else if(xhr.status === 402){
            swal({
                title: "알림",
                text: "이용 신청 후 가능한 서비스입니다.",
            });
            reject(xhr);
        }else{
            swal({
                title: "서버 에러",
                text: "잠시 후 다시 시도해주세요.",
            });
            reject(xhr)
        }
    }
};

const responseNew = (xhr, resolve, reject) => {
    if (xhr.readyState === 4) {
        if(xhr.status === 200){
            let response = JSON.parse(xhr.response);
            if(response.status_code.toLowerCase() === 'fail') reject(xhr);
            else resolve(response);

        }else if(xhr.status === 401){
            swal({
                title: "알림",
                text: "장시간 움직임이 없어 로그아웃 되었습니다. 다시 로그인 해주세요.",
            }).then(() => {
                reject(xhr)
                delCookie("token");
                window.location.href = "/#/login";
            });
        }else if(xhr.status === 402){
            swal({
                title: "알림",
                text: "이용 신청 후 가능한 서비스입니다.",
            });
            reject(xhr);
        }else if(xhr.status === 406){
            swal({
                title: "알림",
                text: "담당자 문의 후 이용해주시길 바랍니다.",
            });
            reject(xhr);
        }else{
            swal({
                title: "서버 에러",
                text: "잠시 후 다시 시도해주세요.",
            });
            reject(xhr)
        }
    }
};

export {get, post, getNew, postNew}
