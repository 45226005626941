import React from 'react';

const Num = ({num, page, onPageMove}) => {

    return (
        <button onClick={e => onPageMove(num)}>
            {num === page ? <b>{num}</b> : num}
        </button>
    )
};

export default Num;
