import React from "react";

let error_body = '<div className="wrap_mail"\n' +
    '         style="-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 2; max-width: 100%; min-width: 640px; margin: 30px 0 50px 0; font-family: Noto Sans Korean, sans-serif;">\n' +
    '        <div className="wrap_mail_inner"\n' +
    '             style="-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 2; width: 640px; margin: 0 auto; font-family: Noto Sans Korean, sans-serif;">\n' +
    '            <div className="logo"\n' +
    '                 style="-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 2; font-size: 25px; text-align: left; font-family: Noto Sans Korean, sans-serif;">\n' +
    '            </div>\n' +
    '            <h3 style="-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 2; font-size: 30px; color: #000; font-weight: 400; font-family: Noto Sans Korean, sans-serif;">죄송합니다.<br>요청하신\n' +
    '                페이지를 찾을 수 없습니다.</h3>\n' +
    '                <br>\n' +
    '            <p className="box_text"\n' +
    '               style="-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; line-height: 2; margin: 0; padding: 0; margin-bottom: 63px; color: #7f7f7f; font-family: Noto Sans Korean, sans-serif;">\n' +
    '                방문하시려는 페이지의 주소가 잘못 입력되었거나,\n' +
    '                페이지의 주소 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.\n' +
    '                <br><br>\n' +
    '                    입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.\n' +
    '            </p>\n' +
    '        </div>\n' +
    '    </div>';

const ErrorPage404 = () => {
    return (
        <>
            <div dangerouslySetInnerHTML={{__html: error_body}}/>
        </>
    )
};

export default ErrorPage404;