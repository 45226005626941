import {SET_BOARD_INFO, SET_BOARD_MENU_LIST} from "../actions/BoardAction";

const initialState = {
    boardInfo: {
        'board_14ab0a7f-d410-48d7-91ca-e264d5121794': {
            name: '공지사항',
            write: false,
            comment: false,
            remove: false,
            writeTxt: "글쓰기",
            description: false,
        },
        'board_942f2cfa-3294-42c5-ac68-b4f3abbed510': {
            name: '묻고 답하기',
            write: true,
            comment: false,
            remove: true,
            writeTxt: "문의하기",
            description: true,
        },
        'board_a2eb004d-73ca-4014-a9d2-c402675cf83f': {
            name: '자주하는 질문',
            write: false,
            comment: false,
            remove: false,
            writeTxt: "글쓰기",
            description: false,
        },
        'board_87b9692c-8959-4b3a-83c5-28e209ceca1f': {
            name: '데이터 문의',
            write: true,
            comment: false,
            remove: true,
            writeTxt: "신청하기",
            description: true,
        }
    },
    boardMenuList: [
        {
            name: '공지사항',
            link: '/board/board_14ab0a7f-d410-48d7-91ca-e264d5121794'
        },
        {
            name: '묻고 답하기',
            link: '/board/board_942f2cfa-3294-42c5-ac68-b4f3abbed510'
        },
        {
            name: '자주하는 질문',
            link: '/board/board_a2eb004d-73ca-4014-a9d2-c402675cf83f'
        },
        {
            name: '데이터 문의',
            link: '/board/board_87b9692c-8959-4b3a-83c5-28e209ceca1f'
        }
    ],
};

const BoardReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_BOARD_INFO:
            return Object.assign({}, state, {
                boardInfo: action.boardInfo
            });
        case SET_BOARD_MENU_LIST:
            return Object.assign({}, state, {
                boardMenuList: action.boardMenuList
            });
        default:
            return state;
    }
};

export default BoardReducer;
