import React, {useState} from "react";

const TextFields = ({placeholder, status, assistiveText, type, value, onChange, onBlur}) => {

    let [it, sIt] = useState(status);
    let [t, sT] = useState(status);

    let statusClass = " ikan-text-field-" + t;

    return (
        <div className={"ikan-text-field-wrap"+statusClass}>
            <div className={"text-field-default"}>
                <input type={type}
                       placeholder={placeholder}
                       onFocus={e => sT("focused")}
                       onBlur={e => {
                           sT(it);
                           onBlur(value);
                       }}
                       disabled={status === "disable"}
                       onChange={value => onChange(value)}
                       value={value}
                />
            </div>
            <div>
                <span>{assistiveText}</span>
            </div>
        </div>
    );
};

TextFields.defaultProps = {
    placeholder: "",
    type: "text",
    status: "default",
    assistiveText: "",
    onChange: () => {},
    onBlur: () => {},
    value:""
};

export default TextFields;
