import React, {useState} from "react";
import Froala from "../../components/Froala";
import {useDispatch, useSelector} from "react-redux";
import ErrorPage404 from "../common/ErrorPage404";
import * as IboardApi from "../../api/Board";
import {getCookie} from "../../util/Cookie";
import swal from "sweetalert";



const Write = ({match, ...props}) => {

    const dispatch = useDispatch();

    let [boardInfo, setBoardInfo] = useState({});
    let [isInit, setIsInit] = useState(true);
    let [content, setContent] = useState('');
    let [title, setTitle] = useState("");

    let [isLoading, setIsLoading] = useState(false);

    const userInfo = useSelector(state => state.user.info);

    useSelector(state => {
        if(JSON.stringify(state.board.boardInfo) !== JSON.stringify(boardInfo)){
            setBoardInfo(state.board.boardInfo);

            console.log(state.board.boardInfo);

        }
    });

    let board = match.params.board;
    let info = boardInfo[board];


    if (info === undefined) return <ErrorPage404/>;
    // 0122 경기도 보안 임시조치 - 공지사항, 자주하는 질문 : 주소 입력(~/write)을 통한 진입 금지
    // 공지사항
    if (board === 'board_14ab0a7f-d410-48d7-91ca-e264d5121794') return <ErrorPage404/>;
    // 자주하는 질문
    if (board === 'board_a2eb004d-73ca-4014-a9d2-c402675cf83f') return <ErrorPage404/>;

    if(isInit){
        setIsInit(false);

        // 수정일 경우
        let articleId = match.params.articleId;
        if(articleId !== undefined){
            setIsLoading(true);
            let params = {
                text_id: articleId
            };
            IboardApi.readText(params).then(res => {
                let rst = res.result;
                setContent(rst.text);
                setTitle(rst.subject);
                setIsLoading(false);
            })
        }
    }

    const onWrite = () => {
        if(title === ""){
            swal("제목을 입력해 주세요.");
            return;
        }

        if(content === ""){
            swal("내용을 입력해 주세요.");
            return;
        }


        let articleId = match.params.articleId;
        let params = {
            board_id: board,
            subject: title,
            text: content,
            user_id: userInfo.id_key
        };
        if(articleId !== undefined) {
            params.article_id = articleId;
            IboardApi.updateText(params).then(res => {
                props.history.push("/board/" + board);
            })
        }else{
            IboardApi.createText(params).then(res => {
                props.history.push("/board/" + board);
            })
        }

    };

    return (
        <>
            <div className={"wrap-write"}>
                <div className={"write-title"}>{info.name}</div>
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="제목을 입력하세요"/>
                <div className="wtiter">
                    <Froala onChange={content => setContent(content)}
                            content={content}
                    />
                </div>
                <div className={"btn-group"}>
                    <div className={"ikan-button-wrap ikan-button-full"}>
                        <button onClick={() => onWrite()}>글쓰기</button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Write;
