import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import BoardReducer from './BoardReducer';
import DataReducer from './DataReducer';

const Reducers = combineReducers({
    user: UserReducer,
    board: BoardReducer,
    data: DataReducer,
});

export default Reducers;
