import React, {useEffect, useState} from 'react';
import Bar from "../../components/Chart/Bar";
import ResourcePreview from "../../components/ResourcePreview";
import Line from "../../components/Chart/Line";
import Pie from "../../components/Chart/Pie";
import Radar from "../../components/Chart/Radar";
import Relation from "../../components/Chart/Relation";
import Scatter from "../../components/Chart/Scatter";
import * as DataApi from "../../api/Data";

const VisualizeDetail = (props) => {

    let chart = null;
    let title = null;
    let desc = null;
    if(props.match.params.id == 1){
        chart = <Bar/>;
        title = "대중교통 이용현황";
        desc = "경기도 대중교통 이용 현황입니다.";
    }else if(props.match.params.id == 2){
        chart = <Line/>;
        title = "취업 현황";
        desc = "경기도 청년의 연도별 취업현황입니다.";
    }else if(props.match.params.id == 3){
        chart = <Pie/>;
        title = "응급차량 현황";
        desc = "현재 경기도에 배치된 응급차량 현황입니다.";
    }else if(props.match.params.id == 4){
        chart = <Radar height={500}/>;
        title = "직군별 취업 현황";
        desc = "경기도의 직군별 취업현황입니다.";
    }else if(props.match.params.id == 5){
        chart = <Relation height={600}/>;
        title = "";
        desc = "";
    }else if(props.match.params.id == 6){
        chart = <Scatter/>;
        title = "기업별 매출당 이익";
        desc = "2019년 기업별 매출당 순이익입니다.";
    }


    let [data, setData] = useState(null);

    useEffect(() => {
        let params = {
            content_id: props.match.params.id
        };
        DataApi.readUseDetail(params).then(res => {
            // console.log(res.result);
            setData(res.result.content);
        })
    }, []);

    const getChart = () => {
        if(data.chart_kind === "bar"){
            return <Bar data={data.chart_data.data} xAxisData={data.chart_data.xAxisData} min={data.chart_data.min} max={data.chart_data.max}/>;
        }else if(data.chart_kind === "line"){
            return <Line data={data.chart_data.data} xAxisData={data.chart_data.xAxisData}/>;
        }else if(data.chart_kind === "radar"){
            return <Radar data={data.chart_data.data} indicator={data.chart_data.indicator} legend={data.chart_data.legend} height={550}/>;
        }else if(data.chart_kind === "scatter"){
            return <Scatter data={data.chart_data.data} symbolSize={data.chart_data.symbolSize}/>;
        }else if(data.chart_kind === "pie"){
            return <Pie seriesData={data.chart_data.seriesData} legendData={data.chart_data.legendData} selected={data.chart_data.selected}/>;
        }else return null;
    };

    return (
        <>
            {data &&
            <div className={"use-wrap"}>
                <div className={"use-way"}>
                    <h3>{data.title}</h3>
                    <div className={"use-way-text"} dangerouslySetInnerHTML={ {__html: data.desc} } />
                </div>
                <br/>
                <br/>
                <div>
                    {getChart()}
                </div>
                <div className={"use-way"}>
                    <h3>상세 데이터</h3>
                            <ResourcePreview resourceId={data.resource_id}/>
                </div>
                {/*<div className={"use-way"}>*/}
                {/*    <h3>활용방법</h3>*/}
                {/*    <div className={"use-way-text"}>{data.description}</div>*/}
                {/*    <div className={"use-way-view-box"}>*/}
                {/*        {data.images.map(img => <img src={img}/>)}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={"btn-group"}>*/}
                {/*    <div className={"ikan-button-wrap ikan-button-line"}>*/}
                {/*        <button onClick={() => props.history.push("/use")}>목록</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"btn-group"}>
                    <div className={"ikan-button-wrap ikan-button-line"}>
                        <button onClick={() => props.history.push("/visualize")}>목록</button>
                    </div>
                </div>
            </div>
            }
        </>
    )
};

export default VisualizeDetail;
