import React from 'react';
import {yyyymmddhhiiss} from "../../util/Date";

const Item = ({data, descriptionRender, onClick, limit, page, i}) => {
    data.date = data.date.replace(" ", "T");
    return (
        <li>
            <dl className={"notice-list"}>
                <dt onClick={() => onClick(data)}>
                    <span className={"notice-no"}>{(page - 1) * limit + i + 1}</span>
                    <span className={"notice-post"}>{data.subject}</span>
                    <div className={"date"}>
                        {yyyymmddhhiiss(new Date(data.date))}
                    </div>
                </dt>
                <dd>
                    <div className={"explanation"}>
                        {descriptionRender(data)}
                    </div>
                </dd>
            </dl>
        </li>
    )
};

Item.defaultProps = {
    descriptionRender: data => null
};

export default Item;
