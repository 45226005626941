import React, {useState} from 'react';

const Head = ({items, onHeadClick, onSort}) => {

    const [sort, setSort] = useState("");
    const [sortName, setSortName] = useState("");

    const sortFunc = (name) => {
        let out = "asc";
        if (sortName === name) {
            if (sort === "asc") {
                out = "desc";
                setSort(out);
            } else {
                out = "asc";
                setSort(out);
            }
        } else {
            out = "asc";
            setSort(out);
            setSortName(name);
        }

        onSort(name, out);
    };

    const renderItem = () => {
        return items.map((item, i) => {

                let cell;
                let arrow = "";

                if (item.sort && item.name === sortName) {

                    if (sort === "asc") arrow = "↑";
                    else if (sort === "desc") arrow = "↓";

                    cell = item.headRender !== undefined ? item.headRender(item, sort) : item.display + arrow;

                } else {

                    cell = item.headRender !== undefined ? item.headRender(item) : item.display;

                }

                return (
                    <th key={i}
                        onClick={() => {
                            onHeadClick(item);
                            if (item.sort)
                                sortFunc(item.name);
                        }}
                        style={item.style}
                    >{cell}</th>
                );
            }
        );
    };

    return (
        <thead>
        <tr>
            {renderItem()}
        </tr>
        </thead>
    )
};

Head.defaultProps = {
    items: [],
    onHeadClick: () => {

    }
};

export default Head;
