import React from 'react';
import {addComma} from "../../util/Number";
import FaqItem from "./FaqItem";

const FaqList = ({list, totCnt, page, limit}) => {

    return (
        <>
            <div className={"list-title-mo"}>자주하는 질문</div>
            <div>
                <div className={"search-result"}>
                    <span>전체({addComma(totCnt)})</span>
                </div>
                <ul className={"dataset-list"}>
                    {list.map((item, i) => <FaqItem data={item}
                                               key={JSON.stringify(item)}
                                                    page={page}
                                                    limit={limit}
                                                    i={i}
                    />)}
                </ul>
            </div>
        </>
    )
};

FaqList.defaultProps = {
    list: [],
    totCnt: 0
};

export default FaqList;
