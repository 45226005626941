import React from 'react';

const Button = ({children, onClick, type}) => {

    let typeClass = " ikan-button-" + type;

    return (
        <div className={"ikan-button-wrap"+typeClass}>
            <button onClick={onClick}>
                {children}
            </button>
        </div>
    )
};

Button.defaultProps = {
    type: "full",
    onClick: e => {}
};

export default Button;
