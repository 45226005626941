import React from 'react';
import Search from './components/Search';
import Table from './components/Table';
import TableState from './components/TableState';
import Page from './components/Page';
import Limit from './components/Limit';
import './style.css';

const Datatable = ({search, searchFilter, onSearch, showLoading, loading, list, head,
    tableState, rowTotal, paging, maxPage, page, limit, limitFilter,
    onRowClick, padding, onPageMove, onHeadClick, onSelectLimit, onSort}) => {

    return (
        <div className={"datatadble-wrap"}>
            <Search search={search} filter={searchFilter} onSearch={onSearch}/>
            <Table showLoading={showLoading}
                   list={list}
                   head={head}
                   loading={loading}
                   onRowClick={onRowClick}
                   onHeadClick={onHeadClick}
                   onSort={onSort}
            />
            <div className="datatable-tail-wrap">
                <TableState tableState={tableState} rowTotal={rowTotal} page={page} maxPage={maxPage}/>
                <Page paging={paging}
                    page={page}
                    maxPage={maxPage}
                    padding={padding}
                    onPageMove={onPageMove}
                />
                <Limit limit={limit} filter={limitFilter} onSelectLimit={onSelectLimit} />
            </div>
        </div>
    )
};


Datatable.defaultProps = {
    searchFilter: [
        {
            name: "",
            display: ""
        }
    ],
    search: false,
    showLoading: false,
    loading: 10,
    list: [],
    head: [],
    paging: false,
    page: 1,
    maxPage: 1,
    tableState: false,
    rowTotal: -1,
    limit:false,
    limitFilter: [
        {
            name: 10,
            display: 10
        },
        {
            name: 50,
            display: 50
        },
        {
            name: 100,
            display: 100
        }
    ],
    onRowClick: row => {

    },
    onPageMove: page => {

    },
    onSort: () => {

    },
    padding: 2
};

export default Datatable;
