import React, {useEffect, useState} from 'react';
import {timeSince} from "../util/Date";

const SnsStTimeSince = ({date}) => {

    let [initDate, setInitDate] = useState(new Date(date.replace(" ", "T")));
    let [displayDate, setDisplayDate] = useState(timeSince(initDate));

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayDate(timeSince(initDate));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <span key={displayDate}>
            {displayDate}
        </span>
    )
};

export default SnsStTimeSince;
